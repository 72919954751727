import * as ShoppingCartActionTypes from 'Types/ShoppingCartActionTypes'


const initialState = {
    debugShippingQuoteState: {
        isSlideOutActive: false,
        debugShippingOption: null
    },
    hydrated: false,
    hideShippingEstimateFlag: false
}

const shoppingCartReducer = (state, action) => {
    state = state || initialState

    switch (action.type) {
        case ShoppingCartActionTypes.RECEIVE_SHOPPINGCARTPAGE:
        case ShoppingCartActionTypes.RECEIVE_UPDATE_SHOPPINGCART_PRODUCTS:
        case ShoppingCartActionTypes.RECEIVE_MOVE_BUILD_LIST_ITEM_TO_CART:
        case ShoppingCartActionTypes.RECEIVE_MOVE_CART_ITEM_TO_BUILD_LIST:
        case ShoppingCartActionTypes.RECEIVE_REMOVE_BUILD_LIST_ITEM:
            const { shoppingCart } = action.payload
            return {
                ...state,
                ...shoppingCart,
                hydrated: true
            }
        case ShoppingCartActionTypes.UPDATE_DEBUG_SHIPPING_QUOTE_STATE:
            return {
                ...state,
                debugShippingQuoteState: action.payload,
                hydrated: true
            }
        case ShoppingCartActionTypes.HIDE_SHIPPING_ESTIMATE:
            return {
                ...state,
                hideShippingEstimateFlag: action.payload
            }
        case ShoppingCartActionTypes.RECEIVE_MOVE_SAVED_FOR_LATER_PART_TO_BUILD_LIST:
            return {
                ...state,
                savedForLaterProducts: action.payload,
                hydrated: true
            }
        default:
            return state
    }
}
export default shoppingCartReducer