import * as ErrorTypes from 'Types/ErrorTypes';
import { USA_ID, CANADA_ID , PASSWORD_REGEX} from 'Constants/Constants';

export function isFormValid(errors) {
    return !Object.values(errors).some(err => err && err.length)
}

export function getAccountSignupValidationErrors(accountSignup) {
	let validationObjects = {}
    let fieldtypes = Object.keys(accountSignup)
    if (fieldtypes.includes("emailAddress")) {
        validationObjects[ErrorTypes.ACCOUNT_SIGNUP_EMAIL] = validateEmailAddress
    }
	if (fieldtypes.includes("password")) {
        validationObjects[ErrorTypes.ACCOUNT_SIGNUP_PASSWORD] = validatePassword
    }

	return Object.keys(validationObjects).reduce((errors, validator) => {
        errors[validator] = validationObjects[validator](accountSignup);
		return errors;
	}, {})
}

export function getEmailSignupValidationErrors(emailSignup) {
    let validationObjects = {}
    let addressFieldtypes = Object.keys(emailSignup)
    if (addressFieldtypes.includes("email")) {
        validationObjects[ErrorTypes.EMAIL_SIGNUP_EMAIL] = validateEmail
    }

    if (addressFieldtypes.includes("phoneNumber") && emailSignup.phoneNumber) {
        validationObjects[ErrorTypes.EMAIL_SIGNUP_PHONE] = validatePhone
    }

    return Object.keys(validationObjects).reduce((errors, validator) => {
        errors[validator] = validationObjects[validator](emailSignup);
        return errors;
    }, {})
}

export function getLoyaltySignupValidationErrors(loyaltySignup) {
    let validationObjects = {}

    let addressFieldtypes = Object.keys(loyaltySignup)
    if (addressFieldtypes.includes("loyaltyEmail")) {
        validationObjects[ErrorTypes.LOYALTY_SIGNUP_EMAIL] = validateLoyaltyEmail
    }

    if (addressFieldtypes.includes("fullName")) {
        validationObjects[ErrorTypes.LOYALTY_SIGNUP_NAME] = validateLoyaltyName
    }

    return Object.keys(validationObjects).reduce((errors, validator) => {
        errors[validator] = validationObjects[validator](loyaltySignup);
        return errors;
    }, {})
}

export function getAccountSignUpValidationErrors(accountSignUp) {
    let validationObjects = {}
    let fieldTypes = Object.keys(accountSignUp)
    if (fieldTypes.includes("emailAddress")) {
        validationObjects[ErrorTypes.EMAIL_SIGNUP_EMAIL] = validateEmail
    }

    return Object.keys(validationObjects).reduce((errors, validator) => {
        errors[validator] = validationObjects[validator](emailSignup);
        return errors;
    }, {})
}

export function getAddressValidationErrors(address) {
    let validationObjects = {}
    let addressFieldtypes = Object.keys(address)
    if (addressFieldtypes.includes("email")) {
        validationObjects[ErrorTypes.ADDRESS_FORM_EMAIL] = validateEmail
    }
    if (addressFieldtypes.includes("phoneNumber")) {
        validationObjects[ErrorTypes.ADDRESS_FORM_PHONE] = validatePhone
    }
    if (addressFieldtypes.includes("fullName")) {
        validationObjects[ErrorTypes.ADDRESS_FORM_FULL_NAME] = validateFullName
    }
    if (addressFieldtypes.includes("addressLine1")) {
        validationObjects[ErrorTypes.ADDRESS_FORM_ADDRESS_LINE_1] = validateAddressLine1
    }
    if (addressFieldtypes.includes("addressLine2")) {
        validationObjects[ErrorTypes.ADDRESS_FORM_ADDRESS_LINE_2] = validateAddressLine2
    }
    if (addressFieldtypes.includes("company")) {
        validationObjects[ErrorTypes.ADDRESS_FORM_COMPANY] = validateCompany
    }
    if (addressFieldtypes.includes("city")) {
        validationObjects[ErrorTypes.ADDRESS_FORM_CITY] = validateCity
    }
    if (addressFieldtypes.includes("province")) {
        if (addressFieldtypes.includes("countryId")) {
            validationObjects[ErrorTypes.ADDRESS_FORM_PROVINCE] = validateProvinceWithCountry
        } else {
            validationObjects[ErrorTypes.ADDRESS_FORM_PROVINCE] = validateProvince

        }
    }
    if (addressFieldtypes.includes("provinceId")) {
        if (addressFieldtypes.includes("countryId")) {
            validationObjects[ErrorTypes.ADDRESS_FORM_PROVINCE] = validateProvinceWithCountry
        } else {
            validationObjects[ErrorTypes.ADDRESS_FORM_PROVINCE] = validateState

        }
    }
    if (addressFieldtypes.includes("postalCode")) {
        validationObjects[ErrorTypes.ADDRESS_FORM_POSTAL_CODE] = validatePostalCode
    }
    if (addressFieldtypes.includes("countryId")) {
        validationObjects[ErrorTypes.ADDRESS_FORM_COUNTRYID] = validateCountry
    }

    return Object.keys(validationObjects).reduce((errors, validator) => {
        errors[validator] = validationObjects[validator](address);
        return errors;
    }, {})
}

function validateLoyaltyEmail(info) {
    let errors = [];

    if (!info.loyaltyEmail || !info.loyaltyEmail.length || info.loyaltyEmail.match(/^\s*$/)) {
        errors.push("Please enter an email.");
    }
    else if (info.loyaltyEmail.length > 100) {
        errors.push("Email can't be more than 100 characters")
    }

    const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;

    if (info.loyaltyEmail && (!info.loyaltyEmail.includes("@") || !info.loyaltyEmail.includes(".") || !emailRegex.test(info.loyaltyEmail))) {
        errors.push("Please enter a valid email. It must contain @ and .");
    }

    return errors;
}

function validateLoyaltyName(info) {
    let errors = [];

    if (!info.fullName || !info.fullName.length || info.fullName.match(/^\s*$/)) {
        errors.push("Please enter your first and last name.");
    }
    else {
        const splitName = info.fullName.split(' ')
        let firstName = splitName[0]
        let lastName = splitName[1]

        const nameRegex = /^[a-zA-Z]+$/;

        if (!firstName || !nameRegex.test(firstName) || !lastName || !nameRegex.test(lastName)) {
            errors.push("Please enter your first and last name seperated by a space.");
        }
    }

    return errors;
}

function validateEmail(address) {
    let errors = [];

    if (!address.email || !address.email.length || address.email.match(/^\s*$/)) {
        errors.push("Please enter an email.");
    }
    else if (address.email.length > 100) {
        errors.push("Email can't be more than 100 characters")
    }

    if (address.email && (!address.email.includes("@") || !address.email.includes("."))) {
        errors.push("Please enter a valid email. It must contain @ and .");
    }

    return errors;
}

export function validateEmailAddress(address) {
	let errors = [];

    if (!address.emailAddress || !address.emailAddress.length || address.emailAddress.match(/^\s*$/)) {
		errors.push("Please enter an email.");
	}
    else if (address.emailAddress.length > 100) {
		errors.push("Email can't be more than 100 characters")
	}

    if (address.emailAddress && (!address.emailAddress.includes("@") || !address.emailAddress.includes("."))) {
		errors.push("Please enter a valid email. It must contain @ and .");
	}

	return errors;
}

export function validatePassword(form) {
	let errors = [];

    if (!form.password || !form.password.length || form.password.match(/^\s*$/)) {
        errors.push("Please enter a password.");
    }
    else if (form.password.length < 8) {
        errors.push("Your password must be at least 8 characters long.")
    }
    else if (!PASSWORD_REGEX.test(form.password)) {
        errors.push("Only letters, numbers, and basic symbols allowed")
    }

	return errors;
}

function validatePhone(address) {

    var cleanedPhoneNumber = ('' + address.phoneNumber).replace(/\D/g, '')

    let errors = [];

    if (!address.phoneNumber || !address.phoneNumber.length || address.phoneNumber.match(/^\s*$/)) {
        errors.push("Please enter a phone number.");
    } else if ((address.countryId === USA_ID || address.countryId === CANADA_ID) && cleanedPhoneNumber.length !== 10) {
        errors.push("Phone number must be 10 digits.");
    }
    else if (address.phoneNumber.length > 25) {
        errors.push("Phone number can't be more than 25 characters.");
    }

    return errors;
}

function validateFullName(address) {
    let errors = [];

    if (!address.fullName 
        || !address.fullName.length 
        || address.fullName.match(/^\s*$/)) // just spaces 
    {
        errors.push("Please enter your full name.");
    }
    else if (!address.fullName.match(/\S+\s+\S+/)) { // ensures two tokens separated by a space
        errors.push("Please indicate a first and last name with a space between");
    }
    else if (address.fullName.length > 30) {
        errors.push("Full name can't be more than 30 characters")
    }

    return errors;
}

function validateAddressLine1(address) {
    let errors = [];

    if (!address.addressLine1 || !address.addressLine1.length || address.addressLine1.match(/^\s*$/)) {
        errors.push("Please enter a street.");
    }
    else if (address.addressLine1.length > 30) {
        errors.push("Street Address Line 1 can't be more than 30 characters")
    }

    return errors;
}

function validateAddressLine2(address) {
    let errors = [];

    if (address.addressLine2 && address.addressLine2.length > 30) {
        errors.push("Street Address Line 2 can't be more than 30 characters")
    }
    return errors; // this field is not required
}

function validateCompany(address) {
    let errors = [];

    if (address.company && address.company.length > 30) {
        errors.push("Company can't be more than 30 characters")
    }
    return errors; // this field is not required
}

function validateCity(address) {
    let errors = [];

    if (!address.city || !address.city.length || address.city.match(/^\s*$/)) {
        errors.push("Please enter a city.");
    }
    else if (address.city.length > 20) {
        errors.push("City can't be more than 20 characters")
    }

    return errors;
}

function validateProvince(address) {
    let errors = [];

    if (!address.province || !address.province.length || address.province.match(/^\s*$/)) {
        errors.push("Please enter a province.");
    }
    else if (address.province.length > 20) {
        errors.push("Province can't be more than 20 characters")
    }

    return errors;
}

function validateState(address) {
    let errors = [];

    if (!address.provinceId) {
        errors.push("Please enter a state.");
    }

    return errors;
}

function validateProvinceWithCountry(address) {
    let errors = [];

    if (address.countryId === USA_ID && !address.provinceId) {
        errors.push("Please select a state.");
    } else if (address.countryId === CANADA_ID && !address.provinceId) {
        errors.push("Please select a province.");
    } else if (address.countryId !== USA_ID && address.countryId !== CANADA_ID && (!address.province || !address.province.length || address.province.match(/^\s*$/))) {
        errors.push("Please enter a province.");
    }

    return errors;
}

function validatePostalCode(address) {
    let errors = [];

    if (!address.postalCode || !address.postalCode.length || address.postalCode.match(/^\s*$/)) {
        if (address.countryId === USA_ID) {
            errors.push("Please enter a zip code.");
        } else {
            errors.push("Please enter a postal code.");
        }
    }
    else if (address.countryId === USA_ID && (address.postalCode.replace(/\s/g, "").length != 5 || !address.postalCode.match(/^[0-9]+$/))) {
        errors.push("Zip code must be 5 digits.")
    }
    else if (address.countryId === CANADA_ID && (address.postalCode.replace(/\s/g, "").replace("-", "").length != 6)) {
        errors.push("Postal code must be 6 characters.")
    }
    else if (address.countryId === CANADA_ID && !address.postalCode.replace(/\s/g, "").replace("-", "").match(/^[A-Za-z]\d[A-Za-z]\d[A-Za-z]\d$/))
    {
        errors.push("Please enter a valid Canadian postal code")
    }
    else if (address.postalCode.length > 25) {
        errors.push("Postal code can't be more than 25 characters.")
    }


    return errors;
}
function validateCountry(address) {
    let errors = [];

    if (!address.countryId) {
        errors.push("Please select a country.");
    }

    return errors;
}