import React, { useEffect } from 'react'
import { connect } from 'react-redux'
import { LoadScript } from 'Utilities/Utilities'

const SplitRumAgent = ({ splitUserKey, splitJavascriptApiKey }) => {
    useEffect(
        () => {
            if (splitUserKey && splitJavascriptApiKey) {
                LoadScript(
                    'https://cdn.split.io/rum-agent/rum-agent-0.2.1.min.js',
                    'SPLIT_RUM_AGENT_SCRIPT',
                    () => {
                        if (window && window.SPLIT_RUM) {
                            window.SPLIT_RUM.init(splitJavascriptApiKey).identities([
                                { key: splitUserKey, trafficType: 'user' }
                            ])
                        }
                    }
                )
            }
        },
        [splitUserKey, splitJavascriptApiKey]
    )

    return null
}

const mapStateToProps = state => {
    return {
        splitUserKey: state.featureFlagContainer.splitUserKey,
        splitJavascriptApiKey: state.featureFlagContainer.splitJavascriptApiKey
    }
}

export default connect(mapStateToProps)(SplitRumAgent)
