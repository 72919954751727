import * as ActionTypes from 'Types/CheckoutActionTypes'
import * as Actions from 'Actions/checkoutTransactionAction'



const StoreHydrationMiddleware = ({ dispatch, getState }) => next => action => {
    switch (action.type) {
        case ActionTypes.RECEIVE_SUBMITCUSTOMERINFO:
        case ActionTypes.RECEIVE_ENTRY:
        case ActionTypes.RECEIVE_SHIPPINGMETHODSTEP:
        case ActionTypes.RECEIVE_CONFIRMATION_PAGE:
            dispatch(Actions.storeHydratedFlag())
            break
        default:
	        break
    }
    return next(action)
}

export default StoreHydrationMiddleware