import React, {Fragment, lazy, Suspense} from "react";
import {connect} from "react-redux";
import Typography from "Components/Typography/Typography";
import Form from "Components/Form/Form";
import Button from "Components/Button/Button";
import Divider from "Components/Divider/Divider";
import * as TransactionActionTypes from "Types/CheckoutActionTypes";
import {SavedCheckmarkSvgIcon, SmiWheelSvgIcon, WarningSvgIcon} from "Components/SvgIcon/SvgIcon";
import Modal from "Components/Modal/Modal";
import {getAccountSignupValidationErrors, isFormValid} from "Utilities/FormValidator";
import {disableLoader} from "Actions/checkoutTransactionAction"
import {sendGoogleAnalyticsEvent} from 'Actions/googleAnalyticsAction'
import './AccountSignUp.css';

const TermsAndConditions = lazy(() => { return import('Containers/Checkout/TermsAndConditions/TermsAndConditions') })

const AccountSignUp = props => {
    if (!canShowAccountSignUpModal(props)) {
        return false;
    }

    let modalContainerCss = {
        marginTop: "75px",
        backgroundImage:
            "linear-gradient(to bottom, rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.9)), url('https://content.speedwaymotors.com/OtherImages/CheckoutRedesign/account_creation_modal_confirmation_page.webp')",
        backgroundPosition: "center top",
        backgroundSize: "cover"
    };
    return (
        <Modal
            id="accountSignUpModal"
            content={
                <ModalContent
                    accountSignUp={props.accountSignUp}
                    updateAccountSignUpForm={props.updateAccountSignUpForm}
                    fetchSubmitAccountSignUp={props.fetchSubmitAccountSignUp}
                    errorMessages={props.errorMessages}
                />
            }
            modalContainerCss={modalContainerCss}
            showModalExit={true}
        />
    );
};

// All the logic checks to determine if we can show the account sign up modal or not.
const canShowAccountSignUpModal = ({ transaction, featureFlagContainer, userProfile, ...props }) => {
    if (
        !transaction ||
        !featureFlagContainer ||
        !userProfile ||
        !transaction.addressLookup ||
        !transaction.addresses
    ) {
        return false;
    }

    let {
        storeHydrated,
        addressLookup,
        addresses
    } = transaction;
    let addressId = addressLookup.billingAddressId;
    let address = addresses[addressId];

    if (
        !storeHydrated ||
        !featureFlagContainer.isAccountCreateModalEnabled ||
        userProfile.isUserLoggedIn ||
        !address
    ) {
        return false;
    }

    let phone = address.phoneNumber;
    let fullName = address.fullName;

    // We need to get the phone or fullName from some other place in the redux store.
    // The user doesn't enter this data, but it is required for the server call to succeed.
    if (!phone || !fullName) {
        return false;
    }

    return true;
};

const ModalContent = props => {
    let { showSuccessResponse, showErrorResponse } = props.accountSignUp;

    return (
        <div className="accountSignUpContainer">
            <div className="valueStatements">
                <SmiWheelSvgIcon />
                <div className="valueStatements">
                    <Typography as={"h3"}>
                        Glad you stopped by. Plan on coming again? Make an account to:
                    </Typography>
                    <a className="orderHistoryLink" href="/account/orderhistory">View Your Order History</a>
                    <p>Organize And Track Your Parts</p>
                    <p>Receive Exclusive Promotions</p>
                    <p>Manage A List Of Your Vehicles</p>
                </div>
            </div>
            <Divider />
            {!showSuccessResponse && !showErrorResponse ? (
                <AccountSignUpForm
                    accountSignUp={props.accountSignUp}
                    updateAccountSignUpForm={props.updateAccountSignUpForm}
                    onFormSubmit={() => props.fetchSubmitAccountSignUp(null, null, null, null)}
                    errorMessages={props.errorMessages}
                />
            ) : (
                false
            )}
            {showSuccessResponse ? <SuccessResponse /> : false}
            {showErrorResponse ? <ErrorResponse /> : false}
        </div>
    );
};

const AccountSignUpForm = ({
    accountSignUp,
    updateAccountSignUpForm,
    onFormSubmit,
    errorMessages
}) => {
    let errors = [];
    if (accountSignUp.errors) {
        errors = accountSignUp.errors;
    }
    return (
        <Fragment>
            <Form onSubmit={onFormSubmit} className="accountSignUpForm">
                <Form.Group controlId="accountSignUpEmail">
                    <Form.AntiForgeryToken/>
                    <Form.Label required={true}>Email</Form.Label>
                    <Form.Textbox
                        className={"emailTextbox"}
                        type={"email"}
                        onChange={event =>
                            updateAccountSignUpForm({ emailAddress: event.target.value })
                        }
                        value={accountSignUp.emailAddress}
                        errors={errors.ACCOUNT_SIGNUP_EMAIL}
                    />
                </Form.Group>
                <Form.Group controlId="accountSignUpPassword">
                    <Form.Label required={true}>Password</Form.Label>
                    <Form.Textbox
                        className={"passwordTextbox"}
                        type={"password"}
                        onChange={event =>
                            updateAccountSignUpForm({ password: event.target.value })
                        }
                        value={accountSignUp.password}
                        errors={errors.ACCOUNT_SIGNUP_PASSWORD}
                    />
                </Form.Group>
                <Button variant={'alternative'} className="createAccountButton" type={"submit"} value={"Create An Account"}></Button>               
            </Form>
            <Button variant={'link'} href="/Account/Login?returnUrl=%2FAccount" className="loginButton" type={"button"}>Already have an account? Log In</Button> 
            <Suspense fallback={<div></div>}>
                <TermsAndConditions />
            </Suspense>
        </Fragment>
    );
};

const SuccessResponse = () => {
    return (
        <div className="accountSignUpSuccess">
            <SavedCheckmarkSvgIcon />
            <Typography as={"h3"}>Success! You are now part of the Speedway family.</Typography>
        </div>
    ); 
}; 

const ErrorResponse = () => {
    return (
        <div className="accountSignUpError">
            <WarningSvgIcon />
            <Typography as={"h3"}>Something Went Wrong.</Typography>
            <p>Call 800.979.0122 for assistance.</p>
        </div>
    );
};

// Action Types.
export const UPDATE_ACCOUNT_SIGN_UP_FORM = "UPDATE_ACCOUNT_SIGN_UP_FORM";
export const REQUEST_SUBMIT_ACCOUNT_SIGN_UP = "REQUEST_SUBMIT_ACCOUNT_SIGN_UP";
export const RECEIVE_SUBMIT_ACCOUNT_SIGN_UP = "RECEIVE_SUBMIT_ACCOUNT_SIGN_UP";
export const DISPLAY_FORM_VALIDATION_ERRORS = "DISPLAY_FORM_VALIDATION_ERRORS";

// Actions.
const updateAccountSignUpForm = updatedFormField => ({
    type: UPDATE_ACCOUNT_SIGN_UP_FORM,
    payload: updatedFormField
});

const requestSubmitAccountSignUp = () => ({
    type: REQUEST_SUBMIT_ACCOUNT_SIGN_UP
});

const receiveSubmitAccountSignUp = success => ({
    type: RECEIVE_SUBMIT_ACCOUNT_SIGN_UP,
    payload: {
        showSuccessResponse: success,
        showErrorResponse: !success
    }
});

const displayFormValidationErrors = errors => ({
    type: DISPLAY_FORM_VALIDATION_ERRORS,
    payload: {
        errors
    }
});

const fetchSubmitAccountSignUp = () => (dispatch, getState) => {
    try {
        let { transaction, accountSignUp } = getState();

        if(!navigator.globalPrivacyControl && !document.cookie.includes('EnableGpc=')) {
            dispatch(sendGoogleAnalyticsEvent('Checkout', 'Account Sign Up Attempt Confirmation Page', transaction.transactionId))
        }

        let billingAddress = transaction.addresses[transaction.addressLookup.billingAddressId];
        let phone = billingAddress.phoneNumber;
        let fullName = billingAddress.fullName;
        let emailAddress = accountSignUp.emailAddress;
        let password = accountSignUp.password;
        let token = getState().antiForgeryToken.token;
        let data = {
            fullName: fullName,
            emailAddress: emailAddress,
            password: password,
	        phone: phone,
            isHeaderRegister: true,
            returnUrl: "anything can go here",
            transactionId: transaction.transactionId
        };

        const dataWithPasswordHidden = JSON.stringify(data, (key, value) => {
            if (key === "password") {
                return undefined;
            }
            return value;
        });

        let errors = getAccountSignupValidationErrors(data);
        if (!isFormValid(errors)) {
            dispatch(displayFormValidationErrors(errors));
        } else {
            dispatch(requestSubmitAccountSignUp());
            
            console.log("registering for account with the following information: " 
                + dataWithPasswordHidden)

            fetch("/account/registeraccount", {
                method: "POST",
                body: JSON.stringify(data),
                headers: {
                        "Content-Type": "application/json"
                },
                credentials: "include"
            })
                .then(response => response.json())
                .then(json => {
                    if (json.Success) {
                        dispatch(receiveSubmitAccountSignUp(json.Success));
                    }
                    else if (json.Success === false && json.Message) {
                        alert(json.Message)
                        dispatch(disableLoader())
                        console.error('Error modal displayed fetchSubmitAccountSignUp', json.Message)
                    } else {
	                    dispatch(receiveSubmitAccountSignUp(json.Success));
                    }
                })
                .catch(error => {
                    console.error("fetchSubmitAccountSignUp ErrorInside:", error);
                    
                    dispatch(receiveSubmitAccountSignUp(false));
                });
        }
    } catch (error) {
        console.error("fetchSubmitAccountSignUp ErrorOutside:", error);
        
        dispatch(receiveSubmitAccountSignUp(false));
    }
};

const initialState = {};
export const accountSignUpReducer = (state, action) => {
    state = state || initialState;

    switch (action.type) {
        case UPDATE_ACCOUNT_SIGN_UP_FORM:
            return {
                ...state,
                ...action.payload
            };
        case RECEIVE_SUBMIT_ACCOUNT_SIGN_UP:
            return {
                ...state,
                ...action.payload
            };
        case TransactionActionTypes.RECEIVE_CONFIRMATION_PAGE:
            var billingAddress = action.payload.checkoutTransactionRm.addresses[action.payload.checkoutTransactionRm.addressLookup.billingAddressId]
            var emailAddress = billingAddress ? billingAddress.email : null
            return {
                ...state,
                emailAddress: emailAddress
            };
        case DISPLAY_FORM_VALIDATION_ERRORS:
            return {
                ...state,
                ...action.payload
            };
        default:
            return state;
    }
};

const mapDispatchToProps = {
    fetchSubmitAccountSignUp,
    updateAccountSignUpForm,
    sendGoogleAnalyticsEvent
};

const mapStateToProps = state => {
    return {
        transaction: state.transaction,
        featureFlagContainer: state.featureFlagContainer,
        userProfile: state.userProfile,
        accountSignUp: state.accountSignUp
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(AccountSignUp);
