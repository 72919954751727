// !!! IMPORTANT !!!
// This file must stay in sync with an enum on the server!
// If you add a type to this file, be sure to add it to the enum.
// (Since the enum hasn't been made yet, I'm not including the file name here. Once it gets made this will be updated.)
// !!! IMPORTANT !!!

export const ADDRESS_FORM_EMAIL = "ADDRESS_FORM_EMAIL";
export const ADDRESS_FORM_PHONE = "ADDRESS_FORM_PHONE";
export const ADDRESS_FORM_FULL_NAME = "FULL_NAME";
export const ADDRESS_FORM_ADDRESS_LINE_1 = "ADDRESS_LINE_1";
export const ADDRESS_FORM_ADDRESS_LINE_2 = "ADDRESS_LINE_2";
export const ADDRESS_FORM_COMPANY = "ADDRESS_FORM_COMPANY";
export const ADDRESS_FORM_CITY = "ADDRESS_FORM_CITY";
export const ADDRESS_FORM_PROVINCE = "ADDRESS_FORM_PROVINCE";
export const ADDRESS_FORM_POSTAL_CODE = "ADDRESS_FORM_POSTAL_CODE";
export const ADDRESS_FORM_COUNTRYID = "ADDRESS_FORM_COUNTRYID";

export const EMAIL_SIGNUP_EMAIL = "EMAIL_SIGNUP_EMAIL";
export const EMAIL_SIGNUP_PHONE = "EMAIL_SIGNUP_PHONE";
export const LOYALTY_SIGNUP_EMAIL = "LOYALTY_SIGNUP_EMAIL";
export const LOYALTY_SIGNUP_NAME = "LOYALTY_SIGNUP_NAME";
export const ACCOUNT_SIGNUP_EMAIL = "ACCOUNT_SIGNUP_EMAIL";
export const ACCOUNT_SIGNUP_PASSWORD = "ACCOUNT_SIGNUP_PASSWORD";

export const SUBMIT_FOR_PAYMENT = "SUBMIT_FOR_PAYMENT";