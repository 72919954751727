import * as CheckoutActionTypes from 'Types/CheckoutActionTypes'
import * as ShoppingCartActionTypes from 'Types/ShoppingCartActionTypes'

const initialState = {
    itemCount: 0,
    lineItemsSubtotal: 0,
    estimatedSalesTax: "",
    shippingPrice: "",
    totalPrice: "",
    isOrderSummaryToggleActive: false,
    futureItemCount: 0,
    futurePostDiscountTotalPrice: "",
    futureEstimatedSalesTax: "",
    futureShippingPrice: "",
    futureLineItemsSubtotal: "",
    totalDiscount: 0,
    retailDeliveryFee: null,
    futureRetailDeliveryFee: null,
    currentLoyaltyRedemption: null,
    futureLoyaltyRedemption: null,
    currentShippingProtectionOptions: null,
    futureShippingProtectionOptions: null,
}
const orderDetailsReducer = (state, action) => {
    state = state || initialState

    switch (action.type) {
        case CheckoutActionTypes.UPDATE_PAYMENTTECHIFRAME:
        case CheckoutActionTypes.RECEIVE_ENTRY:
        case CheckoutActionTypes.RECEIVE_SHIPPINGOPTION:
        case CheckoutActionTypes.RECEIVE_SHIPPINGPROTECTION:
        case CheckoutActionTypes.RECEIVE_PAYMENT:
        case CheckoutActionTypes.RECEIVE_PAYMENTTECH_URL:
        case CheckoutActionTypes.RECEIVE_PROMO_OR_GIFTCARD:
        case CheckoutActionTypes.RECEIVE_LOYALTY_CODE:
        case CheckoutActionTypes.RECEIVE_DEALERTERMS:
        case CheckoutActionTypes.RECEIVE_REMOVEPROMO:
        case CheckoutActionTypes.RECEIVE_REMOVELOYALTYCODE:
        case CheckoutActionTypes.RECEIVE_REMOVEGIFTCARD:
        case CheckoutActionTypes.RECEIVE_SHIPPING:
        case CheckoutActionTypes.RECEIVE_REMOVE_CARB_ITEMS:
        case CheckoutActionTypes.RECEIVE_REMOVE_DROPSHIP_ITEMS:
        case CheckoutActionTypes.RECEIVE_REMOVE_TRUCK_FREIGHT_ITEMS:
        case CheckoutActionTypes.RECEIVE_REMOVE_ITEMS:
        case CheckoutActionTypes.RECEIVE_ACKNOWLEDGE_CARB_TERMS:
        case CheckoutActionTypes.RECEIVE_CONFIRMATION_PAGE:
        case CheckoutActionTypes.RECEIVE_UPDATEADDRESSBOOK:
        case CheckoutActionTypes.RECEIVE_CUSTOMERINFO:
        case CheckoutActionTypes.RECEIVE_SHIPPINGMETHODSTEP:
        case CheckoutActionTypes.RECEIVE_SUBMITCUSTOMERINFO:
        case CheckoutActionTypes.RECEIVE_UPDATESHIPPINGADDRESS:
        case CheckoutActionTypes.RECEIVE_PAYPAL_EXPRESS:
        case CheckoutActionTypes.RECEIVE_MOBILE_PAY:
            return updateState(state, action)
        case ShoppingCartActionTypes.RECEIVE_SHOPPINGCARTPAGE:
        case ShoppingCartActionTypes.RECEIVE_UPDATE_SHOPPINGCART_PRODUCTS:
        case ShoppingCartActionTypes.RECEIVE_MOVE_CART_ITEM_TO_BUILD_LIST:
        case ShoppingCartActionTypes.RECEIVE_MOVE_BUILD_LIST_ITEM_TO_CART:
            return updateStateFromShoppingCart(state, action)
        default:
            return state
    }

}
export default orderDetailsReducer

function updateState(state, action) {
    if (action.payload.checkoutTransactionRm.orderDetails) {
        return {
            ...state,
            itemCount: action.payload.checkoutTransactionRm.orderDetails.itemCount,
            lineItemsSubtotal: action.payload.checkoutTransactionRm.orderDetails.lineItemsSubtotal,
            estimatedSalesTax: action.payload.checkoutTransactionRm.orderDetails.estimatedSalesTax,
            shippingPrice: action.payload.checkoutTransactionRm.orderDetails.shippingPrice,
            totalPrice: action.payload.checkoutTransactionRm.orderDetails.totalPrice,
            isOrderSummaryToggleActive: action.payload.checkoutTransactionRm.orderDetails.isOrderSummaryToggleActive,
            futureItemCount: action.payload.checkoutTransactionRm.orderDetails.futureItemCount,
            futurePostDiscountTotalPrice: action.payload.checkoutTransactionRm.orderDetails.futurePostDiscountTotalPrice,
            futureEstimatedSalesTax: action.payload.checkoutTransactionRm.orderDetails.futureEstimatedSalesTax,
            futureShippingPrice: action.payload.checkoutTransactionRm.orderDetails.futureShippingPrice,
            futureLineItemsSubtotal: action.payload.checkoutTransactionRm.orderDetails.futureLineItemsSubtotal,
            currentAmountDue: action.payload.checkoutTransactionRm.orderDetails.currentAmountDue,
            postDiscountShippingPrice: action.payload.checkoutTransactionRm.orderDetails.postDiscountShippingPrice,
            currentPromos: action.payload.checkoutTransactionRm.orderDetails.currentPromos,
            futurePromos: action.payload.checkoutTransactionRm.orderDetails.futurePromos,
            currentGiftCards: action.payload.checkoutTransactionRm.orderDetails.currentGiftCards,
            futureGiftCards: action.payload.checkoutTransactionRm.orderDetails.futureGiftCards,
            currentLoyaltyRedemption: action.payload.checkoutTransactionRm.orderDetails.currentLoyaltyRedemption,
            futureLoyaltyRedemption: action.payload.checkoutTransactionRm.orderDetails.futureLoyaltyRedemption,
            totalDiscount: action.payload.checkoutTransactionRm.orderDetails.totalDiscount,
            retailDeliveryFee: action.payload.checkoutTransactionRm.orderDetails.retailDeliveryFee,
            futureRetailDeliveryFee: action.payload.checkoutTransactionRm.orderDetails.futureRetailDeliveryFee,
            currentShippingProtectionOptions: action.payload.checkoutTransactionRm.orderDetails.currentShippingProtectionOptions,
            futureShippingProtectionOptions: action.payload.checkoutTransactionRm.orderDetails.futureShippingProtectionOptions
        };
    } else {
        return {
            ...state,
            estimatedSalesTax: "--",
            shippingPrice: "--",
            totalPrice: "--",
            futurePostDiscountTotalPrice: "--",
            futureEstimatedSalesTax: "--",
            futureShippingPrice: "--",
            currentAmountDue: "--",
            postDiscountShippingPrice: "--",
            retailDeliveryFee: null,
            futureRetailDeliveryFee: null,
            currentLoyaltyRedemption: null,
            futureLoyaltyRedemption: null
        }
    }
}

function updateStateFromShoppingCart(state, action) {
    if (action.payload.orderDetails) {
        return {
            ...state,
            itemCount: action.payload.orderDetails.itemCount,
            lineItemsSubtotal: action.payload.orderDetails.lineItemsSubtotal,
            estimatedSalesTax: action.payload.orderDetails.estimatedSalesTax,
            shippingPrice: action.payload.orderDetails.shippingPrice,
            totalPrice: action.payload.orderDetails.totalPrice,
            isOrderSummaryToggleActive: action.payload.orderDetails.isOrderSummaryToggleActive,
            futureItemCount: action.payload.orderDetails.futureItemCount,
            futurePostDiscountTotalPrice: action.payload.orderDetails.futurePostDiscountTotalPrice,
            futureEstimatedSalesTax: action.payload.orderDetails.futureEstimatedSalesTax,
            futureShippingPrice: action.payload.orderDetails.futureShippingPrice,
            currentAmountDue: action.payload.orderDetails.currentAmountDue,
            postDiscountShippingPrice: action.payload.orderDetails.postDiscountShippingPrice,
            currentPromos: action.payload.orderDetails.currentPromos,
            futurePromos: action.payload.orderDetails.futurePromos,
            currentGiftCards: action.payload.orderDetails.currentGiftCards,
            futureGiftCards: action.payload.orderDetails.futureGiftCards,
            currentLoyaltyRedemption: action.payload.orderDetails.currentLoyaltyRedemption,
            futureLoyaltyRedemption: action.payload.orderDetails.futureLoyaltyRedemption,
            retailDeliveryFee: action.payload.orderDetails.retailDeliveryFee,
            futureRetailDeliveryFee: action.payload.orderDetails.futureRetailDeliveryFee,
            currentShippingProtectionOptions: action.payload.orderDetails.currentShippingProtectionOptions,
            futureShippingProtectionOptions: action.payload.orderDetails.futureShippingProtectionOptions
        };
    } else {
	    return {
		    ...state,
		    estimatedSalesTax: "--",
		    shippingPrice: "--",
		    totalPrice: "--",
		    futurePostDiscountTotalPrice: "--",
		    futureEstimatedSalesTax: "--",
		    futureShippingPrice: "--",
		    currentAmountDue: "--",
            postDiscountShippingPrice: "--",
            retailDeliveryFee: null,
            futureRetailDeliveryFee: null,
            currentLoyaltyRedemption: null,
            futureLoyaltyRedemption: null,
            currentShippingProtectionOptions: null,
            futureShippingProtectionOptions: null
	    }
    }
}
