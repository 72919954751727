import { combineReducers } from 'redux'
import { routerReducer } from 'react-router-redux'
import transactionReducer from "Reducers/transactionReducer"
import shoppingCartReducer from "Reducers/shoppingCartReducer"
import productReducer from "Reducers/productReducer"
import orderDetailsReducer from "Reducers/orderDetailsReducer"
import loaderReducer from "Reducers/loaderReducer"
import { accountSignUpReducer } from "Containers/Shared/AccountSignUp/AccountSignUp"
import {antiForgeryTokenReducer} from "Components/Form/AntiForgeryToken"
import  userProfileReducer  from "Reducers/userProfileReducer"
import  featureFlagReducer  from "Reducers/featureFlagReducer"


const rootReducer = combineReducers({
    loader: loaderReducer,
    transaction: transactionReducer,
    shoppingCart: shoppingCartReducer,
    products: productReducer,
    orderDetails: orderDetailsReducer,
	routing: routerReducer,
    accountSignUp: accountSignUpReducer,
    antiForgeryToken: antiForgeryTokenReducer,
    featureFlagContainer: featureFlagReducer,
    userProfile: userProfileReducer
})
export default rootReducer