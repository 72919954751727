import * as ActionTypes from 'Types/CheckoutActionTypes'
import {initialAddressFormState} from "Reducers/transactionReducer"

export const submitCheckout = () => ({
    type: ActionTypes.SUBMIT_CHECKOUT
})

//diable the loader for failed requests

export const disableLoader = () => ({
    type: ActionTypes.DISABLE_LOADER
})

export const enableLoader = () => ({
    type: ActionTypes.ENABLE_LOADER
})

export const sendPaypalStepCompletedEvent = () => ({ 
    type: ActionTypes.SEND_PAYPAL_STEP_COMPLETED_EVENT
})

export const sendMobilePayStepCompletedEvent = (payload) => ({ 
    type: ActionTypes.SEND_MOBILE_PAY_STEP_COMPLETED_EVENT,
    payload: payload
})

export const displayErrors = (errors) => ({
    type: ActionTypes.DISPLAY_ERRORS,
    errors
})

export const displayWarnings = (payload) => ({
    type: ActionTypes.DISPLAY_WARNINGS,
    payload: payload
})

export const displayErrorsToCustomBilling = (billingAddress) => ({
    type: ActionTypes.DISPLAY_ERRORS_TO_CUSTOM_BILLING,
    payload: billingAddress
})
//redirect to external url
export const sendRedirectAction = () => ({
    type: ActionTypes.REDIRECT_ACTION
})
export const redirectAction = (url) => dispatch => {
    dispatch(sendRedirectAction)
    window.location.href = url
}

// entry fetch
export const requestEntry = () => ({
    type: ActionTypes.REQUEST_ENTRY
})
export const receiveEntry = (json) => ({
    type: ActionTypes.RECEIVE_ENTRY,
    payload: json
})

export const fetchEntry = (parameters, history) => dispatch => {
    dispatch(requestEntry())

    return fetch("/data/buy/entry" + parameters, {
        method: 'POST',
        'Content-Type': 'application/json',
        credentials: 'include'
    })
        .then(response => response.json())
        .then(json => {
            if (!json.status.success) {
                handleErrors(json.status, true, dispatch)
            }
            if (json.checkoutTransactionRm) {
                window.localStorage.setItem("TransactionId", json.checkoutTransactionRm.transactionId)
                dispatch(receiveEntry(json));
            }
            // redirect to url in response
            if (json.status.success && json.status.redirectUrl) {
                history.replace(json.status.redirectUrl);
            }
        })
        .catch(error => {
            console.error('fetchEntry Error:', error)
            dispatch(disableLoader())
            alert('Sorry, an error occurred.  Please refresh the page and try again.')
        })

}

//customerInfo fetch
export const requestCustomerInfo = () => ({
    type: ActionTypes.REQUEST_CUSTOMERINFO
})

export const receiveCustomerInfo = (json) => ({
    type: ActionTypes.RECEIVE_CUSTOMERINFO,
    payload: json
})

export const fetchCustomerInfo = () => (dispatch, getState) => {
    dispatch(requestCustomerInfo())

    return fetch("/data/buy/customerinfo",
        {
            credentials: 'include'
        })
        .then(response => response.json())
        .then(json => {
            if (!json.status.success) {
                handleErrors(json.status, true, dispatch)
            }
            if (json.checkoutTransactionRm) {
                dispatch(receiveCustomerInfo(json));
                if (getState().transaction.paymentMethodContainer.paymentTechUrl) {
                    dispatch(erasePaymentTechIframe())
                    dispatch(disableLoader())
                }

            }
        })
        .catch(error => {
            console.error('fetchCustomerInfo Error:', error)
            dispatch(disableLoader())
            alert('Sorry, an error occurred.  Please refresh the page and try again.')
        })
}

export const updateCustomerInfoAddressForm = (updatedFormField, addressHasBeenValidated) => ({
    type: ActionTypes.UPDATE_CUSTOMER_INFO_ADDRESS_FORM,
    payload: {
        updatedFormField: updatedFormField,
        addressHasBeenValidated: addressHasBeenValidated
    }
})

export const updateCarbForm = (updatedFormField) => ({
	type: ActionTypes.UPDATE_CARB_FORM,
	payload: updatedFormField
})

export const updateFormValidation = (updateFormField) => ({
    type: ActionTypes.UPDATE_FORM_VALIDATION,
    payload: updateFormField
})

export const removeWarningValidation = () => ({

    type: ActionTypes.REMOVE_WARNING_VALIDATION
})

export const displaySmartyStreetWarnings = (addressValidationErrors) => ({
    type: ActionTypes.DISPLAY_SMARTY_STREET_WARNINGS,
    payload: addressValidationErrors
})

export const updateSmartyStreetWarnings = (payload) => ({
    type: ActionTypes.UPDATE_WARNING_VALIDATION,
    payload: payload
})

export const updateAddressBookAddressForm = (updatedFormField, addressHasBeenValidated) => ({
    type: ActionTypes.UPDATE_ADDRESS_BOOK_ADDRESS_FORM,
    payload: {
        updatedFormField: updatedFormField,
        addressHasBeenValidated: addressHasBeenValidated
    }
})

export const updateBillingAddressForm = (updatedFormField, addressHasBeenValidated) => ({
    type: ActionTypes.UPDATE_BILLING_ADDRESS_FORM,
    payload: {
        updatedFormField: updatedFormField,
        addressHasBeenValidated: addressHasBeenValidated
    }
})

export const validateBillingAddressForm = () => (dispatch, getState) => {
    const form = getState().transaction.billingAddressForm;
    dispatch(updateBillingAddressForm(form));
}

export const updatePayPalExpressAddressForm = (updatedFormField) => ({
    type: ActionTypes.UPDATE_PAYPAL_EXPRESS_ADDRESS_FORM,
    payload: updatedFormField
})

export const updateEmailSignUpForm = (updatedFormField) => ({
    type: ActionTypes.UPDATE_EMAIL_SIGN_UP,
    payload: updatedFormField
})

export const updateLoyaltySignUpForm = (updatedFormField) => ({
    type: ActionTypes.UPDATE_LOYALTY_SIGN_UP,
    payload: updatedFormField
})

//submit shipping method step
export const requestSubmitCustomerInfo = (history) => ({
    type: ActionTypes.REQUEST_SUBMITCUSTOMERINFO,
    history
})

export const enablePaymentTechOverlay = () => ({
    type: ActionTypes.ENABLE_PAYMENTTECH_OVERLAY
})

export const receiveSubmitCustomerInfo = (json) => ({
    type: ActionTypes.RECEIVE_SUBMITCUSTOMERINFO,
    payload: json
})

export const submitCustomerInfoStep = (history) => (dispatch) => {
    dispatch(requestSubmitCustomerInfo(history))
}

export const requestSmartyStreetAddresses = (addressToComplete) => ({
    type: ActionTypes.REQUEST_SMARTY_STREET_ADDRESSES,
    payload: addressToComplete
})

export const receiveSmartyStreetAddresses = (json) => ({
    type: ActionTypes.RECEIVE_SMARTY_STREET_ADDRESSES,
    payload: json
})

export const requestValidatedSmartyStreetAddress = (address, updateFormAction, formAddress) => ({
    type: ActionTypes.REQUEST_VALIDATED_SMARTY_STREET_ADDRESS,
    payload: {
        address: address,
        updateFormAction: updateFormAction,
        formAddress: formAddress
    }
})

export const receiveValidatedSmartyStreetAddress = (json) => ({
    type: ActionTypes.RECEIVE_VALIDATED_SMARTY_STREET_ADDRESS,
    payload: json
})

export const receiveAddressValidationStatus = (payload) => ({
    type: ActionTypes.RECEIVE_ADDRESS_VALIDATION_ERRORS,
    payload: payload
})

export const receiveEmailValidationError = () => ({
    type: ActionTypes.RECEIVE_EMAIL_VALIDATION_ERROR,
})

export const updateAdditionalAddressFormData = (payload) => ({
    type: ActionTypes.UPDATE_ADDITIONAL_ADDRESS_FORM_DATA,
    payload
})

//submit shipping method step
export const requestSubmitPaypalExpress = (payload) => ({
    type: ActionTypes.REQUEST_SUBMIT_PAYPALEXPRESS,
    payload
})

export const receiveSubmitPaypalExpress = (json) => ({
    type: ActionTypes.RECEIVE_SUBMIT_PAYPALEXPRESS,
    payload: json
})

export const submitPaypalExpress = (phoneRequiredBool) => (dispatch) => {
    dispatch(requestSubmitPaypalExpress(phoneRequiredBool))
}


//shipping fetch
export const requestShipping = () => ({
    type: ActionTypes.REQUEST_SHIPPING
})

export const receiveShipping = (json) => ({
    type: ActionTypes.RECEIVE_SHIPPING,
    payload: json
})

export const fetchShippingInfo = (transactionId, history) => dispatch => {
    dispatch(requestShipping())

    return fetch("/data/buy/shipping?transactionId=" + transactionId,
        {
            credentials: 'include'
        })
        .then(response => response.json())
        .then(json => {
            if (!json.status.success) {
                handleErrors(json.status, true, dispatch)
            }
            if (json.checkoutTransactionRm) {
                //29 minutes client side timeout
                setTimeout(function () { window.location.href = "/shoppingcart?timeout=1"; }, 1740000);
                dispatch(receiveShipping(json));
            }
        })
        .catch(error => {
            console.error('fetchShippingInfo Error:', error)
            dispatch(disableLoader())
            alert('Sorry, an error occurred.  Please refresh the page and try again.')
        })
}


//shipping options fetch
export const requestShipppingOption = (transactionId) => ({
    type: ActionTypes.REQUEST_SHIPPINGOPTION,
    payload: transactionId
})

export const receiveShippingOption = (json) => ({
    type: ActionTypes.RECEIVE_SHIPPINGOPTION,
    payload: json
})

function BuildSelectedShippingOptions(transactionId, standardShipments, featureFlagContainer, shipmentToUpdate, shipmentToUpdateShippingOption, shipmentToUpdateInstorePickupLocationId, selectedDisplayedShippingOption, selectedHiddenShippingOption, selectedShippingOption){
    let shippingOptions = []
    var updatedStandardShipments = new Array()

    if (standardShipments && !standardShipments.length) {
        updatedStandardShipments.push(standardShipments)
    }
    else {
        updatedStandardShipments = standardShipments;
    }

    updatedStandardShipments.forEach(function(shipment){
        let shippingOption = {
            ShipmentId: shipment.shipmentId,
            ShippingOptionId: null,
            InStorePickupLocationId: null,
            InStorePickupDate: null,
            TransactionId: transactionId
        }

        let currentlySelectedDisplayedShippingOption = shipment.displayedShippingOptions.find(x => x.isSelected);
        let currentlySelectedHiddenShippingOption = shipment.hiddenShippingOptions.find(x => x.isSelected);

        if(shipmentToUpdate == shipment.shipmentId){
            shippingOption = SetInstorePickupPropertiesForShippingOption(shipment, shippingOption, shipmentToUpdateShippingOption, shipmentToUpdateInstorePickupLocationId, selectedDisplayedShippingOption, selectedHiddenShippingOption, selectedShippingOption)
        }
        else{
            shippingOption = SetInstorePickupPropertiesForShippingOption(shipment, shippingOption, null, null, currentlySelectedDisplayedShippingOption, currentlySelectedHiddenShippingOption, selectedShippingOption)
        }

        shippingOptions.push(shippingOption)
    })

    return shippingOptions
}

function SetInstorePickupPropertiesForShippingOption(shipment, shippingOption, shipmentToUpdateShippingOption, shipmentToUpdateInstorePickupLocationId, displayedShippingOption, hiddenShippingOption, selectedShippingOption ){
    let instorePickupLocationId = shipmentToUpdateInstorePickupLocationId
    let inStorePickupDate = null
    let shippingOptionId = shipmentToUpdateShippingOption

    if(instorePickupLocationId !== null && displayedShippingOption){
        if(displayedShippingOption.inStorePickupOptions && displayedShippingOption.inStorePickupOptions.length >= 1){

            let chosenStore = displayedShippingOption.inStorePickupOptions.find(option => option.inStorePickupId == instorePickupLocationId)
            // if we cannot find a selected this is a backup to set the location as the first we have
            if(!chosenStore){
                chosenStore = displayedShippingOption.inStorePickupOptions[0]
            }
    
            instorePickupLocationId = chosenStore.inStorePickupId
            inStorePickupDate = chosenStore.pickupReadyDate
        }
    }
    else if(displayedShippingOption || hiddenShippingOption) {
        let currentShippingOption = displayedShippingOption ? displayedShippingOption : (hiddenShippingOption ? hiddenShippingOption : null)

        shippingOptionId = shippingOptionId == null ? currentShippingOption.shippingMethodId : shippingOptionId
        if(currentShippingOption && currentShippingOption.inStorePickupOptions && currentShippingOption.inStorePickupOptions.length >= 1){

            let chosenStore = currentShippingOption.inStorePickupOptions.find(option => option.isSelected)
            // if we cannot find a selected this is a backup to set the location as the first we have
            if(!chosenStore){
                chosenStore = currentShippingOption.inStorePickupOptions[0]
            }
    
            instorePickupLocationId = chosenStore.inStorePickupId
            inStorePickupDate = chosenStore.pickupReadyDate
        }

    }

    shippingOption.ShippingOptionId = shippingOptionId
    shippingOption.InStorePickupLocationId = instorePickupLocationId
    shippingOption.InStorePickupDate = inStorePickupDate

    return shippingOption
}

export const selectShippingOption = (shipmentId, shippingOptionId, instorePickupLocationId, modal = false) => (dispatch, getState) => {
    const transaction = getState().transaction
    const featureFlagContainer = getState().featureFlagContainer

    // If the clicked option is already selected, don't do anything.
    let selectedShipment = transaction.shippingMethodContainer.shipments.find(x => x.shipmentId === shipmentId);

    let selectedDisplayedShippingOption = selectedShipment.displayedShippingOptions.find(x => x.shippingMethodId === shippingOptionId);
    let selectedHiddenShippingOption = selectedShipment.hiddenShippingOptions.find(x => x.shippingMethodId === shippingOptionId);

    if(selectedDisplayedShippingOption && selectedDisplayedShippingOption.inStorePickupOptions
        && selectedDisplayedShippingOption.inStorePickupOptions.length > 0 
        && selectedShipment.selectedShippingOption.inStorePickupOptions
        && selectedShipment.selectedShippingOption.inStorePickupOptions.length > 0
        && selectedShipment.selectedShippingOption.inStorePickupOptions.find(x => x.isSelected).inStorePickupId === selectedDisplayedShippingOption.inStorePickupOptions.find(x => x.isSelected).inStorePickupId){
            return () => {}
    }

    if (
        (
            selectedDisplayedShippingOption && selectedDisplayedShippingOption.isSelected ||
            selectedHiddenShippingOption && selectedHiddenShippingOption.isSelected
        )
        && !modal) {
        return () => { };
    }

    /* pickup date and id will not go away for instore pickup when we remove feature flag. */
    dispatch(requestShipppingOption(transaction.transactionId))

    let standardShipments = transaction.shippingMethodContainer.shipments.filter(x => x.shipmentTag.includes("standard"))
    let selectedShippingOptions = BuildSelectedShippingOptions(transaction.transactionId, standardShipments, featureFlagContainer, shipmentId, shippingOptionId, instorePickupLocationId, selectedDisplayedShippingOption, selectedHiddenShippingOption, 
        selectedShipment.selectedShippingOption)
    
    let shippingOptions = {
        shippingOptions: selectedShippingOptions,
        transactionId: transaction.transactionId
    }

    return fetch("/data/buy/selectshippingoption",
        {
            method: 'POST', // or 'PUT'
            body: JSON.stringify(shippingOptions), // data can be `string` or {object}!
            headers: {
                'Content-Type': 'application/json'
            },
            credentials: 'include'
        })
        .then(response => response.json())
        .then(json => {
            if (!json.status.success) {
                handleErrors(json.status, true, dispatch)
            }
            if (json.checkoutTransactionRm) {
                dispatch(receiveShippingOption(json));
            }
        })
        .catch(error => {
            console.error('selectShippingOption Error:', error)
            dispatch(disableLoader())
            alert('Sorry, an error occurred.  Please refresh the page and try again.')
        })
}

//shipping options fetch
export const requestShipppingProtection = (transactionId) => ({
    type: ActionTypes.REQUEST_SHIPPINGPROTECTION,
    payload: transactionId
})

export const receiveShippingProtection = (json) => ({
    type: ActionTypes.RECEIVE_SHIPPINGPROTECTION,
    payload: json
})

export const selectShippingProtection = (shippingProtectionId, modal = false) => (dispatch, getState) => {

    const transaction = getState().transaction

    // If the clicked option is already selected, don't do anything.
    let selectedShippingProtectionOption = transaction.shippingProtectionOptionsContainer.shippingProtectionOptions.find(x => x.shippingOptionId === shippingProtectionId);

    if (selectedShippingProtectionOption && selectedShippingProtectionOption.isSelected) {
        return () => { }
    }

    dispatch(requestShipppingOption(transaction.transactionId))

    let shippingProtection = {
        shippingProtectionOptionId: shippingProtectionId,
        transactionId: transaction.transactionId
    }

    return fetch("/data/buy/selectshippingprotection",
        {
            method: 'POST',
            body: JSON.stringify(shippingProtection), 
            headers: {
                'Content-Type': 'application/json'
            },
            credentials: 'include'
        })
        .then(response => response.json())
        .then(json => {
            if (!json.status.success) {
                handleErrors(json.status, true, dispatch)
            }
            if (json.checkoutTransactionRm) {
                dispatch(receiveShippingProtection(json));
            }
        })
        .catch(error => {
            console.error('selectShippingProtection Error:', error)
            dispatch(disableLoader())
            alert('Sorry, an error occurred.  Please refresh the page and try again.')
        })
}

export const showAddressBook = (json) => ({
    type: ActionTypes.ENABLE_ADDRESSBOOK,
    payload: json
})

export const hideAddressBook = () => ({
    type: ActionTypes.DISABLE_ADDRESSBOOK
})

export const updateAddressBookDisplay = (isAddressBookDisplayed, doesAddressNeedValidated = false) => (dispatch, getState) => {
    const transactionId = getState().transaction.transactionId

    if (isAddressBookDisplayed) {
        dispatch(
            showAddressBook({
                transactionId: transactionId,
                showAddressFields: doesAddressNeedValidated
            })
        )
    } else {
        dispatch(hideAddressBook())
    }
}

export const requestUpdateShippingAddress = (transactionId) => ({
    type: ActionTypes.REQUEST_UPDATESHIPPINGADDRESS,
    payload: transactionId
})

export const receiveUpdateShippingAddress = (json) => ({
    type: ActionTypes.RECEIVE_UPDATESHIPPINGADDRESS,
    payload: json
})

export const updateShippingAddress = (address, isBraintreePaypalAddress = false, isMobilePayAddress = false, customerOverride = false) => (dispatch, getState) => {

    const { transactionId, addressLookup, isAddressBookDisplayed, shippingAddressSuggestion } = getState().transaction

    // If the clicked option is already selected, don't do anything.
    const selectedAddressId = addressLookup.shippingAddressId

    if (!address) {
        return () => { };
    }

    if (address.addressId === selectedAddressId) {
        return () => { };
    }

    dispatch(requestUpdateShippingAddress(transactionId))

    const data = {
        transactionAddress: {
            transactionId: transactionId,
            IsShipping: true,
            IsBilling: true, // this should set billing and shipping at this point to the new address entered.
            transactionAddress: {
                addressId: address.addressId,
                addressLine1: address.addressLine1,
                addressLine2: address.addressLine2,
                city: address.city,
                province: address.province,
                provinceId: address.provinceId,
                countryId: address.countryId,
                postalCode: address.postalCode,
                fullName: address.fullName,
                phoneNumber: address.phoneNumber,
                email: address.email,
                validationType: address.validationType,
                addressName: address.addressLine1, // this is default behavior during checkout. we need an address name to be valid in the address book
                company: address.company
            },
            customerOverride: customerOverride
        },
        isBraintreePaypalAddress: isBraintreePaypalAddress,
        isMobilePayAddress: isMobilePayAddress
    };

    return fetch("/data/buy/updatetransactionaddress",
        {
            method: 'POST', // or 'PUT'
            body: JSON.stringify(data), // data can be `string` or {object}!
            headers: {
                'Content-Type': 'application/json'
            },
            credentials: 'include'
        })
        .then(response => response.json())
        .then(json => {
            if (!json.status.success) {
                handleErrors(json.status, true, dispatch)
            }
            if (json.checkoutTransactionRm) {
                if(isBraintreePaypalAddress){
                    let shippingAddressId = json.checkoutTransactionRm.paymentMethodContainer.shipToAddressId;
                    let shippingAddress = json.checkoutTransactionRm.addresses[shippingAddressId];
                    json.checkoutTransactionRm["paypalAddressForm"] = shippingAddress;
                    json.checkoutTransactionRm["isCleanPaypalAddress"] = shippingAddressId ? true : false

                    dispatch(receivePaypal(json));
                }
                else{
                    dispatch(receiveUpdateShippingAddress(json))

                    if (isAddressBookDisplayed) {
                        dispatch(hideAddressBook())
                    }
                }
            }
        })
        .catch(error => {
            console.error('updateShippingAddress Error:', error)
            dispatch(disableLoader())
            alert('Sorry, an error occurred.  Please refresh the page and try again.')
        })
}

export const requestAddAddressToAddressBook = (isShipping, isBilling, transactionId) =>  ({
    type: ActionTypes.REQUEST_UPDATEADDRESSBOOK,
    payload: {
        transactionId: transactionId,
        isShipping: isShipping,
        isBilling: isBilling
    }
})

export const receiveAddAddressToAddressBook = (json) => ({
    type: ActionTypes.RECEIVE_UPDATEADDRESSBOOK,
    payload: json
})

export const updateAddressBook = (isShipping, isBilling) => (dispatch, getState) => {
    const { transactionId, addressBookAddressForm } = getState().transaction

    if (!addressBookAddressForm) {
        return () => { };
    }

    dispatch(requestAddAddressToAddressBook(isShipping, isBilling, transactionId))
}

export const resetAddressBookAddress = (address) => ({
    type: ActionTypes.RESET_ADDRESSBOOKADDRESS,
    payload: address
})

export const resetAddressBookAddressForm = (address) => (dispatch, getState) => {
    let { email, fullName } = getState().userProfile;

    if (!address) {
        address = {
            ...initialAddressFormState
        };
    }

    // https://speedwaymotors.atlassian.net/browse/WIFR-20023
    if (!address.email) {
        address.email = email;
    }
    if (!address.fullName) {
        address.fullName = fullName;
    }

    dispatch(resetAddressBookAddress(address));
}

//payment fetch
export const requestPayment = () => ({
    type: ActionTypes.REQUEST_PAYMENT
})

export const updatePaymentTechIframe = (json) => ({
    type: ActionTypes.UPDATE_PAYMENTTECHIFRAME,
    payload: json
})

export const erasePaymentTechIframe = () => ({
    type: ActionTypes.ERASE_PAYMENTTECHIFRAME
})

export const receivePayment = (json) => ({
    type: ActionTypes.RECEIVE_PAYMENT,
    payload: json
})

// Even though the react layout will automatically select an option, 
// it won't update the redux state for the initial page load.
// The following sets the redux state to the option that the ui will default to.
export const setInitialBillingAddressId = (dispatch, hasDefaultBillingAddress, hasShippingAddress) => {
    if (hasDefaultBillingAddress) {
        dispatch(selectDefaultBillingAddress());
    } else if (hasShippingAddress) {
        dispatch(selectSameAsShippingBillingAddress());
    }
}

export const fetchPaymentInfo = (transactionId, history) => dispatch => {
    dispatch(requestPayment())

    return fetch("/data/buy/payment?transactionId=" + transactionId,
        {
            credentials: 'include'
        })
        .then(response => response.json())
        .then(json => {
            if (!json.status.success) {
                handleErrors(json.status, true, dispatch)
            }
            if (json.checkoutTransactionRm) {
                let { checkoutTransactionRm } = json;

                if (checkoutTransactionRm.carb) {
                    history.push('/buy/shipping?transactionId=' + transactionId)
                }

                if (!checkoutTransactionRm.paymentProcessorClientAuthToken) {
                    console.error('fetch request returned with invalid paymentProcessorClientAuthToken: ' + checkoutTransactionRm.paymentProcessorClientAuthToken)
                }

                dispatch(receivePayment(json));

                setInitialBillingAddressId(dispatch, checkoutTransactionRm.paymentMethodContainer.hasDefaultBillingAddress, checkoutTransactionRm.paymentMethodContainer.hasShippingAddress)
            }
        })
        .catch(error => {
            console.error('fetchPaymentInfo Error:', error)
            dispatch(disableLoader())
            alert('Sorry, an error occurred.  Please refresh the page and try again.')
        })
}


export const handlePaymentOptionSelection = (paymentMethod) => ({
    type: ActionTypes.UPDATE_PAYMENTMETHOD,
    payload: paymentMethod
})


export const paymentTechIframeLoaded = () => (dispatch, getState) => {
    if (getState().transaction.paymentMethodContainer.paymentTechUrl) {
        dispatch(disableLoader());
    }
}

export const paymentTech_creHandleDetailErrors = (errorCodes, gatewayCode, gatewayMessage) => dispatch => {
    dispatch(disableLoader());

    if (!errorCodes) {
        return () => { };
    }

    var lastChar = errorCodes.slice(-1);
    if (lastChar === "|") {
        errorCodes = errorCodes.slice(0, -1);
    }
    errorCodes = errorCodes.split("|");

    var errorMessages = [];

    for (var i = 0; i < errorCodes.length; i++) {
        var errorMessage = "Error message: " + errorCodes[i]
        switch (errorCodes[i]) {
            case "000":
                errorMessage = "Successful request.";
                break;
            case "100":
                errorMessage = "Merchant Identifier left blank or not valid. The transaction was not processed.";
                break;
            case "110":
                errorMessage = "Session Identifier left blank. The transaction was not processed.";
                break;
            case "200":
                errorMessage = "Name not present.";
                break;
            case "300":
                errorMessage = "Amount not specified or invalid value entered.";
                break;
            case "310":
                errorMessage = "Invalid Credit Card Number.";
                break;
            case "315":
                errorMessage = "Invalid Credit Card Number.";
                break;
            case "320":
                errorMessage = "Credit card type left blank or invalid.";
                break;
            case "330":
                errorMessage = "Expiration month left blank.";
                break;
            case "340":
                errorMessage = "Expiration year left blank.";
                break;
            case "350":
                errorMessage = "Security Code submitted but does not match the card.";
                break;
            case "355":
                errorMessage = "Security Code required but not present.";
                break;
            case "357":
                errorMessage = "An invalid character was entered, such as a letter in a numeric field.";
                break;
            case "360":
                errorMessage = "Payment declined by financial institution, or some other error has occurred.";
                break;
            case "370":
                errorMessage = "Expiration date invalid.";
                break;
            case "400":
                errorMessage = "Transaction tracer value does not match.";
                break;
            case "500":
                errorMessage = "Address one field required but left blank.";
                break;
            case "510":
                errorMessage = "City field required but left blank.";
                break;
            case "520":
                errorMessage = "State field required but left blank.";
                break;
            case "530":
                errorMessage = "ZIP/postal code field required but left blank.";
                break;
            case "531":
                errorMessage = "Invalid ZIP/postal code format received.";
                break;
            case "550":
                errorMessage = "Country is missing.";
                break;
            case "600":
                errorMessage = "The Bank name was blank (ECP Only).";
                break;
            case "610":
                errorMessage = "The Routing Number is blank (ECP Only).";
                break;
            case "620":
                errorMessage = "The Checking Account number was blank (ECP Only).";
                break;
            case "630":
            case "640":
                errorMessage = "The Routing Number is invalid (ECP Only).";
                break;
            default:
                console.error(errorMessage);
                break;
        }

        if (errorMessages && errorMessages.length) {
            errorMessages.push("\r" + errorMessage);
        } else {
            errorMessages.push(errorMessage);
        }
    }

    if (gatewayMessage) {
        errorMessages.push("\r\rGateway Message: " + gatewayMessage);
    }

    alert(errorMessages.join(""));
}

export const paymentTech_startCREPayment = () => dispatch => {
    return dispatch(enableLoader());
}

export const updateBillingAddress = () => ({
    type: ActionTypes.UPDATE_BILLING_ADDRESS
});

export const requestPaymentInformation = (json) => ({
    type: ActionTypes.REQUEST_SUBMIT_PAYMENT_INFORMATION,
    payload: json
});

export const submitPaymentInformation = (json) => (dispatch) => {
    dispatch(requestPaymentInformation(json))
}

export const finalizePayment = (nonce) => ({
    type: ActionTypes.FINALIZE_PAYMENT,
    payload: nonce
});

export const selectSameAsShippingBillingAddress = () => ({
    type: ActionTypes.SELECT_SAME_AS_SHIPPING_BILLING_ADDRESS
})

export const selectDefaultBillingAddress = () => ({
    type: ActionTypes.SELECT_DEFAULT_BILLING_ADDRESS
})

export const selectCustomBillingAddress = () => ({
    type: ActionTypes.SELECT_CUSTOM_BILLING_ADDRESS
})

export const finalizeCreditCardPayment = () => ({
    type: ActionTypes.FINALIZE_CREDIT_CARD_PAYMENT
});

export const handlePromoOrGiftCardChange = (promoOrGiftCardCode) => ({
    type: ActionTypes.HANDLE_PROMO_OR_GIFTCARD_CHANGE,
    payload: promoOrGiftCardCode
})

export const handleLoyaltyCodeChange = (appliedCode, selectedCode) => ({
    type: ActionTypes.HANDLE_LOYALTY_CODE_CHANGE,
    payload: { Applied: appliedCode, Selected: selectedCode }
})

//promo or gift card fetch
export const requestPromoOrGiftCard = (promoOrGiftCardCode) => ({
    type: ActionTypes.REQUEST_PROMO_OR_GIFTCARD,
    payload: promoOrGiftCardCode
})

//loyalty code fetch
export const requestLoyaltyCode = (loyaltyCode) => ({
    type: ActionTypes.REQUEST_LOYALTY_CODE,
    payload: loyaltyCode
})

export const receivePromoOrGiftCard = (json) => ({
    type: ActionTypes.RECEIVE_PROMO_OR_GIFTCARD,
    payload: json
})

export const receiveLoyaltyCode = (json) => ({
    type: ActionTypes.RECEIVE_LOYALTY_CODE,
    payload: json
})

export const applyPromoOrGiftCard = (promoOrGiftCardCode, isCookiePromo = false) => (dispatch, getState) => {

    if (promoOrGiftCardCode === "") {
        return;
    }
  
    const { transactionId } = getState().transaction
    if (!transactionId) {
	    return;
    }

    dispatch(requestPromoOrGiftCard(promoOrGiftCardCode))

    let promoOrGiftCard = { PromoOrGiftCardCode: promoOrGiftCardCode, TransactionId: transactionId }

    return fetch("/data/buy/applypromoorgiftcard",
        {
            method: 'POST', // or 'PUT'
            body: JSON.stringify(promoOrGiftCard), // data can be `string` or {object}!
            headers: {
                'Content-Type': 'application/json'
            },
            credentials: 'include'
        })
        .then(response => response.json())
        .then(json => {
            if (!json.status.success) {
                //todo: this will need to be set to false to remove alerts
                handleErrors(json.status, true, dispatch)
            }

            if (json.checkoutTransactionRm) {
                // we set this to remove a promo cookie in the GiftCardPromoForm so we do not import a module here
                let { isDiscountApplied } = json.checkoutTransactionRm.paymentMethodContainer;
                if (isCookiePromo && !isDiscountApplied) {
                    json.checkoutTransactionRm.paymentMethodContainer["removeCookiePromo"] = true
                }

                // Clear the promo/gift card input if a promo/gift card was applied.
                // If the promo/gift card wasn't applied, put the code back in the input.
                if (isDiscountApplied) {
                    json.checkoutTransactionRm.paymentMethodContainer.currentPromoOrGiftCard = "";
                } else {
                    json.checkoutTransactionRm.paymentMethodContainer.currentPromoOrGiftCard = promoOrGiftCardCode;
                }

                dispatch(receivePromoOrGiftCard(json));
            }
        })
        .catch(error => {
            console.error('applyPromoOrGiftCard Error:', error)
            dispatch(disableLoader())
            alert('Sorry, an error occurred.  Please refresh the page and try again.')
        })
}

export const applyLoyaltyCode = (loyaltyCode) => (dispatch, getState) => {

    if (loyaltyCode === "") {
        return;
    }

    const { transactionId } = getState().transaction
    if (!transactionId) {
        return;
    }

    dispatch(requestLoyaltyCode(loyaltyCode))


    let loyaltyCodeBody = { LoyaltyCode: loyaltyCode ?? "", TransactionId: transactionId }

    return fetch("/data/buy/applyloyaltycode",
        {
            method: 'POST', // or 'PUT'
            body: JSON.stringify(loyaltyCodeBody), // data can be `string` or {object}!
            headers: {
                'Content-Type': 'application/json'
            },
            credentials: 'include'
        })
        .then(response => response.json())
        .then(json => {
            if (!json.status.success) {
                //todo: this will need to be set to false to remove alerts
                handleErrors(json.status, true, dispatch)
            }

            if (json.checkoutTransactionRm) {
                dispatch(receiveLoyaltyCode(json));
            }
        })
        .catch(error => {
            console.error('applyLoyaltyCode Error:', error)
            dispatch(disableLoader())
            alert('Sorry, an error occurred.  Please refresh the page and try again.')
        })
}

export const requestRemoveLoyaltyCode = (loyaltyCode) => ({
    type: ActionTypes.REQUEST_REMOVELOYALTYCODE,
    payload: loyaltyCode
})

export const receiveRemoveLoyaltyCode = (json) => ({
    type: ActionTypes.RECEIVE_REMOVELOYALTYCODE,
    payload: json
})

export const removeLoyaltyCode = (loyaltyCode) => (dispatch, getState) => {

    const { transactionId } = getState().transaction
    if (!transactionId) {
        return;
    }

    dispatch(requestRemoveLoyaltyCode(loyaltyCode))

    let loyaltyCodeBody = { LoyaltyCode: loyaltyCode, TransactionId: transactionId }

    return fetch("/data/buy/removeloyaltycode",
        {
            method: 'POST', // or 'PUT'
            body: JSON.stringify(loyaltyCodeBody), // data can be `string` or {object}!
            headers: {
                'Content-Type': 'application/json'
            },
            credentials: 'include'
        })
        .then(response => response.json())
        .then(json => {
            if (!json.status.success) {
                //todo: this will need to be set to false to remove alerts
                handleErrors(json.status, true, dispatch)
            }

            if (json.checkoutTransactionRm) {
                dispatch(receiveRemoveLoyaltyCode(json));
            }
        })
        .catch(error => {
            console.error('removeLoyaltyCode Error:', error)
            dispatch(disableLoader())
            alert('Sorry, an error occurred.  Please refresh the page and try again.')
        })
}

export const requestRemovePromo = (promoCode) => ({
    type: ActionTypes.REQUEST_REMOVEPROMO,
    payload: promoCode
})

export const receiveRemovePromo = (json) => ({
    type: ActionTypes.RECEIVE_REMOVEPROMO,
    payload: json
})

export const removePromo = (promoCode) => (dispatch, getState) => {

    dispatch(requestRemovePromo(promoCode))
    const { transactionId } = getState().transaction

    let promoModel = { PromoCode: promoCode, TransactionId: transactionId }

    return fetch("/data/buy/removepromo",
        {
            method: 'POST', // or 'PUT'
            body: JSON.stringify(promoModel), // data can be `string` or {object}!
            headers: {
                'Content-Type': 'application/json'
            },
            credentials: 'include'
        })
        .then(response => response.json())
        .then(json => {
            if (!json.status.success) {
                handleErrors(json.status, true, dispatch)
            }
            if (json.checkoutTransactionRm) {
                dispatch(receiveRemovePromo(json));
            }
        })
        .catch(error => console.error('removePromo Error:', error))
}

export const requestRemoveGiftCard = (giftCardNumber) => ({
    type: ActionTypes.REQUEST_REMOVEGIFTCARD,
    payload: giftCardNumber
})

export const receiveRemoveGiftCard = (json) => ({
    type: ActionTypes.RECEIVE_REMOVEGIFTCARD,
    payload: json
})

export const removeGiftCard = (giftCardNumber) => (dispatch, getState) => {

    dispatch(requestRemoveGiftCard(giftCardNumber))
    const { transactionId } = getState().transaction

    let giftCardModel = { GiftCardNumber: giftCardNumber, TransactionId: transactionId }

    return fetch("/data/buy/removegiftcard",
        {
            method: 'POST', // or 'PUT'
            body: JSON.stringify(giftCardModel), // data can be `string` or {object}!
            headers: {
                'Content-Type': 'application/json'
            },
            credentials: 'include'
        })
        .then(response => response.json())
        .then(json => {
            if (!json.status.success) {
                handleErrors(json.status, true, dispatch)
            }
            if (json.checkoutTransactionRm) {
                dispatch(receiveRemoveGiftCard(json));
            }
        })
        .catch(error => console.error('removeGiftCard Error:', error))
}


//select dealer terms
export const requestDealerTerms = (transactionId) => ({
    type: ActionTypes.REQUEST_DEALERTERMS,
    payload: transactionId
})

export const receiveDealerTerms = (json) => ({
    type: ActionTypes.RECEIVE_DEALERTERMS,
    payload: json
})

export const selectDealerTerms = () => (dispatch, getState) => {
    const transactionId = getState().transaction.transactionId
    dispatch(requestDealerTerms(transactionId))

    return fetch("/data/buy/dealer?transactionId=" + transactionId, {
        credentials: 'include'
    })
        .then(response => response.json())
        .then(json => {
            if (!json.status.success) {
                handleErrors(json.status, true, dispatch)
            }
            if (json.checkoutTransactionRm) {
                if (json.checkoutTransactionRm.isFinalized) {
                    dispatch(receiveDealerTerms(json))
                    window.location.href = '/Checkout/Confirmation?transactionId=' + transactionId + '&isSmiTransactionId=True'
                } else {
                    dispatch(disableLoader())
                    alert('Sorry, an error occurred.  Please refresh the page and try again.')
                    console.error("alert displayed in selectDealerTerms")
                }
            }
        })
        .catch(error => {
            console.error('selectDealerTerms Error:', error)
            dispatch(disableLoader())
            alert('Sorry, an error occurred.  Please refresh the page and try again.')
        })
}
//submit email signup
export const submitEmailSignUp = () => ({
    type: ActionTypes.SUBMIT_EMAIL_SIGN_UP
})

export const submitLoyaltySignUp = () => ({
    type: ActionTypes.SUBMIT_LOYALTY_SIGN_UP
})

//update carb subclass form
export const updateCarbSubclassForm = (json) => ({
    type: ActionTypes.UPDATE_CARB_SUBCLASS_FORM,
    payload: json
})

// receive carb subclass form
export const receiveCarbSubclassForm = (json) => ({
    type: ActionTypes.RECEIVE_CARB_SUBCLASS_FORM,
    payload: json
})

export const receiveEmailSignUpResponse = (json) => ({
    type: ActionTypes.RECEIVE_EMAIL_SIGN_UP,
    payload: json
})

export const receiveLoyaltySignUpResponse = (json) => ({
    type: ActionTypes.RECEIVE_LOYALTY_SIGN_UP,
    payload: json
})

//submit shipping method step
export const requestShippingMethodStep = (json) => ({
    type: ActionTypes.REQUEST_SHIPPINGMETHODSTEP,
    payload: json
})

export const receiveShippingMethodStep = (json) => ({
    type: ActionTypes.RECEIVE_SHIPPINGMETHODSTEP,
    payload: json
})

export const receiveInstoreLocation = (json) => ({
    type: ActionTypes.UPDATE_INSTORE_PICKUP_LOCATION,
    payload: json
})

export const updateInstorePickupLocation = (instoreLocation, shipmentId, shippingMethod) => (dispatch, getState) => {
    const state = getState()
    var shippingMethodContainer = state.transaction.shippingMethodContainer

    let shipment = shippingMethodContainer.shipments.find(x => x.shipmentId == shipmentId)
    if(!shipment){
        console.error("Unable to find shipment when changing instore pickup location")
        return shippingMethodContainer
    }
    
    //let displayedShippingOption = shipment.displayedShippingOptions.find(x => x.shippingMethodId == shippingMethod)
    //let displayedInstoreOptionToSelect = displayedShippingOption.inStorePickupOptions.find(x => x.inStorePickupId == instoreLocation)
    let optionToSelect = shipment.selectedShippingOption.inStorePickupOptions.find(x => x.inStorePickupId == instoreLocation)
    if(!optionToSelect){
        console.error("Unable to find instore pickup options on selected shipment")
        return shippingMethodContainer
    }

    // if(optionToSelect.InStorePickupLocationId == instoreLocation){
    //     return shippingMethodContainer
    // }

    //displayedShippingOption.inStorePickupOptions.map(x => x.isSelected = false)
    shipment.selectedShippingOption.inStorePickupOptions.map(x => x.isSelected = false)
    optionToSelect.isSelected = true
    //displayedInstoreOptionToSelect.isSelected = true

    shipment.shipmentTitle = optionToSelect.shipmentTitle

    dispatch(receiveInstoreLocation(shippingMethodContainer))
}

export const submitShippingMethodStep = (selectedShippingOptions, history) => (dispatch, getState) => {
    const state = getState()
    let transactionId = state.transaction.transactionId

    dispatch(requestShippingMethodStep(transactionId))

    let data = {
        SelectedShippingOptions: selectedShippingOptions,
        CommentDeliveryInstructions: state.transaction.shippingMethodContainer.deliveryInstructions,
        transactionId: transactionId
    }

    let url = "/data/buy/submitshippingmethodstep"
    return fetch(url,
        {
            method: 'POST', // or 'PUT'
            body: JSON.stringify(data), // data can be `string` or {object}!
            headers: {
                'Content-Type': 'application/json'
            },
            credentials: 'include'
        })
        .then(response => response.json())
        .then(json => {
            if (json.checkoutTransactionRm) {
                dispatch(receiveShippingMethodStep(json));
            }
            if (json.status.success) {
                history.push(`/buy/payment?transactionId=` + transactionId);
            } else {
                handleErrors(json.status, true, dispatch);
            }
        })
        .catch(error => {
            console.error('Unable to submit shipping data for payment', data, url, error)
            dispatch(disableLoader())
            alert('Sorry, an error occurred.  Please refresh the page and try again.')
        })
}
export const submitDeliveryInstructions = (deliveryInstructions) => ({
    type: ActionTypes.DELIVERYINSTRUCTIONS,
    payload: deliveryInstructions
})

export const clearPaymentErrors = () =>({
    type: ActionTypes.CLEAR_PAYMENT_ERRORS
})

//promo or gift card fetch
export const requestSubmitPayPalPayment = (transactionId) => ({
    type: ActionTypes.REQUEST_SUBMITPAYPALPAYMENT,
    payload: transactionId
})

export const submitPayPalPayment = () => (dispatch, getState) => {
    const { transactionId } = getState().transaction
    dispatch(requestSubmitPayPalPayment(transactionId));
    let submitPayPalPaymentModel = { TransactionId: transactionId }

    return fetch("/data/buy/getpaypalredirecturl",
        {
            method: 'POST',
            body: JSON.stringify(submitPayPalPaymentModel),
            headers: {
                'Content-Type': 'application/json'
            },
            credentials: 'include'
        })
        .then(response => response.json())
        .then(json => {
            if (json.status.success) {
                window.location.href = json.checkoutTransactionRm.paymentMethodContainer.payPalUrl
            } else {
                handleErrors(json.status, true, dispatch);
            }
        })
        .catch(error => {
            console.error('submitPayPalPayment Error:', error)
            dispatch(disableLoader())
            alert('Sorry, an error occurred.  Please refresh the page and try again.')
        })
}


//remove carb items from the transaction
export const requestRemoveCarbAcknowledgeItems = (items, isPayPalExpress) => ({
    type: ActionTypes.REQUEST_REMOVE_CARB_ACKNOWLEDGE_ITEMS,
    payload: {
        "items": items,
        "isPayPalExpress": isPayPalExpress
    }
})

export const requestRemoveCarbNoSaleItems = (items, isPayPalExpress) => ({
    type: ActionTypes.REQUEST_REMOVE_CARB_NO_SALE_ITEMS,
    payload: {
        "items": items,
        "isPayPalExpress": isPayPalExpress
    }
})

export const requestRemoveDropshipItems = (dropshipItems, isPayPalExpress) => ({
    type: ActionTypes.REQUEST_REMOVE_DROPSHIP_ITEMS,
    payload: {
        "dropshipItems": dropshipItems,
        "isPayPalExpress": isPayPalExpress
    }
})

export const requestRemoveItems = (items, isPayPalExpress) => ({
    type: ActionTypes.REQUEST_REMOVE_ITEMS,
    payload: {
        "items": items,
        isPayPalExpress
    }
})

export const requestRemoveTruckFreightItems = (truckFreightItems, isPayPalExpress) => ({
    type: ActionTypes.REQUEST_REMOVE_TRUCK_FREIGHT_ITEMS,
    payload: {
        "truckFreightItems": truckFreightItems,
        "isPayPalExpress": isPayPalExpress
    }
})

export const receiveAddressShippingConstraintModal = () => ({
    type: ActionTypes.RECEIVE_ADDRESS_SHIPPING_CONSTRAINT_MODAL
})

export const receiveRemoveCarbItems = (json) => ({
    type: ActionTypes.RECEIVE_REMOVE_CARB_ITEMS,
    payload: json
})

export const receiveRemoveDropshipItems = (json) => ({
    type: ActionTypes.RECEIVE_REMOVE_DROPSHIP_ITEMS,
    payload: json
})

export const receiveRemoveTruckFreightItems = (json) => ({
    type: ActionTypes.RECEIVE_REMOVE_TRUCK_FREIGHT_ITEMS,
    payload: json
})

export const receiveRemoveItems = (json) => ({
    type: ActionTypes.RECEIVE_REMOVE_ITEMS,
    payload: json
})

//express paypal fetch
export const requestPaypal = () => ({
    type: ActionTypes.REQUEST_PAYPAL_EXPRESS
})
export const requestMobilePay = () => ({
    type: ActionTypes.REQUEST_MOBILE_PAY
})

export const receivePaypal = (json) => ({
    type: ActionTypes.RECEIVE_PAYPAL_EXPRESS,
    payload: json
})
export const receiveMobilePay = (json) => ({
    type: ActionTypes.RECEIVE_MOBILE_PAY,
    payload: json
})

export const requestSubmitBraintreePaypalExpress = (payload) => ({
    type: ActionTypes.REQUEST_SUBMIT_BRAINTREE_PAYPALEXPRESS,
    payload
})

export const requestSubmitMobilePayExpress = (payload) => ({
    type: ActionTypes.REQUEST_SUBMIT_BRAINTREE_MOBILEPAY,
    payload
})

export const selectCustomBillingAddressBraintree = (payload) => ({
    type: ActionTypes.SELECT_CUSTOM_BILLING_ADDRESS_BRAINTREE,
    payload
})

export const updatePaypalAddressForm = (updatedFormField) => ({
    type: ActionTypes.UPDATE_PAYPAL_ADDRESS_FORM,
    payload: updatedFormField
})

export const updateMobilePayAddressForm = (updatedFormField) => ({
    type: ActionTypes.UPDATE_MOBILEPAY_ADDRESS_FORM,
    payload: updatedFormField
})

export const fetchPaypal = (transactionId, paymentId, history) => dispatch => {
    dispatch(requestPaypal())

    return fetch("/data/buy/paypal?transactionId=" + transactionId + "&paymentId=" + paymentId,
        {
            credentials: 'include'
        })
        .then(response => response.json())
        .then(json => {
            if (!json.status.success) {
                handleErrors(json.status, true, dispatch)
            }
            if (json.checkoutTransactionRm) {
                //29 minutes client side timeout
                setTimeout(function () { window.location.href = "/shoppingcart?timeout=1"; }, 1740000);
                let shippingAddressId = json.checkoutTransactionRm.paymentMethodContainer.shipToAddressId;
                let shippingAddress = json.checkoutTransactionRm.addresses[shippingAddressId];
                json.checkoutTransactionRm["paypalAddressForm"] = shippingAddress;
                if (json.checkoutTransactionRm.isEmailInvalid) {
                    dispatch(displayErrors({ ADDRESS_FORM_EMAIL: ["Please enter a valid email"] }))
                }

                dispatch(receivePaypal(json));
            }
        })
        .catch(error => {
            console.error('fetchPayPalExpress Error:', error)
            dispatch(disableLoader())
            alert('Sorry, an error occurred.  Please refresh the page and try again.')
            window.location.href = "/buy"
        })
}

export const fetchMobilePay = (transactionId, history) => dispatch => {
    dispatch(requestMobilePay())

    return fetch("/data/buy/mobilepay?transactionId=" + transactionId,
            {
                credentials: 'include'
            })
        .then(response => response.json())
        .then(json => {
            if (!json.status.success) {
                handleErrors(json.status, true, dispatch)
            }
            if (json.checkoutTransactionRm) {
                //29 minutes client side timeout
                setTimeout(function () { window.location.href = "/shoppingcart?timeout=1"; }, 1740000);
                let shippingAddressId = json.checkoutTransactionRm.paymentMethodContainer.shipToAddressId;
                let shippingAddress = json.checkoutTransactionRm.addresses[shippingAddressId];
                json.checkoutTransactionRm["mobilePayAddressForm"] = shippingAddress;

                dispatch(receiveMobilePay(json));
            }
        })
        .catch(error => {
            console.error('fetchMobilePay Error:', error)
            dispatch(disableLoader())
            alert('Sorry, an error occurred.  Please refresh the page and try again.')
            window.location.href = "/buy"
        })
}

export const requestAcknowledgeCarbTerms = (acknowledgedCarbTerms, carb) => ({
    type: ActionTypes.REQUEST_ACKNOWLEDGE_CARB_TERMS,
    payload: carb
})

export const receiveAcknowledgeCarbTerms = (json) => ({
    type: ActionTypes.RECEIVE_ACKNOWLEDGE_CARB_TERMS,
    payload: json
})

//confirmation fetch
export const receiveConfirmationPage = (json) => ({
    type: ActionTypes.RECEIVE_CONFIRMATION_PAGE,
    payload: json
})

export const fetchConfirmation = (transactionId) => ({
    type: ActionTypes.REQUEST_CONFIRMATION_PAGE,
    payload: transactionId
})

export const hydratedConfirmationPage = (json) => ({
    type: ActionTypes.HYDRATED_CONFIRMATION_STEP,
    payload: json
})

export const storeHydratedFlag = () => ({
    type: ActionTypes.STORE_HYDRATED_FLAG
})

//todo: this will need to be modified to handle gift cards, other text box error. for now we will alert with these messages
export function handleErrors(status, messageAlert, dispatch) {
    if (status.redirectUrl) {
        window.location.href = status.redirectUrl;
    } else if (status.errorMessage) {
        dispatch(disableLoader());
        alert(status.errorMessage);
        console.error("error message displayed", status.errorMessage)
    } else {
        dispatch(disableLoader());
        alert('Sorry, an error occurred.  Please refresh the page and try again.');
        console.error("error message displayed in handleErrors")
    }
}

export const fetchPayPalExpress = (payload) => ({
    type: ActionTypes.FETCH_PAYPAL_EXPRESS,
    payload: payload

})

export const fetchPayPalExpressAuthorize = (payload) => ({
    type: ActionTypes.FETCH_PAYPAL_EXPRESS_AUTHORIZE,
    payload: payload

})

export const fetchPayPalExpressError = (history) => (dispatch, getState) => {
    window.location.href = "/buy?expresspaypalerror=1";
}
