import * as ActionTypes from 'Types/CheckoutActionTypes';
import * as CartActionTypes from 'Types/ShoppingCartActionTypes'
import Cookies from 'js-cookie'
import {addSessionProperties, incrementSessionStorageKeyEventCount} from "Middleware/Utilities/SessionStorageUtility";

//all the segment scripts are downloaded in ds9
const SegmentMiddleware = ({action, dispatch, getState}) => next => action => {

    try {
        const getSuperMarketAffinity = (item) => {
            item.superMarketAffinity = item.superMarketAffinity ?? [];
            if (item.superMarketAffinity.length === 0) {
                return "none";
            }
            if (item.superMarketAffinity.length === 1) {
                return item.superMarketAffinity[0].toLowerCase()
            }
            return "multiple"
        }

        window.waitForClutch(() => {
            let {transaction, orderDetails} = getState()
            let userProfile = BuildUserProfile(transaction, action.payload)

            if (!navigator.globalPrivacyControl && !document.cookie.includes('EnableGpc=')) {
                let product
                switch (action.type) {
                    case CartActionTypes.REQUEST_MOVE_BUILD_LIST_ITEM_TO_CART:
                        product = action.payload.productDetails
                        if (!product.skuVariant) {
                            console.error("Sending segment ATC event with empty sku number - move buildlist item to cart")
                        }
                        window.analytics.track('Product Added', {
                            cart_id: Cookies.get('WebUserGuid'),
                            product_id: product.skuBase,
                            sku: product.skuBase,
                            name: product.title,
                            image_url: product.imageUrl,
                            url: product.productPageUrl,
                            category: `${product.category} > ${product.subcategory} > ${product.partType}`,
                            price: convertPricetoFloat(product.price),
                            quantity: 1,
                            brand: product.brand,
                            variant: product.skuVariant,
                            reviews: product.reviewCount,
                            rating: product.rating,
                            availability: product.availability,
                            audience_affinity: getSuperMarketAffinity(product),
                        });
                        break
                    case CartActionTypes.REQUEST_MOVE_CART_ITEM_TO_BUILD_LIST:
                        product = action.payload.productDetails
                        analytics.track('Product Removed', {
                            cart_id: Cookies.get('WebUserGuid'),
                            product_id: product.skuBase,
                            sku: product.skuBase,
                            name: product.title,
                            image_url: product.imageUrl,
                            url: product.productPageUrl,
                            category: `${product.category} > ${product.subcategory} > ${product.partType}`,
                            price: convertPricetoFloat(product.price),
                            quantity: 1,
                            brand: product.brand,
                            variant: product.skuVariant,
                            reviews: product.reviewCount,
                            rating: product.rating,
                            availability: product.availability,
                            audience_affinity: getSuperMarketAffinity(product),
                        });
                        break
                    case CartActionTypes.REQUEST_UPDATE_SHOPPINGCART_PRODUCTS:
                        let addCartItems = action.payload.skusToAdd
                        let removeCartItems = action.payload.skusToRemove

                        if (addCartItems) {
                            for (var i = 0; i < addCartItems.length; i++) {
                                product = addCartItems[i]
                                if (!product.skuNumber) {
                                    console.error("Sending segment ATC event with empty sku number - update shopping cart products")
                                }
                                analytics.track('Product Added', {
                                    cart_id: Cookies.get('WebUserGuid'),
                                    product_id: product.skuBase,
                                    sku: product.skuBase,
                                    name: product.title,
                                    image_url: product.imageUrl,
                                    url: product.productPageUrl,
                                    category: buildSegmentCategory(product.category, product.subcategory, product.partType),
                                    price: convertPricetoFloat(product.price),
                                    brand: product.brand,
                                    variant: product.skuVariant,
                                    quantity: product.quantity ? parseInt(product.quantity) : null,
                                    reviews: product.reviewCount,
                                    rating: product.rating,
                                    availability: product.availability,
                                    audience_affinity: getSuperMarketAffinity(product),
                                });
                            }
                        }

                        if (removeCartItems) {
                            for (var i = 0; i < removeCartItems.length; i++) {
                                product = removeCartItems[i]
                                analytics.track('Product Removed', {
                                    cart_id: Cookies.get('WebUserGuid'),
                                    product_id: product.skuBase,
                                    sku: product.skuBase,
                                    name: product.title,
                                    image_url: product.imageUrl,
                                    url: product.productPageUrl,
                                    category: `${product.category} > ${product.subcategory} > ${product.partType}`,
                                    price: convertPricetoFloat(product.price),
                                    brand: product.brand,
                                    variant: product.skuVariant,
                                    quantity: product.quantity,
                                    reviews: product.reviewCount,
                                    rating: product.rating,
                                    availability: product.availability,
                                    audience_affinity: getSuperMarketAffinity(product),
                                });
                            }
                        }

                        break
                    case ActionTypes.RECEIVE_ENTRY:
                        let transactionIdFromTransaction = action?.payload?.checkoutTransactionRm?.transactionId
                        let transactionIdFromEntry = window.localStorage.getItem('TransactionId')
                        let transactionId = transactionIdFromTransaction && transactionIdFromTransaction !== "" ? transactionIdFromTransaction : transactionIdFromEntry

                        analytics.track('Checkout Started',
                            {
                                order_id: transactionId,
                                value: convertPricetoFloat(action?.payload?.checkoutTransactionRm?.orderDetails?.currentAmountDue),
                                discount: convertPricetoFloat(action?.payload?.checkoutTransactionRm?.orderDetails?.totalDiscount),
                                products: action?.payload?.checkoutTransactionRm?.shippingMethodContainer?.shipments?.map(
                                    shipment => {
                                        return shipment?.products.map(product => {
                                            const {
                                                listId,
                                                listName
                                            } = splitAddToCartCreditedSource(product.addToCartCreditedSource)
                                            const isYmm = product.fromYmmSource ?? "not_set"
                                            const isPlatform = product.fromPlatformSource ?? "not_set"
                                            const isRaceType = product.fromRaceTypeSource ?? "not_set"
                                            const isEngine = product.fromEngineSource ?? "not_set"
                                            const isTransmission = product.fromTransmissionSource ?? "not_set"
                                            const isRearAxle = product.fromRearAxleSource ?? "not_set"
                                            let attribution = {}

                                            if (product.attribution) {
                                                try {
                                                    attribution = JSON.parse(product.attribution)
                                                } catch (e) {
                                                    console.error(e)
                                                }
                                            }

                                            return {
                                                product_id: product.skuBase,
                                                sku: product.skuBase,
                                                name: product.title,
                                                image_url: product.imageUrl,
                                                category: product.segmentCategorization,
                                                price: convertPricetoFloat(product.price),
                                                quantity: product.quantity,
                                                brand: product.brand,
                                                variant: product.skuVariant,
                                                list_id: listId,
                                                list_name: listName,
                                                is_ymm: isYmm,
                                                is_racetype: isRaceType,
                                                is_engine: isEngine,
                                                is_transmission: isTransmission,
                                                is_rear_axle: isRearAxle,
                                                is_platform: isPlatform,
                                                ...attribution
                                            }
                                        })
                                    }).reduce(function (a, b) {
                                    return a.concat(b);
                                }, [])
                            });
                        IdentifyCall(userProfile)
                        break
                    case ActionTypes.LOADED_CUSTOMER_INFO_STEP:
                        analytics.track('Checkout Step Viewed', {
                            checkout_id: action?.payload?.checkoutTransactionRm?.transactionId,
                            step: 1,
                            step_name: 'Customer Info'
                        });
                        analytics.page()
                        IdentifyCall(userProfile)
                        break
                    case ActionTypes.RECEIVE_SUBMITCUSTOMERINFO:
                        analytics.track('Checkout Step Completed', {
                            checkout_id: action?.payload?.checkoutTransactionRm?.transactionId,
                            step: 1,
                            step_name: 'Customer Info',
                            shipping_method: '',
                            payment_method: ''
                        })
                        break
                    case ActionTypes.LOADED_SHIPPING_STEP:
                        analytics.track('Checkout Step Viewed', {
                            checkout_id: action?.transactionId,
                            step: 2,
                            step_name: 'Shipping'
                        });
                        analytics.page()
                        IdentifyCall(userProfile)
                        break
                    case ActionTypes.RECEIVE_SHIPPINGMETHODSTEP:
                        analytics.track('Checkout Step Completed', {
                            checkout_id: action?.payload?.checkoutTransactionRm?.transactionId,
                            step: 2,
                            step_name: 'Shipping',
                            shipping_method: buildShippingMethods(action?.payload?.checkoutTransactionRm?.shippingMethodContainer?.shipments),
                            payment_method: ''
                        })
                        break
                    case ActionTypes.LOADED_CARB_MODAL:
                        analytics.track('Checkout CARB Modal Viewed', {
                            checkout_id: action?.transactionId,
                            pp66Items: action?.carb.pP66CarbItems,
                            raceItems: action?.carb.raceCarbItems,
                            RorPCarbItems: action?.carb.roRPCarbItems,
                            noSaleItems: action?.carb.noSaleCarbItems,
                            itemsRequiringAcceptanceOfTerms: action?.carb.carbItemsRequiringAcceptanceOfTerms,
                            OEMCarbItems: action?.carb.oEMCarbItems,
                            MOTRCarbItems: action?.carb.mOTRCarbItems,
                            EOCarbItems: action?.carb.eOCarbItems
                        });
                        break
                    case ActionTypes.CARB_ITEMS_REMOVED:
                        analytics.track('CARB Item(s) Removed', {
                            checkout_id: action?.transactionId,
                            items_removed: action?.items,
                        });
                        break
                    case ActionTypes.LOADED_PAYMENT_STEP:
                    case ActionTypes.RECEIVE_PAYMENT:
                    case ActionTypes.RECEIVE_PAYPAL_EXPRESS:
                    case ActionTypes.RECEIVE_MOBILE_PAY:
                        const paymentStep = getPaymentStep(action.type)
                        if (action?.transactionId) {
                            analytics.track('Checkout Step Viewed', {
                                checkout_id: action?.transactionId,
                                step: paymentStep.step,
                                step_name: paymentStep.stepName,
                                shipping_method: buildShippingMethods(transaction?.shippingMethodContainer?.shipments),
                            });
                        } else if (action?.payload?.checkoutTransactionRm) {
                            //this is the paypal section. This is techincally both steps 1, 2, and 3....
                            analytics.track('Checkout Started', {
                                order_id: action?.payload?.checkoutTransactionRm?.transactionId,
                                value: convertPricetoFloat(action?.payload?.checkoutTransactionRm?.orderDetails?.currentAmountDue),
                                discount: convertPricetoFloat(action?.payload?.checkoutTransactionRm?.orderDetails?.totalDiscount),
                                products: action?.payload?.checkoutTransactionRm?.shippingMethodContainer?.shipments?.map(
                                    shipment => {
                                        return shipment?.products.map(product => {
                                            let attribution = {}

                                            if (product.attribution) {
                                                try {
                                                    attribution = JSON.parse(product.attribution)
                                                } catch (e) {
                                                    console.error(e)
                                                }
                                            }

                                            return {
                                                product_id: product.skuBase,
                                                sku: product.skuBase,
                                                name: product.title,
                                                image_url: product.imageUrl,
                                                category: product.segmentCategorization,
                                                price: convertPricetoFloat(product.price),
                                                quantity: product.quantity,
                                                brand: product.brand,
                                                variant: product.skuVariant,
                                                addToCartCreditedSource: product.addToCartCreditedSource,
                                                is_ymm: product.fromYmmSource ?? "not_set",
                                                is_racetype: product.fromRaceTypeSource ?? "not_set",
                                                is_engine: product.fromEngineSource ?? "not_set",
                                                is_transmission: product.fromTransmissionSource ?? "not_set",
                                                is_rear_axle: product.fromRearAxleSource ?? "not_set",
                                                is_platform: product.fromPlatformSource ?? "not_set",
                                                ...attribution
                                            }
                                        })
                                    }).reduce(function (a, b) {
                                    return a.concat(b);
                                }, [])
                            });

                            analytics.track('Checkout Step Viewed', {
                                checkout_id: action?.payload?.checkoutTransactionRm?.transactionId,
                                step: paymentStep.step,
                                step_name: paymentStep.stepName,
                                shipping_method: buildShippingMethods(action?.payload?.checkoutTransactionRm?.shippingMethodContainer?.shipments)
                            });

                            try {
                                analytics.track('Payment Info Entered', {
                                    checkout_id: action?.payload?.checkoutTransactionRm?.transactionId,
                                    step: paymentStep.step,
                                    step_name: paymentStep.stepName,
                                        payment_method: action.type === ActionTypes.RECEIVE_PAYPAL_EXPRESS ? 'PayPal' : 'ApplePay/GooglePay',
                                    products: action?.payload?.checkoutTransactionRm?.shippingMethodContainer?.shipments?.map(
                                        shipment => {
                                            return shipment?.products.map(product => {
                                                return {
                                                    product_id: product.skuBase,
                                                    sku: product.skuBase,
                                                    name: product.title,
                                                    image_url: product.imageUrl,
                                                    category: product.segmentCategorization,
                                                    price: convertPricetoFloat(product.price),
                                                    quantity: product.quantity,
                                                    brand: product.brand,
                                                    variant: product.skuVariant,
                                                    reviews: product.reviewCount,
                                                    rating: product.rating,
                                                    availability: product.availabilityEnum,
                                                    audience_affinity: getSuperMarketAffinity(product),
                                                    url: product.productPageUrl
                                                }
                                            })
                                        }).reduce(function (a, b) {
                                        return a.concat(b);
                                    }, [])
                                });
                            } catch (e) {
                                console.error('unable to send segment event')
                            }
                        }
                        analytics.page()
                        if (action.type === ActionTypes.RECEIVE_PAYPAL_EXPRESS || action.type === ActionTypes.RECEIVE_MOBILE_PAY) {
                            IdentifyCall(userProfile)
                        } else {
                            IdentifyCall(userProfile)
                        }
                        break
                    case ActionTypes.RECEIVE_PROMO_OR_GIFTCARD:
                        if (action?.payload?.checkoutTransactionRm) {
                            let payloadTransaction = action?.payload?.checkoutTransactionRm
                            let currentPromos = payloadTransaction?.orderDetails?.currentPromos
                            if (currentPromos && currentPromos.length > 0) {
                                analytics.track('Coupon Entered', {
                                    checkout_id: payloadTransaction?.transactionId,
                                    coupon_id: currentPromos[0].promoCode,
                                    discount_amount: convertPricetoFloat(currentPromos[0].discount_amount)
                                });
                            }

                        }
                        break
                    case ActionTypes.REQUEST_SUBMIT_PAYMENT_INFORMATION:
                        const paymentMethod = transaction.paymentMethodContainer.paymentMethod
                        analytics.track('Checkout Step Completed', {
                            checkout_id: transaction?.transactionId,
                            step: 3,
                            step_name: 'Select Payment',
                            shipping_method: buildShippingMethods(transaction?.shippingMethodContainer?.shipments),
                            payment_method: getPaymentMethodByBraintreeType(action?.payload?.paymentType),
                        })
                        break
                    case ActionTypes.SEND_PAYPAL_STEP_COMPLETED_EVENT:
                        analytics.track('Checkout Step Completed', {
                            checkout_id: transaction?.transactionId,
                            step: 4,
                            step_name: 'PayPal Express',
                            shipping_method: buildShippingMethods(transaction?.shippingMethodContainer?.shipments),
                            payment_method: 'PayPal',
                        })
                    case ActionTypes.SEND_MOBILE_PAY_STEP_COMPLETED_EVENT:
                        analytics.track('Checkout Step Completed', {
                            checkout_id: transaction?.transactionId,
                            step: 5,
                            step_name: 'ApplePay/Googlepay',
                            shipping_method: buildShippingMethods(transaction?.shippingMethodContainer?.shipments),
                            payment_method: action?.payload?.paymentMethod,
                        })
                    case ActionTypes.HYDRATED_CONFIRMATION_STEP:
                        let segmentData = BuildSegmentTransaction(transaction, orderDetails)

                        // Find email address and phone number most associated with order
                        let email = undefined
                        let phone = undefined

                        let billingAddressId = transaction.addressLookup.billingAddressId
                        if (billingAddressId in transaction.addresses) {
                            email = setEmail(email, transaction.addresses[billingAddressId].email)
                            phone = setPhone(phone, transaction.addresses[billingAddressId].phoneNumber)
                        }

                        email = setEmail(email, userProfile.email)

                        let shippingAddressId = transaction.addressLookup.shippingAddressId
                        
                        if (shippingAddressId in transaction.addresses) {
                            email = setEmail(email, transaction.addresses[shippingAddressId].email)
                            phone = setPhone(phone, transaction.addresses[shippingAddressId].phoneNumber)
                        }
                        for (const addressId in transaction.addresses) {
                            email = setEmail(email, transaction.addresses[addressId].email)
                            phone = setPhone(phone, transaction.addresses[addressId].phone)
                        }

                        console.info('Attempting to send Order Completed', {
                            analytics: analytics,
                            analyticsTrack: analytics?.track,
                            analyticsIdentify: analytics?.identify,
                            segmentData: segmentData,
                            email: email,
                            phone: phone,
                            userProfile: userProfile
                        })

                        analytics.track(
                            'Order Completed',
                            segmentData,
                            {
                                traits: {
                                    email: email,
                                    phone: phone,
                                }
                            })

                        IdentifyCall(userProfile)

                        try {
                            incrementSessionStorageKeyEventCount('Order Completed');
                        } catch (e) {
                            console.error(e)
                        }

                        break
                    default:
                        break
                }
            }
        })
    } catch (e) {
        console.error(e)
    }
    next(action)
}

let identify = {}
export default SegmentMiddleware;


function setEmail(email, newEmail) {
    if (email == null || email === "") {
        email = newEmail
    }
    return email
}

function setPhone(phone, newPhone) {
    if (phone == null || phone === "") {
        phone = newPhone
        for (let i = 0; i < phone.length; ++i) {
            if (phone[i] === '-') phone = phone.replace('-', '');
        }
    }
    return phone
}

function buildSegmentCategory(category, subcategory, partType) {
    return category || subcategory || partType ? `${category} > ${subcategory} > ${partType}` : ''
}

function buildShippingMethods(shipments) {
    if (!shipments?.length) {
        return ''
    }
    let shippingMethods = shipments.map(x => x.selectedShippingOption.methodName)
    return shippingMethods.toString()
}

function getPaymentStep(actionType) {
    if (actionType === ActionTypes.RECEIVE_PAYMENT || actionType === ActionTypes.LOADED_PAYMENT_STEP) {
        return {step: 3, stepName: 'Select Payment'}
    } else if (actionType === ActionTypes.RECEIVE_PAYPAL_EXPRESS) {
        return {step: 4, stepName: 'PayPal Express'}
    } else if (actionType === ActionTypes.RECEIVE_MOBILE_PAY) {
        return {step: 5, stepName: 'ApplePay/Googlepay'}
    } else {
        return {step: 999, stepName: 'Unable To Get Step'}
    }
}

function getPaymentMethodByBraintreeType(type) {
    if (type === 'CreditCard') {
        return 'CreditCard'
    } else if (type === 'PayPalAccount') {
        return 'PayPal'
    } else if (type === 'GooglePayCard' || type === 'AndroidPayCard') {
        return 'GooglePay'
    } else if (type === 'ApplePayCard') {
        return 'ApplePay'
    } else {
        return 'Unable to get Payment Method'
    }
}

function splitAddToCartCreditedSource(addToCartCreditedSource) {
    const not_set = "not_set"
    if (addToCartCreditedSource) {
        const [listId, listName] = addToCartCreditedSource.split(":")
        return {listId: listId ?? not_set, listName: listName ?? not_set}
    } else {
        return {listId: not_set, listName: not_set}
    }
}

function IdentifyCall(userProfile) {
    if (userProfile != null) {
        var userId = userProfile.sopCustomerNumber
        var identifyPayload = {}

        if (!userId && userProfile.email) {
            identifyPayload.email = userProfile.email
            var options = {
                context: {
                    traits: {
                        email: userProfile.email
                    }
                }
            }
        }

        analytics.identify(userId, identifyPayload, options);
    }
}

let BuildSegmentTransaction = function (transaction, orderDetails) {

    const getSuperMarketAffinity = (item) => {
        item.superMarketAffinity = item.superMarketAffinity ?? [];
        if (item.superMarketAffinity.length === 0) {
            return "none";
        }
        if (item.superMarketAffinity.length === 1) {
            return item.superMarketAffinity[0].toLowerCase()
        }
        return "multiple"
    }

    let segmentTransactionData = {}

    if (transaction) {
        if (transaction.confirmation) {
            segmentTransactionData.order_id = transaction.confirmation?.isSplitOrder
                ? transaction.confirmation?.masterOrderNumber
                : transaction.confirmation?.orderNumbers[0];
            segmentTransactionData.checkout_id = transaction.transactionId;
            segmentTransactionData.subtotal = convertPricetoFloat(transaction.confirmation?.orderSubTotal)
            segmentTransactionData.revenue = convertPricetoFloat(transaction.confirmation?.orderSubTotal)
            segmentTransactionData.total = convertPricetoFloat(transaction.confirmation?.orderAndBackorderTotal)
            segmentTransactionData.shipping = convertPricetoFloat(transaction.confirmation?.shippingTotal)
            segmentTransactionData.tax = convertPricetoFloat(transaction.confirmation?.taxTotal)

        } else {
            console.log("transaction.Confirmation is Missing")
        }
        if (transaction?.shippingProtectionOptionsContainer?.instrumentation) {
            segmentTransactionData.shipping_protection_eligible = transaction.shippingProtectionOptionsContainer.instrumentation.isTransactionEligible
            segmentTransactionData.shipping_protection_total = transaction.shippingProtectionOptionsContainer.instrumentation.chosenAmount
            segmentTransactionData.shipping_protection_treatment = transaction.shippingProtectionOptionsContainer.instrumentation.treatmentName
        }
        let position = 0
        segmentTransactionData.promoCode = transaction.promoCode
        segmentTransactionData.products = transaction.shippingMethodContainer?.shipments.map(shipment => {
            return shipment?.products.map(product => {
                let productData = {}
                let availability = product.availability ? product.availability.split(' ').join('') : ''
                if (availability === 'OnBackorder') {
                    availability = 'Backorderable'
                }
                if (availability === 'MadetoOrder') {
                    availability = 'MadeToOrder'
                }
                if (availability === 'OutofStock') {
                    availability = 'NotAvailable'
                }

                position = position + 1

                productData.product_id = product.skuBase
                productData.sku = product.skuBase
                productData.name = product.title
                productData.image_url = product.imageUrl
                productData.availability = availability
                productData.audience_affinity = getSuperMarketAffinity(product)
                productData.category = buildSegmentCategory(product.category, product.subcategory, product.partType)
                productData.price = convertPricetoFloat(product.price)
                productData.preDiscountUnitPriceDecimal = product.preDiscountUnitPriceDecimal
                productData.postDiscountUnitPriceDecimal = product.postDiscountUnitPriceDecimal
                productData.quantity = product.quantity
                productData.brand = product.brand
                productData.variant = product.skuVariant
                productData.reviews = product.reviewCount
                productData.rating = product.rating
                productData.position = position
                const {listId, listName} = splitAddToCartCreditedSource(product.addToCartCreditedSource)
                productData.list_id = listId
                productData.list_name = listName
                productData.is_ymm = product.fromYmmSource
                productData.is_platform = product.fromPlatformSource
                productData.is_racetype = product.fromRaceTypeSource
                productData.is_engine = product.fromEngineSource
                productData.is_transmission = product.fromTransmissionSource
                productData.is_rear_axle = product.fromRearAxleSource

                let attribution = {}

                if (product.attribution) {
                    try {
                        attribution = JSON.parse(product.attribution)
                    } catch (e) {
                        console.error(e)
                    }
                }

                productData = {
                    ...productData,
                    ...attribution
                }

                return productData
            })
        }).flat()
        if (orderDetails) {
            segmentTransactionData.discount = convertPricetoFloat(orderDetails?.totalDiscount)
        } else {
            console.log("OrderDetails is Missing")
        }
        // we're struggling a bit on getting the correct identifier. Let's cover our bases.
        const iterableEmailCampaignId = Cookies.get('iterableEmailCampaignId')
        if (iterableEmailCampaignId) {
            segmentTransactionData.iterableEmailCampaignId = parseInt(iterableEmailCampaignId)
            segmentTransactionData.campaignId = parseInt(iterableEmailCampaignId)
        }
        const iterableTemplateId = Cookies.get('iterableTemplateId')
        if (iterableTemplateId) {
            segmentTransactionData.iterableTemplateId = parseInt(iterableTemplateId)
            segmentTransactionData.templateId = parseInt(iterableTemplateId)
        }

        const userVin = Cookies.get('VIN')
        if (userVin) {
            segmentTransactionData.user_vin = userVin;
        }

        const webUserGuid = Cookies.get('WebUserGuid')
        if (webUserGuid) {
            segmentTransactionData.web_user_guid = webUserGuid;
        }

        try {
            segmentTransactionData = addSessionProperties(segmentTransactionData);
        } catch (e) {
            console.error(e)
        }
    }

    return segmentTransactionData
}

let convertPricetoFloat = function (price) {
    if (price == null) {
        return 0
    }
    if (typeof price == 'number') {
        return price
    }
    if (price.includes('$') || price.includes(',')) {
        return parseFloat(price.replace('$', '').replace(',', ''))
    }
    return parseFloat(price)
}

let BuildUserProfile = function (transaction, payload) {
    return {
        firstName: transaction?.userProfile?.firstName || payload?.userProfile?.firstName,
        lastName: transaction?.userProfile?.lastName || payload?.userProfile?.lastName,
        email: transaction?.userProfile?.email || payload?.userProfile?.email,
        sopCustomerNumber: transaction?.userProfile?.sopCustomerNumber || payload?.userProfile?.sopCustomerNumber
    }
}