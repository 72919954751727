import React, { lazy, Suspense } from 'react'
import { Route, Switch } from 'react-router-dom'
import { Provider } from 'react-redux'

import SplitRumAgent from 'Containers/Shared/SplitRumAgent/SplitRumAgent'
import './CssToOverrideOldStylesToDeleteWhenCompletelyOnNewApp.css'
import UnregisterServiceWorker from './UnregisterServiceWorker'


const ShoppingCartContainer = lazy(() => import('Routes/ShoppingCart/ShoppingCart'))
const EntryContainer = lazy(() => { return import('Routes/Checkout/Entry/Entry') })
const CustomerInfoContainer = lazy(() => { return import('Routes/Checkout/CustomerInfo/CustomerInfo') })
const ShippingContainer = lazy(() => { return import('Routes/Checkout/Shipping/Shipping') })
const PaypalContainer = lazy(() => { return import('Routes/Checkout/Paypal/Paypal') })
const MobilePayContainer = lazy(() => { return import('Routes/Checkout/MobilePay/MobilePay') })
const PaymentContainer = lazy(() => { return import('Routes/Checkout/Payment/Payment') })
const ConfirmationContainer = lazy(() => { return import('Routes/Checkout/Confirmation/Confirmation') })
const PageNotFoundContainer = lazy(() => { return import('Routes/Shared/PageNotFound/PageNotFound') })


const ClientApp = ({ store, ...props }) => {
    return (
        <Provider store={store}>
            <SplitRumAgent />
            <UnregisterServiceWorker />
            <Suspense fallback={<div></div>}>
                <Switch>
                    <Route path="/shoppingcart" render={() => <ShoppingCartContainer />} />
                    <Route path="/buy" exact render={() => <EntryContainer />} />
                    <Route path="/buy/customerinfo" render={() => <CustomerInfoContainer />} />
                    <Route path="/buy/shipping" render={() => <ShippingContainer />} />
                    <Route path="/buy/paypal" render={() => <PaypalContainer />} />
                    <Route path="/buy/mobilepay" render={() => <MobilePayContainer />} />
                    <Route path="/buy/payment" render={() => <PaymentContainer />} />
                    <Route path="/checkout/confirmation" render={() => <ConfirmationContainer />} />
                    <Route render={() => <PageNotFoundContainer />} />
                </Switch>
            </Suspense>
        </Provider>
    )
}

export default ClientApp
