import * as ActionTypes from 'Types/CheckoutActionTypes'
import * as ShoppingCartActionTypes from 'Types/ShoppingCartActionTypes'
import { REQUEST_SUBMIT_ACCOUNT_SIGN_UP, RECEIVE_SUBMIT_ACCOUNT_SIGN_UP} from "Containers/Shared/AccountSignUp/AccountSignUp"

const initialState = {

    isLoading: true,

};

const loaderReducer = (state, action) => {
    state = state || initialState

    switch (action.type) {
        case ActionTypes.DISABLE_LOADER:
        case ActionTypes.RECEIVE_SUBMITCUSTOMERINFO:
        case ActionTypes.RECEIVE_UPDATESHIPPINGADDRESS:
        case ActionTypes.RECEIVE_SHIPPINGMETHODSTEP:
        case ActionTypes.RECEIVE_VALIDATED_SMARTY_STREET_ADDRESS:
        case ActionTypes.UPDATE_ADDITIONAL_ADDRESS_FORM_DATA:
        case ActionTypes.RECEIVE_ENTRY:
        case ActionTypes.RECEIVE_SHIPPINGOPTION:
        case ActionTypes.RECEIVE_SHIPPINGPROTECTION:
        case ActionTypes.RECEIVE_PAYMENTTECH_URL:
        case ActionTypes.RECEIVE_PROMO_OR_GIFTCARD:
        case ActionTypes.RECEIVE_LOYALTY_CODE:
        case ActionTypes.RECEIVE_REMOVEPROMO:
        case ActionTypes.RECEIVE_REMOVEGIFTCARD:
        case ActionTypes.RECEIVE_REMOVELOYALTYCODE:
        case ActionTypes.RECEIVE_SHIPPING:
        case ActionTypes.RECEIVE_REMOVE_CARB_ITEMS:
        case ActionTypes.RECEIVE_ACKNOWLEDGE_CARB_TERMS:
        case ActionTypes.RECEIVE_CARB_SUBCLASS_FORM:
        case ActionTypes.RECEIVE_REMOVE_DROPSHIP_ITEMS:
        case ActionTypes.RECEIVE_REMOVE_TRUCK_FREIGHT_ITEMS:
        case ActionTypes.RECEIVE_REMOVE_ITEMS:
        case ActionTypes.RECEIVE_ADDRESS_SHIPPING_CONSTRAINT_MODAL:
        case ActionTypes.RECEIVE_CONFIRMATION_PAGE:
        case ActionTypes.RECEIVE_PAYPAL_EXPRESS:
        case ActionTypes.RECEIVE_MOBILE_PAY:
        case ActionTypes.RECEIVE_UPDATEADDRESSBOOK:
        case ActionTypes.RECEIVE_EMAIL_SIGN_UP:
        case ActionTypes.RECEIVE_LOYALTY_SIGN_UP:
        case ActionTypes.RECEIVE_CUSTOMERINFO:
        case ShoppingCartActionTypes.RECEIVE_SHOPPINGCARTPAGE:
        case ShoppingCartActionTypes.RECEIVE_UPDATE_SHOPPINGCART_PRODUCTS:
        case ShoppingCartActionTypes.RECEIVE_MOVE_CART_ITEM_TO_BUILD_LIST:
        case ShoppingCartActionTypes.RECEIVE_MOVE_BUILD_LIST_ITEM_TO_CART:
        case ShoppingCartActionTypes.RECEIVE_REMOVE_BUILD_LIST_ITEM:
        case RECEIVE_SUBMIT_ACCOUNT_SIGN_UP:
        case ActionTypes.RECEIVE_PAYMENT:
            return {
                isLoading: false
            }
        case ActionTypes.REQUEST_ENTRY:
        case ActionTypes.REQUEST_CUSTOMERINFO:
        case ActionTypes.REQUEST_SUBMITCUSTOMERINFO:
        case ActionTypes.REQUEST_SHIPPING:
        case ActionTypes.REQUEST_SHIPPINGOPTION:
        case ActionTypes.REQUEST_SHIPPINGPROTECTION:
        case ActionTypes.REQUEST_UPDATESHIPPINGADDRESS:
        case ActionTypes.REQUEST_PAYMENT:
        case ActionTypes.REQUEST_PAYMENTTECH_URL:
        case ActionTypes.REQUEST_PROMO_OR_GIFTCARD:
        case ActionTypes.REQUEST_LOYALTY_CODE:
        case ActionTypes.REQUEST_DEALERTERMS:
        case ActionTypes.REQUEST_SHIPPINGMETHODSTEP:
        case ActionTypes.REQUEST_SUBMITPAYPALPAYMENT:
        case ActionTypes.REQUEST_REMOVEPROMO:
        case ActionTypes.REQUEST_REMOVEGIFTCARD:
        case ActionTypes.REQUEST_REMOVELOYALTYCODE:
        case ActionTypes.REQUEST_UPDATEADDRESSBOOK:
        case ActionTypes.ENABLE_LOADER:
        case ActionTypes.SEND_PAYPAL_STEP_COMPLETED_EVENT:
        case ActionTypes.REQUEST_REMOVE_CARB_NO_SALE_ITEMS:
        case ActionTypes.REQUEST_REMOVE_CARB_ACKNOWLEDGE_ITEMS:
        case ActionTypes.REQUEST_ACKNOWLEDGE_CARB_TERMS:
        case ActionTypes.REQUEST_REMOVE_TRUCK_FREIGHT_ITEMS:
        case ActionTypes.REQUEST_REMOVE_ITEMS:
        case ActionTypes.REQUEST_PAYPAL_EXPRESS:
        case ActionTypes.REQUEST_MOBILE_PAY:
        case ActionTypes.REQUEST_CONFIRMATION_PAGE:
        case ActionTypes.REQUEST_VALIDATED_SMARTY_STREET_ADDRESS:
        case ActionTypes.FETCH_PAYPAL_EXPRESS_AUTHORIZE:
        case ActionTypes.ERASE_PAYMENTTECHIFRAME:
        case ShoppingCartActionTypes.REQUEST_UPDATE_SHOPPINGCART_PRODUCTS:
        case ShoppingCartActionTypes.REQUEST_SHOPPINGCARTPAGE:
        case ShoppingCartActionTypes.REQUEST_MOVE_BUILD_LIST_ITEM_TO_CART:
        case ShoppingCartActionTypes.REQUEST_MOVE_CART_ITEM_TO_BUILD_LIST:
        case ShoppingCartActionTypes.REQUEST_REMOVE_BUILD_LIST_ITEM:
        case REQUEST_SUBMIT_ACCOUNT_SIGN_UP:
        case ActionTypes.REQUEST_SUBMIT_PAYMENT_INFORMATION:
        case ActionTypes.REQUEST_REMOVE_DROPSHIP_ITEMS:
            return {
                isLoading: true 
            }

        default:
            return state
    }
}

export default loaderReducer