import { applyMiddleware, compose, createStore } from 'redux'
import { composeWithDevTools } from 'redux-devtools-extension';

import thunk from 'redux-thunk'
import { routerMiddleware } from 'react-router-redux'
import rootReducer from 'Reducers/rootReducer'
import FormValidationMiddleware from 'Middleware/FormValidationMiddleware'
import StoreHydrationMiddleware from 'Middleware/StoreHydrationMiddleware'
import BingMiddleware from 'Middleware/BingMiddleware'
import TwitterMiddleware from 'Middleware/TwitterMiddleware'
import SegmentMiddleware from 'Middleware/SegmentMiddleware'
import GeminiMiddleware from 'Middleware/GeminiMiddleware'

 
const middlewareWrapper = ({ dispatch, getState }) => next => action => {
    import('Middleware/Actions/GoogleAnalyticsMiddlewareAction')
        .then(module => module.default(action, dispatch, getState))
    import('Middleware/Actions/CheckoutApiMiddlewareAction')
        .then(module => module.default(action, dispatch, getState))
    import('Middleware/Actions/ShoppingCartApiMiddlewareAction')
        .then(module => module.default(action, dispatch, getState))
    import('Middleware/Actions/FacebookMiddlewareAction')
        .then(module => module.default(action, dispatch, getState))

    return next(action)
}

const configureStore = (history, initialState) => {
    const middleware = [
        thunk,
        routerMiddleware(history),
        FormValidationMiddleware,
        StoreHydrationMiddleware,
        TwitterMiddleware,
        GeminiMiddleware,
        SegmentMiddleware,
        //Bing appears that it is being sent still through the header. This will need to be enabled when we make a new header
        //BingMiddleware 

        // since the middleware handles checkout actions, it should be last
        middlewareWrapper

    ];

    return createStore(
        rootReducer,
        initialState,
        composeWithDevTools(applyMiddleware(...middleware))
    );
}

export default configureStore