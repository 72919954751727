import React, {lazy, Suspense, useEffect} from 'react'
import {connect} from 'react-redux'
import {withRouter} from 'react-router-dom'
import * as queryString from 'query-string'
import Header from 'Containers/Checkout/CheckoutHeader/CheckoutHeader'
import Footer from 'Containers/Checkout/CheckoutFooter/CheckoutFooter'
import {disableLoader, erasePaymentTechIframe, fetchShippingInfo} from 'Actions/checkoutTransactionAction'
import Loader from 'Components/Loader/Loader'
import * as ActionTypes from 'Types/CheckoutActionTypes'
import AddressShippingConstraints from "../../../Containers/Checkout/POBox/AddressShippingConstraints";

const TrustMarks = lazy(() => { return import('Containers/ShoppingCart/TrustMarks/TrustMarks') })
const ShippingAddressSelection = lazy(() => { return import('Containers/Checkout/ShippingAddressSelection/ShippingAddressSelection') })
const ShippingMethodSelection = lazy(() => { return import('Containers/Checkout/ShippingMethodSelection/ShippingMethodSelection') })
const Carb = lazy(() => { return import('Containers/Checkout/Carb/Carb') })
const ProgressBar = lazy(() => { return import('Containers/Checkout/ProgressBar/ProgressBar') })
const OrderDetails = lazy(() => { return import('Containers/Checkout/OrderDetails/OrderDetails') })

const Shipping = ({
    transactionId,
    storeHydrated,
    paymentMethodContainer,
    requestShippingStep,
    trustMarksEnabled
}) => {
    //same as component did mount
    useEffect(() => {
        window.scrollTo(0, 0)
        requestShippingStep(transactionId, storeHydrated, paymentMethodContainer)

        if(!navigator.globalPrivacyControl && !document.cookie.includes('EnableGpc=')) {
            fetch('/data/splitevent/trackcheckoutstep2pageview', {
                method: 'POST',
                credentials: 'include'
            }).catch(error => {
                console.error('Checkout step 2 page view error:', error)
            })
        }
    }, [])

    let trustMarks = false
    if (trustMarksEnabled === true) {
        trustMarks = <TrustMarks />
    }

    return (
        <>
            <Loader />
            <Header />
            <Suspense fallback={<div/>}>
                <div className="content">
                    <AddressShippingConstraints isPayPalExpress={false} />
                    <Carb isPayPalExpress={false} />
                    <Suspense fallback={<div/>}>
                        <ProgressBar step="shipping" />
                    </Suspense>
                    <div className="checkoutSideBar sticky">
                        <Suspense fallback={<div/>}>
                            <OrderDetails isOrderSummaryToggleActive={true} />
                        </Suspense>
                        <div className= "hideMobile">
                            {trustMarks}
                        </div>
                    </div>
                    <div className="checkoutMain">
                        <Suspense fallback={<div/>}>
                            <ShippingAddressSelection isEditable={true} />
                        </Suspense>
                        <Suspense fallback={<div/>}>
                            <ShippingMethodSelection />
                        </Suspense>
                    </div>

                    <div className="hideDesktop">
                        {trustMarks}
                    </div>
                </div>
            </Suspense>
            <Footer variant="shipping" />
        </>
    )
}

const requestShippingStep = (transactionId, storeHydrated, paymentMethodContainer) => dispatch => {
    const parsedQuery = queryString.parse(location.search)
    transactionId =
        transactionId === '' || transactionId === null ? parsedQuery.transactionId : transactionId

    const expresspaypalerror = parsedQuery.expresspaypalerror
    const error = parsedQuery.error

    if (error) {
        console.error(error)
        alert(error)
    } else if (expresspaypalerror) {
        console.error('Express Paypal Error')
        alert(
            "Looks like we're having issues with PayPal,continue through checkout and select a different payment option."
        )
    }
    if (!storeHydrated) {
        dispatch(fetchShippingInfo(transactionId))
    }
    if (paymentMethodContainer && paymentMethodContainer.paymentTechUrl) {
        dispatch(erasePaymentTechIframe())
        dispatch(disableLoader())
    }

    dispatch({
        type: ActionTypes.LOADED_SHIPPING_STEP,
        transactionId: transactionId
    })
}

const mapDispatchToProps = {
    requestShippingStep
}

const mapStateToProps = state => {
    return {
        transactionId: state.transaction.transactionId,
        storeHydrated: state.transaction.storeHydrated,
        paymentMethodContainer: state.transaction.paymentMethodContainer,
        trustMarksEnabled: typeof state.featureFlagContainer.trustMarksEnabled !== 'undefined' ? state.featureFlagContainer.trustMarksEnabled : false
    }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Shipping))
