export const REQUEST_SHOPPINGCARTPAGE = "REQUEST_SHOPPINGCARTPAGE"
export const RECEIVE_SHOPPINGCARTPAGE = "RECEIVE_SHOPPINGCARTPAGE"
export const REQUEST_UPDATE_SHOPPINGCART_PRODUCTS = "REQUEST_UPDATE_SHOPPINGCART_PRODUCTS"
export const RECEIVE_UPDATE_SHOPPINGCART_PRODUCTS = "RECEIVE_UPDATE_SHOPPINGCART_PRODUCTS"
export const REQUEST_MOVE_BUILD_LIST_ITEM_TO_CART = "REQUEST_MOVE_BUILD_LIST_ITEM_TO_CART"
export const RECEIVE_MOVE_BUILD_LIST_ITEM_TO_CART = "RECEIVE_MOVE_BUILD_LIST_ITEM_TO_CART"
export const REQUEST_MOVE_CART_ITEM_TO_BUILD_LIST = "REQUEST_MOVE_CART_ITEM_TO_BUILD_LIST"
export const RECEIVE_MOVE_CART_ITEM_TO_BUILD_LIST = "RECEIVE_MOVE_CART_ITEM_TO_BUILD_LIST"
export const REQUEST_REMOVE_BUILD_LIST_ITEM = "REQUEST_REMOVE_BUILD_LIST_ITEM"
export const RECEIVE_REMOVE_BUILD_LIST_ITEM = "RECEIVE_REMOVE_BUILD_LIST_ITEM"
export const ENTER_CHECKOUT = "ENTER_CHECKOUT"
export const UPDATE_DEBUG_SHIPPING_QUOTE_STATE = "UPDATE_DEBUG_SHIPPING_QUOTE_STATE"
export const HIDE_SHIPPING_ESTIMATE = 'HIDE_SHIPPING_ESTIMATE'
export const REQUEST_MOVE_SAVED_FOR_LATER_PART_TO_BUILD_LIST = "REQUEST_MOVE_SAVED_FOR_LATER_PART_TO_BUILD_LIST"
export const RECEIVE_MOVE_SAVED_FOR_LATER_PART_TO_BUILD_LIST = "RECEIVE_MOVE_SAVED_FOR_LATER_PART_TO_BUILD_LIST"
