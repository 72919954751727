import * as ActionTypes from 'Types/CheckoutActionTypes';
import * as ErrorTypes from 'Types/ErrorTypes';
import { getAddressValidationErrors, getEmailSignupValidationErrors, getLoyaltySignupValidationErrors, isFormValid } from 'Utilities/FormValidator';
import { displayWarnings, displayErrors, displayErrorsToCustomBilling, updateSmartyStreetWarnings } from 'Actions/checkoutTransactionAction'


// this middleware doesn't call next(action) when validation errors happen, so leaving as a proper middleware
const FormValidationMiddleware = ({ dispatch, getState }) => next => action => {
    var { transaction } = getState()
    var errors = []

    switch (action.type) {
        case ActionTypes.REQUEST_SUBMITCUSTOMERINFO:
            errors = getAddressValidationErrors(transaction.guestCheckoutAddressForm);
            if (!isFormValid(errors)) {
                dispatch(displayErrors(errors))
            } else {
                next(action)
            }
            break;
        case ActionTypes.REQUEST_SUBMIT_BRAINTREE_PAYPALEXPRESS:
            errors = getAddressValidationErrors(transaction.paypalAddressForm);
            if (!isFormValid(errors)) {
                dispatch(displayErrors(errors))
            } else {
                next(action)
            }
            break;
        case ActionTypes.REQUEST_SUBMIT_BRAINTREE_MOBILEPAY:
        errors = getAddressValidationErrors(transaction.mobilePayAddressForm);
        if (!isFormValid(errors)) {
            dispatch(displayErrors(errors))
        } else {
            next(action)
        }
        break;
        case ActionTypes.REQUEST_SUBMIT_PAYMENT_INFORMATION:
            {
                let isCustomBillingAddressSelected = transaction.paymentMethodContainer.isCustomBillingAddressSelected

                if (isCustomBillingAddressSelected) {
                    errors = getAddressValidationErrors(transaction.billingAddressForm);
                    if (!isFormValid(errors)) {
                        dispatch(displayErrors(errors))
                    } else {
                        next(action)
                    }
                } else {
                    const isDefaultBillingAddressSelected = transaction.paymentMethodContainer.isDefaultBillingAddressSelected
                    const isShippingAddressBillingAddressSelected = transaction.paymentMethodContainer.isShippingAddressBillingAddressSelected
                    const addressLookup = transaction.addressLookup
                    const addresses = transaction.addresses

                    if (addressLookup && addresses) {
                        let billingAddress = isDefaultBillingAddressSelected ? addresses[addressLookup.defaultBillingAddressId] : (isShippingAddressBillingAddressSelected ? addresses[addressLookup.shippingAddressId] : undefined)

                        if (billingAddress) {
                            errors = getAddressValidationErrors(billingAddress);
                            if (!isFormValid(errors)) {
                                dispatch(displayErrors(errors))
                                dispatch(displayErrorsToCustomBilling(billingAddress))
                            } else {
                                next(action)
                            }
                        } else {
                            next(action)
                        }
                    } else {
                        next(action)
                    }
                }
                break;
            }
        case ActionTypes.REQUEST_UPDATEADDRESSBOOK:
            errors = getAddressValidationErrors(transaction.addressBookAddressForm);
            if (!isFormValid(errors)) {
                dispatch(displayErrors(errors))
            } else {
                next(action)
            }
            break;
        case ActionTypes.REQUEST_SUBMIT_PAYPALEXPRESS:
            //this decides if we should check the phone number or not
            if (action.payload.requiredPhoneBool === false) {
                next(action)
            }
            errors = getAddressValidationErrors(transaction.payPalExpressForm);
            if (!isFormValid(errors)) {
                dispatch(displayErrors(errors))
            } else {
                next(action)
            }
            break;
        case ActionTypes.SUBMIT_EMAIL_SIGN_UP:
            errors = getEmailSignupValidationErrors(transaction.emailSignUp);
            if (!isFormValid(errors)) {
                dispatch(displayErrors(errors))
            } else {
                next(action)
            }
            break;
        case ActionTypes.SUBMIT_LOYALTY_SIGN_UP:
            errors = getLoyaltySignupValidationErrors(transaction.loyaltySignUp);
            if (!isFormValid(errors)) {
                dispatch(displayErrors(errors))
            } else {
                next(action)
            }
            break;
        case ActionTypes.UPDATE_FORM_VALIDATION:
            var addressErrors = getAddressValidationErrors(action.payload);
            dispatch(displayErrors(addressErrors))
            updateWarningValidation(dispatch, action, transaction, getState)
            next(action)
            break;
        case ActionTypes.UPDATE_EMAIL_SIGN_UP:
            var emailSignupErrors = getEmailSignupValidationErrors(action.payload);
            dispatch(displayErrors(emailSignupErrors))
            next(action)
            break;
        case ActionTypes.UPDATE_LOYALTY_SIGN_UP:
            var loyaltySignupErrors = getLoyaltySignupValidationErrors(action.payload);
            dispatch(displayErrors(loyaltySignupErrors))
            next(action)
            break;
        case ActionTypes.DISPLAY_SMARTY_STREET_WARNINGS:
            {
                let warnings = getSmartyStreetWarnings(action.payload)
                if (warnings) {
                    console.log("SmartyStreets warning validation displayed.")
                    dispatch(displayWarnings(warnings))
                }
                next(action)
                break
            }
        default:
            next(action);
    };
};

function updateWarningValidation(dispatch, action, transaction, getState) {
    var warningState = {
        transaction: transaction,
        formUpdated: action.payload
    }
    dispatch(updateSmartyStreetWarnings(warningState))
    let state = getState()
    let warnings = getSmartyStreetWarnings(state.transaction.addressValidationErrors)
    if(warnings)
        dispatch(displayWarnings(warnings))
}

function getSmartyStreetWarnings(smartyStreetErrors) {
    if(!smartyStreetErrors)
        return null

    let warnings = []

    if (smartyStreetErrors.apartmentSuiteNumberRequired) {
        warnings[ErrorTypes.ADDRESS_FORM_ADDRESS_LINE_2] = ["Does this address require an apartment or suite?"]
    }
    if (smartyStreetErrors.isCommercial) {
        warnings[ErrorTypes.ADDRESS_FORM_COMPANY] = ["Is this a commercial address? If so, please add a business name."]
    }
    if (smartyStreetErrors.addressDoesNotReceiveMail) {
        warnings[ErrorTypes.ADDRESS_FORM_ADDRESS_LINE_1] = ["Does this address receive mail?"]
    }
    if (smartyStreetErrors.boxNumberIsMissingOrInvalid) {
        warnings[ErrorTypes.ADDRESS_FORM_ADDRESS_LINE_2] = ["Is this box number valid?"]
    }
    if (smartyStreetErrors.isInvalidAddress) {
        warnings[ErrorTypes.ADDRESS_FORM_ADDRESS_LINE_1] = ["Is this address correct?"]
    }
    if (smartyStreetErrors.partialAddress) {
        warnings[ErrorTypes.ADDRESS_FORM_ADDRESS_LINE_1] = ["Is this address missing something?"]
    }
    if (smartyStreetErrors.isInvalidSopAddress) {
        warnings[ErrorTypes.ADDRESS_FORM_ADDRESS_LINE_1] = ["Is this address correct? (Ensure each address lines are limit to 30 characters)"]
    }
    if (smartyStreetErrors.invalidAddressNumber) {
        warnings[ErrorTypes.ADDRESS_FORM_ADDRESS_LINE_1] = ["Is this address number valid?"]
    }

    return warnings
}

export default FormValidationMiddleware;