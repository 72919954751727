export function UpdatePhoneNumberFormatUsa(input) {

    var cleaned = ('' + input).replace(/\D/g, '')

    var matchCountryCode = cleaned.match(/^(1|)?(\d{3})(\d{3})(\d{4})$/)
    if (matchCountryCode) {
        return ['(', matchCountryCode[2], ') ', matchCountryCode[3], '-', matchCountryCode[4]].join('')
    }
    var matchLastFour = cleaned.match(/^(\d{3})(\d{3})(\d{1,4})$/)
    if (matchLastFour) {
        return ['(', matchLastFour[1], ') ', matchLastFour[2], '-', matchLastFour[3]].join('')
    }
    var matchMiddleThree = cleaned.match(/^(\d{3})(\d{1,2})$/)
    if (matchMiddleThree) {
        return ['(', matchMiddleThree[1], ') ', matchMiddleThree[2]].join('')
    }
    var matchAreaCode = cleaned.match(/^(\d{1,2})$/)
    if (matchAreaCode) {
        return ['(', matchAreaCode[1]].join('')
    }

    var matchExtraWithCountryCode = cleaned.match(/^(1|)?(\d{3})(\d{3})(\d{5,})$/)
    if (matchExtraWithCountryCode) {
        return ['(', matchExtraWithCountryCode[2], ') ', matchExtraWithCountryCode[3], '-', matchExtraWithCountryCode[4].substring(0, 4)].join('')
    }

    var matchExtra = cleaned.match(/^(\d{3})(\d{3})(\d{5,})$/)
    if (matchExtra) {
        return ['(', matchExtra[1], ') ', matchExtra[2], '-', matchExtra[3].substring(0, 4)].join('')
    }

    return input ? input : '';
}



export function LoadScript(script, scriptId, callback) {

    let existingScript = document.getElementById(scriptId)

    if (!existingScript) {
        const newScript = document.createElement('script')
        newScript.src = script
        newScript.id = scriptId
        document.body.appendChild(newScript)

        newScript.onload = () => {
            if (callback) callback()
        }
    }

    if (existingScript && callback) callback()
}