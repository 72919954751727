import React from "react";
const regex = RegExp('service-worker\.js');

// This exists to remove the service worker from tng
const UnregisterServiceWorker = ({ ...props }) => {
    // If the browser doesn't support service workers, bail.
    if ("serviceWorker" in navigator === false) {
        console.log("service worker is not supported");
        return false;
    }

    navigator.serviceWorker.getRegistrations().then(function (registrations) {
        for (let registration of registrations) {
            if (regex.test(registration.active.scriptURL)) {
                console.log("Unregistering Old Service Worker")
                registration.unregister()
            }
        }
    });

    return false;
};

export default UnregisterServiceWorker;
