import * as ActionTypes from 'Types/CheckoutActionTypes';
import {LoadScript} from "Utilities/Utilities"

const TwitterMiddleware = ({ dispatch, getState }) => next => action => {
    
    if(!navigator.globalPrivacyControl && !document.cookie.includes('EnableGpc=')) {
        try {
            switch (action.type) {
                case ActionTypes.HYDRATED_CONFIRMATION_STEP: {
                    const script = "//platform.twitter.com/oct.js"
                    const scriptId = "twitterPixel"
                    const callback = function () {
                        twttr.conversion.trackPid('nunu8', {
                            tw_sale_amount: action.payload.checkoutTransactionRm.confirmation.orderSubTotal,
                            tw_order_quantity: 1
                        });
                    }

                    LoadScript(script, scriptId, callback)

                    break
                }

                default:
                    break
            }
        } catch (e) {
            console.error(e)
        }
    }
    
    next(action)
}

export default TwitterMiddleware;

