export const REQUEST_ENTRY = 'REQUEST_ENTRY'
export const RECEIVE_ENTRY = 'RECEIVE_ENTRY'
export const REQUEST_CUSTOMERINFO = 'REQUEST_CUSTOMERINFO'
export const RECEIVE_CUSTOMERINFO = 'RECEIVE_CUSTOMERINFO'
export const REQUEST_SHIPPING = 'REQUEST_SHIPPING'
export const RECEIVE_SHIPPING = 'RECEIVE_SHIPPING'
export const REQUEST_SHIPPINGOPTION = 'REQUEST_SHIPPINGOPTION'
export const RECEIVE_SHIPPINGOPTION = 'RECEIVE_SHIPPINGOPTION'
export const REQUEST_SHIPPINGPROTECTION = 'REQUEST_SHIPPINGPROTECTION'
export const RECEIVE_SHIPPINGPROTECTION = 'RECEIVE_SHIPPINGPROTECTION'
export const REQUEST_UPDATESHIPPINGADDRESS = 'REQUEST_UPDATESHIPPINGADDRESS'
export const RECEIVE_UPDATESHIPPINGADDRESS = 'RECEIVE_UPDATESHIPPINGADDRESS'
export const UPDATE_INSTORE_PICKUP_LOCATION = 'UPDATE_INSTORE_PICKUP_LOCATION'
export const REQUEST_PAYMENT = 'REQUEST_PAYMENT'
export const RECEIVE_PAYMENT = 'RECEIVE_PAYMENT'
export const REQUEST_DEALERTERMS = 'REQUEST_DEALERTERMS'
export const RECEIVE_DEALERTERMS = 'RECEIVE_DEALERTERMS'
export const REQUEST_PAYMENTTECH_URL = 'REQUEST_PAYMENTTECH_URL'
export const RECEIVE_PAYMENTTECH_URL = 'RECEIVE_PAYMENTTECH_URL'
export const HANDLE_PROMO_OR_GIFTCARD_CHANGE = 'HANDLE_PROMO_OR_GIFTCARD_CHANGE'
export const HANDLE_LOYALTY_CODE_CHANGE = 'HANDLE_LOYALTY_CODE_CHANGE'
export const REQUEST_PROMO_OR_GIFTCARD = 'REQUEST_PROMO_OR_GIFTCARD'
export const RECEIVE_PROMO_OR_GIFTCARD = 'RECEIVE_PROMO_OR_GIFTCARD'
export const REQUEST_LOYALTY_CODE = 'REQUEST_LOYALTY_CODE'
export const RECEIVE_LOYALTY_CODE = 'RECEIVE_LOYALTY_CODE'
export const REQUEST_SHIPPINGMETHODSTEP = 'REQUEST_SHIPPINGMETHODSTEP'
export const RECEIVE_SHIPPINGMETHODSTEP = 'RECEIVE_SHIPPINGMETHODSTEP'
export const REQUEST_SUBMITPAYPALPAYMENT = 'REQUEST_SUBMITPAYPALPAYMENT'
export const REQUEST_REMOVEGIFTCARD = 'REQUEST_REMOVEGIFTCARD'
export const RECEIVE_REMOVEGIFTCARD = 'RECEIVE_REMOVEGIFTCARD'
export const REQUEST_REMOVEPROMO = 'REQUEST_REMOVEPROMO'
export const RECEIVE_REMOVEPROMO = 'RECEIVE_REMOVEPROMO'
export const REQUEST_REMOVELOYALTYCODE = 'REQUEST_REMOVELOYALTYCODE'
export const RECEIVE_REMOVELOYALTYCODE = 'RECEIVE_REMOVELOYALTYCODE'
export const SUBMIT_CHECKOUT = 'SUBMIT_CHECKOUT'
export const DISABLE_LOADER = 'DISABLE_LOADER'
export const ENABLE_LOADER = 'ENABLE_LOADER'
export const DISABLE_ADDRESSBOOK = 'DISABLE_ADDRESSBOOK'
export const ENABLE_ADDRESSBOOK = 'ENABLE_ADDRESSBOOK'
export const UPDATE_CUSTOMER_INFO_ADDRESS_FORM = 'UPDATE_CUSTOMER_INFO_ADDRESS_FORM'
export const UPDATE_PAYPAL_ADDRESS_FORM = 'UPDATE_PAYPAL_ADDRESS_FORM'
export const UPDATE_MOBILEPAY_ADDRESS_FORM = 'UPDATE_MOBILEPAY_ADDRESS_FORM'
export const REQUEST_SUBMIT_BRAINTREE_PAYPALEXPRESS = 'REQUEST_SUBMIT_BRAINTREE_PAYPALEXPRESS'
export const REQUEST_SUBMIT_BRAINTREE_MOBILEPAY = 'REQUEST_SUBMIT_BRAINTREE_MOBILEPAY'
export const UPDATE_CARB_FORM = 'UPDATE_CARB_FORM'
export const UPDATE_ADDRESS_BOOK_ADDRESS_FORM = 'UPDATE_ADDRESS_BOOK_ADDRESS_FORM'
export const UPDATE_BILLING_ADDRESS_FORM = 'UPDATE_BILLING_ADDRESS_FORM'
export const SELECT_CUSTOM_BILLING_ADDRESS_BRAINTREE = 'SELECT_CUSTOM_BILLING_ADDRESS_BRAINTREE'
export const REQUEST_SUBMITCUSTOMERINFO = 'REQUEST_SUBMITCUSTOMERINFO'
export const RECEIVE_SUBMITCUSTOMERINFO = 'RECEIVE_SUBMITCUSTOMERINFO'
export const REDIRECT_ACTION = 'REDIRECT_ACTION'
export const REQUEST_UPDATEADDRESSBOOK = 'REQUEST_UPDATEADDRESSBOOK'
export const RECEIVE_UPDATEADDRESSBOOK = 'RECEIVE_UPDATEADDRESSBOOK'
export const DISPLAY_ERRORS = 'DISPLAY_ERRORS'
export const DISPLAY_WARNINGS = 'DISPLAY_WARNINGS'
export const STORE_HYDRATED_FLAG = 'STORE_HYDRATED_FLAG'
export const RESET_ADDRESSBOOKADDRESS = 'RESET_ADDRESSBOOKADDRESS'
export const UPDATE_BILLING_ADDRESS = 'UPDATE_BILLING_ADDRESS'
export const SELECT_SAME_AS_SHIPPING_BILLING_ADDRESS = 'SELECT_SAME_AS_SHIPPING_BILLING_ADDRESS'
export const SELECT_DEFAULT_BILLING_ADDRESS = 'SELECT_DEFAULT_BILLING_ADDRESS'
export const SELECT_CUSTOM_BILLING_ADDRESS = 'SELECT_CUSTOM_BILLING_ADDRESS'
export const DISPLAY_ERRORS_TO_CUSTOM_BILLING = 'DISPLAY_ERRORS_TO_CUSTOM_BILLING'
export const FINALIZE_CREDIT_CARD_PAYMENT = 'FINALIZE_CREDIT_CARD_PAYMENT'
export const RECEIVE_REMOVE_CARB_ITEMS = 'RECEIVE_REMOVE_CARB_ITEMS'
export const REQUEST_REMOVE_CARB_ACKNOWLEDGE_ITEMS = 'REQUEST_REMOVE_CARB_ACKNOWLEDGE_ITEMS'
export const REQUEST_REMOVE_CARB_NO_SALE_ITEMS = 'REQUEST_REMOVE_CARB_NO_SALE_ITEMS'
export const REQUEST_ACKNOWLEDGE_CARB_TERMS = 'REQUEST_ACKNOWLEDGE_CARB_TERMS'
export const RECEIVE_ACKNOWLEDGE_CARB_TERMS = 'RECEIVE_ACKNOWLEDGE_CARB_TERMS'
export const UPDATE_CARB_SUBCLASS_FORM = 'UPDATE_CARB_SUBCLASS_FORM'
export const RECEIVE_CARB_SUBCLASS_FORM = 'RECEIVE_CARB_SUBCLASS_FORM'
export const UPDATE_PAYMENTMETHOD = 'UPDATE_PAYMENTMETHOD'
export const UPDATE_PAYMENTTECHIFRAME = 'UPDATE_PAYMENTTECHIFRAME'
export const ERASE_PAYMENTTECHIFRAME = 'ERASE_PAYMENTTECHIFRAME'
export const REQUEST_PAYPAL = 'REQUEST_PAYPAL'
export const RECEIVE_PAYPAL = 'RECEIVE_PAYPAL'
export const REQUEST_SUBMIT_PAYPALEXPRESS = 'REQUEST_SUBMIT_PAYPALEXPRESS'
export const RECEIVE_SUBMIT_PAYPALEXPRESS = 'RECEIVE_SUBMIT_PAYPALEXPRESS'
export const UPDATE_PAYPAL_EXPRESS_ADDRESS_FORM = 'UPDATE_PAYPAL_EXPRESS_ADDRESS_FORM'
export const REQUEST_PAYPAL_EXPRESS = 'REQUEST_PAYPAL_EXPRESS'
export const RECEIVE_PAYPAL_EXPRESS = 'RECEIVE_PAYPAL_EXPRESS'
export const DELIVERYINSTRUCTIONS = 'DELIVERYINSTRUCTIONS'
export const ENABLE_PAYMENTTECH_OVERLAY = 'ENABLE_PAYMENTTECH_OVERLAY'
export const REQUEST_CONFIRMATION_PAGE = 'REQUEST_CONFIRMATION_PAGE'
export const RECEIVE_CONFIRMATION_PAGE = 'RECEIVE_CONFIRMATION_PAGE'
export const REQUEST_SMARTY_STREET_ADDRESSES = 'REQUEST_SMARTY_STREET_ADDRESSES'
export const RECEIVE_SMARTY_STREET_ADDRESSES = 'RECEIVE_SMARTY_STREET_ADDRESSES'
export const REQUEST_VALIDATED_SMARTY_STREET_ADDRESS = 'REQUEST_VALIDATED_SMARTY_STREET_ADDRESS'
export const RECEIVE_VALIDATED_SMARTY_STREET_ADDRESS = 'RECEIVE_VALIDATED_SMARTY_STREET_ADDRESS'
export const RECEIVE_ADDRESS_VALIDATION_ERRORS = 'RECEIVE_ADDRESS_VALIDATION_ERRORS'
export const RECEIVE_EMAIL_VALIDATION_ERROR = 'RECEIVE_EMAIL_VALIDATION_ERROR'
export const SUBMIT_EMAIL_SIGN_UP = 'SUBMIT_EMAIL_SIGN_UP'
export const RECEIVE_EMAIL_SIGN_UP = 'RECEIVE_EMAIL_SIGN_UP'
export const UPDATE_EMAIL_SIGN_UP = 'UPDATE_EMAIL_SIGN_UP'
export const UPDATE_LOYALTY_SIGN_UP = 'UPDATE_LOYALTY_SIGN_UP'
export const SUBMIT_LOYALTY_SIGN_UP = 'SUBMIT_LOYALTY_SIGN_UP'
export const RECEIVE_LOYALTY_SIGN_UP = 'RECEIVE_LOYALTY_SIGN_UP'
export const UPDATE_FORM_VALIDATION = 'UPDATE_FORM_VALIDATION'
export const REMOVE_WARNING_VALIDATION = 'REMOVE_WARNING_VALIDATION'
export const UPDATE_WARNING_VALIDATION = 'UPDATE_WARNING_VALIDATION'
export const SUBMIT_PAYMENT_INFORMATION = 'SUBMIT_PAYMENT_INFORMATION'
export const REQUEST_SUBMIT_PAYMENT_INFORMATION = 'REQUEST_SUBMIT_PAYMENT_INFORMATION'
export const FINALIZE_PAYMENT = 'FINALIZE_PAYMENT'
export const SEND_PAYPAL_STEP_COMPLETED_EVENT = 'SEND_PAYPAL_STEP_COMPLETED_EVENT'
export const SEND_MOBILE_PAY_STEP_COMPLETED_EVENT = 'SEND_MOBILE_PAY_STEP_COMPLETED_EVENT'
export const DISPLAY_SMARTY_STREET_WARNINGS = 'DISPLAY_SMARTY_STREET_WARNINGS'
export const RECEIVE_REMOVE_DROPSHIP_ITEMS = 'RECEIVE_REMOVE_DROPSHIP_ITEMS'
export const RECEIVE_REMOVE_TRUCK_FREIGHT_ITEMS = 'RECEIVE_REMOVE_TRUCK_FREIGHT_ITEMS'
export const REQUEST_REMOVE_DROPSHIP_ITEMS = 'REQUEST_REMOVE_DROPSHIP_ITEMS'
export const REQUEST_REMOVE_TRUCK_FREIGHT_ITEMS = 'REQUEST_REMOVE_TRUCK_FREIGHT_ITEMS'
export const RECEIVE_ADDRESS_SHIPPING_CONSTRAINT_MODAL = 'RECEIVE_ADDRESS_SHIPPING_CONSTRAINT_MODAL'
export const REQUEST_REMOVE_ITEMS = 'REQUEST_REMOVE_ITEMS'
export const RECEIVE_REMOVE_ITEMS = 'RECEIVE_REMOVE_ITEMS'

// These actions are used to trigger ga enhanced ecommerce analytics events.
export const LOADED_CUSTOMER_INFO_STEP = 'LOADED_CUSTOMER_INFORMATION_STEP'
export const LOADED_SHIPPING_STEP = 'LOADED_SHIPPING_STEP'
export const LOADED_CARB_MODAL = 'LOADED_CARB_MODAL'
export const CARB_ITEMS_REMOVED = 'CARB_ITEMS_REMOVED'
export const LOADED_PAYMENT_STEP = 'LOADED_PAYMENT_STEP'
export const LOADED_CONFIRMATION_STEP = 'LOADED_CONFIRMATION_STEP'
export const HYDRATED_CONFIRMATION_STEP = 'HYDRATED_CONFIRMATION_STEP'
export const UPDATE_ADDITIONAL_ADDRESS_FORM_DATA = 'UPDATE_ADDITIONAL_ADDRESS_FORM_DATA'

export const FETCH_PAYPAL_EXPRESS = 'FETCH_PAYPAL_EXPRESS'
export const FETCH_PAYPAL_EXPRESS_AUTHORIZE = 'FETCH_PAYPAL_EXPRESS_AUTHORIZE'
export const FETCH_PAYPAL_EXPRESS_ERROR = 'FETCH_PAYPAL_EXPRESS_ERROR'
export const CLEAR_PAYMENT_ERRORS = 'CLEAR_PAYMENT_ERRORS'

export const REQUEST_MOBILE_PAY = 'REQUEST_MOBILE_PAY'
export const RECEIVE_MOBILE_PAY = 'RECEIVE_MOBILE_PAY'
