import React, { Fragment, useState } from 'react'
import { connect } from 'react-redux'
import classNames from 'classnames'
import { withRouter } from 'react-router-dom'

const Geolocation = (
    {
        userProfile,
        receiveGeolocation,
        fetchGeolocation,
        featureFlagContainer
    }
) => {
    if (featureFlagContainer && featureFlagContainer.isGeolocationEnabled) {
        if (userProfile && !userProfile.ip) {
            fetchGeolocation()
        }
    }
    return false
}

const fetchGeolocation = () => dispatch => {
    fetch('https://api.ipstack.com/check?access_key=d1f2dc908712c8edc8d9d38a1cec0367')
        .then(response => response.json())
        .then(json => {
            dispatch(receiveGeolocation(json));
        })
        .catch(function (error) {
            console.log(error);
        });
}

// Action Types.
export const RECEIVE_GEOLOCATION = 'RECEIVE_GEOLOCATION'

// Actions.
export const receiveGeolocation = data => ({
    type: RECEIVE_GEOLOCATION,
    payload: data
})

const mapDispatchToProps = {
    receiveGeolocation,
    fetchGeolocation
}
const mapStateToProps = (state) => {
    return {
        userProfile: state.userProfile,
        featureFlagContainer : state.featureFlagContainer
    }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Geolocation))
