import * as ActionTypes from 'Types/GoogleAnalyticsActionTypes'

export const sendGoogleAnalyticsEvent = (category, action, label, nonInteraction = false) => ({
    type: ActionTypes.SEND_GOOGLE_ANALYTICS_EVENT,
    payload: {
        category: category,
        action: action,
        label: label,
        nonInteraction: nonInteraction
    }
})

export const sendProductRecommendationEnhancedImpressionEvent = (variant, productRecommendations) => ({
    type: ActionTypes.SEND_PRODUCT_RECOMMENDATION_ENHANCED_IMPRESSION_EVENT,
    payload: {
        "variant": variant,
        "productRecommendations": productRecommendations
    }
})

export const sendProductRecommendationEnhancedClickEvent =
    (
        page,
        recommendationsTitle,
        sku,
        ordinality,
        labelName,
        variant,
        isSkuVariant,
        price,
        title
        ) => (
            {
                type: ActionTypes.SEND_PRODUCT_RECOMMENDATION_ENHANCED_CLICK_EVENT,
                payload: {
                    "page": page,
                    "recommendationsTitle": recommendationsTitle,
                    "sku": sku,
                    "ordinality": ordinality,
                    "labelName": labelName,
                    "variant": variant,
                    "isSkuVariant": isSkuVariant,
                    "price": price,
                    "title": title
                }
            }
        )

export const sendProductRecommendationEnhancedAddToCartEvent =
    (
        page,
        recommendationsTitle,
        sku,
        ordinality,
        labelName,
        variant,
        isSkuVariant,
        price,
        title
    ) => (
            {
                type: ActionTypes.SEND_PRODUCT_RECOMMENDATION_ENHANCED_ADD_TO_CART_EVENT,
                payload: {
                    "page": page,
                    "recommendationsTitle": recommendationsTitle,
                    "sku": sku,
                    "ordinality": ordinality,
                    "labelName": labelName,
                    "variant": variant,
                    "isSkuVariant": isSkuVariant,
                    "price": price,
                    "title": title,
                }
            }
        )
