import { RECEIVE_GEOLOCATION } from 'Containers/Shared/Geolocation/Geolocation'
const initialState = {

    isDealer: false,
    canUserEditAddress: false,
    ip: '',
    zip: '',
    city: '',
    state: '',
    hasAccountsReceivable: false
}
 
const userProfileReducer = (state, action) => {
    state = state || initialState

    if (action.payload && action.payload.userProfile) {
        return {
            ...state,
            ...action.payload.userProfile
        }

    }
    else if (action.type === RECEIVE_GEOLOCATION) {
        return {
            ...state,
            ...action.payload
        }
    }else {
        return state 
    }

}
export default userProfileReducer