export function incrementSessionStorageKeyEventCount(eventName) {
    const keyEvents = [
        "Product Added",
        "Product Added to Wishlist",
        "Fitment Added",
        "Signed In",
        "Product Reviewed",
        "Product Shared",
        "Order Completed",
        "Products Searched",
        "Signed Up",
        "Wishlist Created",
    ];

    try {
        if (keyEvents.includes(eventName)) {
            let keyEventCount =
                parseInt(sessionStorage.getItem("keyEventCount"), 10) || 0;
            keyEventCount += 1;
            sessionStorage.setItem("keyEventCount", keyEventCount.toString());

            checkAndTrackEngagedSession();
        }
    } catch (error) {
        console.debug("Error incrementing key event count:", error);
    }
}

export function getSessionDuration() {
    try {
        const sessionStartTimeStr = sessionStorage.getItem("sessionStartTime");

        if (!sessionStartTimeStr) {
            console.warn(
                "Session start time not found in session storage. Defaulting to 0."
            );
            return 0;
        }

        const sessionStartTime = new Date(sessionStartTimeStr);

        if (isNaN(sessionStartTime.getTime())) {
            console.warn("Invalid session start time format. Defaulting to 0.");
            return 0;
        }

        const currentTime = new Date();

        return (currentTime - sessionStartTime) / 1000;
    } catch (error) {
        console.error("Error calculating session duration:", error);
        return 0;
    }
}

export function getSessionStorageKeyEventCount() {
    try {
        let keyEventCount = parseInt(sessionStorage.getItem("keyEventCount"), 10);

        if (isNaN(keyEventCount)) {
            console.warn("Invalid key event count format. Defaulting to 0.");
            keyEventCount = 0;
        }

        return keyEventCount;
    } catch (error) {
        console.error("Error getting key event count:", error);
        return 0;
    }
}

export function getSessionStoragePageViewCount() {
    try {
        let pageViewCount = parseInt(sessionStorage.getItem("pageViewCount"), 10);

        if (isNaN(pageViewCount)) {
            console.warn("Invalid page view count format. Defaulting to 0.");
            pageViewCount = 0;
        }

        console.debug("Page View Count: ", pageViewCount);

        return pageViewCount;
    } catch (error) {
        console.error("Error getting page view count:", error);
        return 0;
    }
}

export function getIsEngagedSession(
    sessionDuration,
    keyEventCount,
    pageViewCount
) {
    try {
        return sessionDuration >= 10 || keyEventCount >= 1 || pageViewCount >= 2;
    } catch (error) {
        console.warn("Error calculating isEngagedSession:", error);
        return false;
    }
}

export function getIsEngagedSessionFired() {
    try {
        const isEngagedSessionFired = sessionStorage.getItem(
            "isEngagedSessionFired"
        );

        if (isEngagedSessionFired === null) {
            return false;
        }

        return isEngagedSessionFired === "true";
    } catch (error) {
        console.error("Error getting isEngagedSessionFired:", error);
        return false;
    }
}

export function trackEngagedSession() {
    if (getIsEngagedSessionFired()) {
        return;
    }

    const sessionDuration = getSessionDuration();
    const keyEventCount = getSessionStorageKeyEventCount();
    const pageViewCount = getSessionStoragePageViewCount();
    const isEngagedSession = getIsEngagedSession(
        sessionDuration,
        keyEventCount,
        pageViewCount
    );

    if (isEngagedSession) {
        const isEngagedSessionFired =
            sessionStorage.getItem("isEngagedSessionFired") === "true";
        if (!isEngagedSessionFired) {
            let properties = addSessionProperties();

            if (window.analytics?.track) {
                try {
                    window.analytics.track("Engaged Session", properties);
                } catch (e) {
                    console.error(e)
                }
            }

            sessionStorage.setItem("isEngagedSessionFired", "true");
        } else {
            console.error("analytics or analytics.track were not found - not a problem on localhost, but bad in other env")
        }
    }
}

export function checkAndTrackEngagedSession() {
    const sessionDuration = getSessionDuration();
    const keyEventCount = getSessionStorageKeyEventCount();
    const pageViewCount = getSessionStoragePageViewCount();
    const isEngagedSession = getIsEngagedSession(
        sessionDuration,
        keyEventCount,
        pageViewCount
    );

    if (isEngagedSession) {
        trackEngagedSession();
    }
}

export function addSessionProperties(properties = {}) {
    const sessionDuration = getSessionDuration();
    const keyEventCount = getSessionStorageKeyEventCount();
    const pageViewCount = getSessionStoragePageViewCount();
    const isEngagedSession = getIsEngagedSession(
        sessionDuration,
        keyEventCount,
        pageViewCount
    );

    return {
        ...properties,
        sessionDuration,
        keyEventCount,
        pageViewCount,
        isEngagedSession,
    };
}
