import "regenerator-runtime/runtime";
import 'whatwg-fetch'
import './index.css'
import React from 'react'
import { render } from 'react-dom'
import { BrowserRouter as Router } from 'react-router-dom'
import configureStore from './store/configureStore'
import ClientApp from './Containers/Shared/ClientApp'
import 'Utilities/CutcopyTracker';


const store = configureStore()

if (IS_EXPERIMENTAL_ENVIRONMENT) {
    //use preact which has a slightly different render function
    console.log('experimental mode!')
    render(
        <div id="appContainer"> {/*this additional container is to make preact work*/}
            <Router>
                <ClientApp store={store} />
            </Router>
        </div>,
        document.getElementById('root'),
        document.getElementById('appContainer') //again, this is to make preact work
    )
} else {
    //use react
    render(
        <div id="appContainer"> {/*this is for preact... it's not really needed for react, but we'll included it anyway*/}
            <Router>
                <ClientApp store={store} />
            </Router>
        </div>,
        document.getElementById('root')
    )
}
