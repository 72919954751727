import * as ActionTypes from 'Types/CheckoutActionTypes'
import {CANADA_ID, USA_ID} from 'Constants/Constants'
import Shipping from '../Routes/Checkout/Shipping/Shipping';

export const initialAddressFormState = {
    addressId: null,
    email: "",
    phoneNumber: "",
    fullName: "",
    addressLine1: "",
    addressLine2: "",
    city: "",
    province: "",
    provinceId: "",
    countryId: "", // default to US
    postalCode: "",
    isEmailListCheckboxActive: true,
    isTextListCheckboxActive: false,
    isPromotionalTextListCheckboxActive: false,
    isTransactionalTextListCheckboxActive: false,
    isInternationalAddressToggleActive: false
}

const initialState = {
    storeHydrated: false,
    paypalPhoneNumber: "",
    thirdPartyPaymentContainer: {
        payPalExpressInfo: {
        }
    },
    shippingMethodContainer: {
        shipments: [
        ],
        deliveryInstructions: ""
    },
    shippingProtectionOptionsContainer: {

    },
    addresses: [
    ],
    addressForm: {
        countries: [],
        provinces: [],
        states: []
    },
    paymentMethodContainer: {
        isDealerEnabled: false,
        isPayPalEnabled: false,
        promos: [],
        giftCards: [],
        currentPromoOrGiftCard: "",
        selectedLoyaltyCode: "",
        appliedLoyaltyCode: "",
        paymentTechUrl: "",
        hasDefaultBillingAddress: false,
        defaultBillingAddressId: 0,
        hasShippingAddress: false,
        shipToAddressId: 0,
        isInStorePickup: false,
        isPaymentTechOverlayDisplayed: false,
        isDiscountApplied: false,
        userRedemptions: [],
        loyaltyPointsAwarded: 0
    },
    guestCheckoutAddressForm: {
        ...initialAddressFormState
    },
    addressBookAddressForm: {
        ...initialAddressFormState
    },
    billingAddressForm: {
        ...initialAddressFormState
    },
    paypalAddressForm: {
        ...initialAddressFormState
    },
    mobilePayAddressForm: {
        ...initialAddressFormState
    },
    isCleanPaypalAddress: false,
    carbForm: {
        acknowledgedCarbTerms: false
    },
    emailSignUp: {
    },
    loyaltySignUp: {
    },
    shippingAddressSuggestion: {
        suggestedAddress: null,
        originalAddress: null
    },
    addressLookup: {
        shippingAddressId: "",
        billingAddressId: "",
        defaultBillingAddressId: "",
        defaultShippingAddressId: ""
    },
    carb: null,
    payPalOrder: null,
    payPalExpressForm: {
        phoneNumber: ""
    },
    confirmation: null,
    transactionId: "",
    isUserLoggedIn: false,
    isAddressBookDisplayed: false,
    selectedPaymentMethod: "Credit Card",
    errors: [],
    warnings: [],
    paymentProcessorClientAuthToken: "",
    hasBackorderedItems: false,
    isPayPalAllowedForAddress: false,
    addressValidationErrors: [],
    addressHasBeenValidated: false,
    dropshipItems: [],
    truckFreightItems: [],
    isTruckFreightOrder: false,
    hasPOBoxOverweightPackage: false,
    unshippableItems: []
};

const transactionReducer = (state, action) => {
    state = state || initialState

    switch (action.type) {
        case ActionTypes.RECEIVE_CUSTOMERINFO:
        case ActionTypes.RECEIVE_ENTRY:
        case ActionTypes.RECEIVE_SHIPPINGOPTION:
        case ActionTypes.RECEIVE_SHIPPINGPROTECTION:
        case ActionTypes.RECEIVE_PAYMENT:
        case ActionTypes.RECEIVE_PAYMENTTECH_URL:
        case ActionTypes.RECEIVE_PROMO_OR_GIFTCARD:
        case ActionTypes.RECEIVE_LOYALTY_CODE:
        case ActionTypes.RECEIVE_DEALERTERMS:
        case ActionTypes.RECEIVE_REMOVEPROMO:
        case ActionTypes.RECEIVE_REMOVEGIFTCARD:
        case ActionTypes.RECEIVE_REMOVELOYALTYCODE:
        case ActionTypes.RECEIVE_SHIPPING:
        case ActionTypes.RECEIVE_REMOVE_CARB_ITEMS:
        case ActionTypes.RECEIVE_REMOVE_DROPSHIP_ITEMS:
        case ActionTypes.RECEIVE_REMOVE_ITEMS:
        case ActionTypes.RECEIVE_REMOVE_TRUCK_FREIGHT_ITEMS:
        case ActionTypes.RECEIVE_ACKNOWLEDGE_CARB_TERMS:
        case ActionTypes.RECEIVE_CONFIRMATION_PAGE:
            return updateState(state, action)
        case ActionTypes.RECEIVE_PAYPAL_EXPRESS:
        case ActionTypes.RECEIVE_MOBILE_PAY:
            return updateStateUnhydrated(state, action)
        case ActionTypes.ENABLE_ADDRESSBOOK:
            return enableAddressBook(state, action);
        case ActionTypes.DISABLE_ADDRESSBOOK:
            return disableAddressBook(state, action);
        case ActionTypes.RESET_ADDRESSBOOKADDRESS:
            return updateAddressBookAddress(state, action);
        case ActionTypes.RECEIVE_UPDATEADDRESSBOOK:
            return addAddressBookAddress(state, action);
        case ActionTypes.RECEIVE_EMAIL_SIGN_UP:
            return updateEmailSignUpState(state, action)
        case ActionTypes.RECEIVE_LOYALTY_SIGN_UP:
            return updateLoyaltySignUpState(state, action)
        case ActionTypes.UPDATE_CUSTOMER_INFO_ADDRESS_FORM:
            return {
                ...state,
                guestCheckoutAddressForm: {
                    ...state.guestCheckoutAddressForm,
                    ...action.payload.updatedFormField
                },
                addressHasBeenValidated: action?.payload?.addressHasBeenValidated ?? state?.addressHasBeenValidated
            }
        case ActionTypes.UPDATE_CARB_FORM:
            return {
                ...state,
                carbForm: {
                    ...state.carbForm,
                    ...action.payload
                }
            }
        case ActionTypes.UPDATE_CARB_SUBCLASS_FORM:
            return {
                ...state,
                carb: {
                    ...state.carb,
                    ...action.payload
                }
            }
        case ActionTypes.RECEIVE_CARB_SUBCLASS_FORM:
            return {
                ...state,
                carb: {
                    ...state.carb,
                    yearMakeModelData: {
                        ...action.payload
                    }
                }
            }
        case ActionTypes.UPDATE_ADDRESS_BOOK_ADDRESS_FORM:
            return {
                ...state,
                addressBookAddressForm: {
                    ...state.addressBookAddressForm,
                    ...action.payload.updatedFormField
                }, 
                addressHasBeenValidated: action?.payload?.addressHasBeenValidated ?? state?.addressHasBeenValidated
            }
        case ActionTypes.UPDATE_INSTORE_PICKUP_LOCATION:
            return {
                ...state,
                shippingMethodContainer: {
                    ...action.payload
                }
            }
        case ActionTypes.UPDATE_BILLING_ADDRESS_FORM:
            let shippingAddress = state.addresses[state.addressLookup.shippingAddressId]
            let addressId = state.billingAddressForm.addressId
            if (!shippingAddress) {
                shippingAddress = {
                    email: "",
                    phoneNumber: ""
                }
            }
            else if (addressId == shippingAddress.addressId) {
                addressId = state.addressLookup.billingAddressId
            }

            return {
                ...state,
                paymentMethodContainer: {
                    ...state.paymentMethodContainer,
                    isPaymentTechOverlayDisplayed: !isAddressValid({
                        ...state.billingAddressForm,
                        ...action?.payload?.updatedFormField
                    })
                },
                billingAddressForm: {
                    ...state.billingAddressForm,
                    ...action?.payload?.updatedFormField,
                    email: shippingAddress.email,
                    phoneNumber: shippingAddress.phoneNumber,
                    addressId: addressId
                },
                addressHasBeenValidated: action?.payload?.addressHasBeenValidated ?? false
            }
        case ActionTypes.UPDATE_EMAIL_SIGN_UP:
            return {
                ...state,
                emailSignUp: {
                    ...state.emailSignUp,
                    ...action.payload
                }
            }
        case ActionTypes.UPDATE_LOYALTY_SIGN_UP:
            return {
                ...state,
                loyaltySignUp: {
                    ...state.loyaltySignUp,
                    ...action.payload
                }
            }
        case ActionTypes.SUBMIT_CHECKOUT:
            return {
                ...state,
                email: action.reactState.email
            }
        case ActionTypes.RECEIVE_SUBMITCUSTOMERINFO:
        case ActionTypes.RECEIVE_UPDATESHIPPINGADDRESS:
        case ActionTypes.RECEIVE_SHIPPINGMETHODSTEP:
            return updateShippingAddressSuggestion(state, action);
        case ActionTypes.DISPLAY_ERRORS:
            return { 
                ...state,
                errors: {
                    ...state.errors,
                    ...action.errors
                }
            }

        case ActionTypes.DISPLAY_WARNINGS:
            return {
                ...state,
                warnings: {
                    ...action.payload
                }
            }

        case ActionTypes.DISPLAY_ERRORS_TO_CUSTOM_BILLING:
            return {
                ...state,
                billingAddressForm: action.payload,
                paymentMethodContainer: {
                    ...state.paymentMethodContainer,
                    isDefaultBillingAddressSelected: false,
                    isShippingAddressBillingAddressSelected: false,
                    isCustomBillingAddressSelected: true,
                }
            }
        case ActionTypes.STORE_HYDRATED_FLAG:
            return {
                ...state,
                storeHydrated: true
            }
        case ActionTypes.SELECT_DEFAULT_BILLING_ADDRESS:
            return {
                ...state,
                billingAddressForm: {
                    ...initialAddressFormState,
                    addressId: state.addressLookup.shippingAddressId
                },
                paymentMethodContainer: {
                    ...state.paymentMethodContainer,
                    isPaymentTechOverlayDisplayed: false,
                    isDefaultBillingAddressSelected: true,
                    isShippingAddressBillingAddressSelected: false,
                    isCustomBillingAddressSelected: false,
                    ...action.payload
                }
            }
        case ActionTypes.SELECT_SAME_AS_SHIPPING_BILLING_ADDRESS:
            return {
                ...state,
                billingAddressForm: {
                    ...initialAddressFormState,
                    addressId: state.addressLookup.shippingAddressId
                },
                paymentMethodContainer: {
                    ...state.paymentMethodContainer,
                    isPaymentTechOverlayDisplayed: false,
                    isDefaultBillingAddressSelected: false,
                    isShippingAddressBillingAddressSelected: true,
                    isCustomBillingAddressSelected: false,
                    ...action.payload
                }
            }
        case ActionTypes.SELECT_CUSTOM_BILLING_ADDRESS_BRAINTREE:
            let billingAddressForm = state.billingAddressForm
            if (!action.payload) {
                billingAddressForm = {
                    ...initialAddressFormState,
                    addressId: state.addressLookup.shippingAddressId
                }
            }

            return {
                ...state,
                billingAddressForm: billingAddressForm,
                paymentMethodContainer: {
                    ...state.paymentMethodContainer,
                    isCustomBillingAddressSelected: action.payload
                }
            }
        case ActionTypes.SELECT_CUSTOM_BILLING_ADDRESS:
            return {
                ...state,
                billingAddressForm: {
                    ...state.billingAddressForm,
                    addressId: null
                },
                paymentMethodContainer: {
                    ...state.paymentMethodContainer,
                    isPaymentTechOverlayDisplayed: !isAddressValid(state.billingAddressForm),
                    isDefaultBillingAddressSelected: false,
                    isShippingAddressBillingAddressSelected: false,
                    isCustomBillingAddressSelected: true,
                    ...action.payload
                }
            }
        case ActionTypes.UPDATE_PAYMENTMETHOD:
            return {
                ...state,
                selectedPaymentMethod: action.payload,
                paymentProcessorClientAuthToken: ""
            }
        case ActionTypes.UPDATE_PAYMENTTECHIFRAME:
            return {
                ...state,
                transactionId: action.payload.checkoutTransactionRm.transactionId,
                addressLookup: action.payload.checkoutTransactionRm.addressLookup,
                addressForm: action.payload.checkoutTransactionRm.addressForm,
                addresses: action.payload.checkoutTransactionRm.addresses,
                shippingMethodContainer: action.payload.checkoutTransactionRm.shippingMethodContainer,
                paymentMethodContainer: action.payload.checkoutTransactionRm.paymentMethodContainer,
                carb: action.payload.checkoutTransactionRm.carb,
                dropshipItems: action.payload.checkoutTransactionRm.dropshipItems,
                truckFreightItems: action.payload.checkoutTransactionRm.truckFreightItems,
                isTruckFreightOrder: action.payload.checkoutTransactionRm.isTruckFreightOrder,
                hasPOBoxOverweightPackage: action.payload.checkoutTransactionRm.hasPOBoxOverweightPackage
            }
        case ActionTypes.ERASE_PAYMENTTECHIFRAME:
            return {
                ...state,
                paymentMethodContainer: {
                    ...state.paymentMethodContainer,
                    paymentTechUrl: ""
                },
                storeHydrated: false
            }
        case ActionTypes.UPDATE_PAYPAL_EXPRESS_ADDRESS_FORM:
            return {
                ...state,
                payPalExpressForm: {
                    ...action.payload
                }
            }
        case ActionTypes.UPDATE_PAYPAL_ADDRESS_FORM:
            return {
                ...state,
                paypalAddressForm: {
                    ...state.paypalAddressForm,
                    ...action.payload
                },
                addressHasBeenValidated: state.paypalAddressForm.addressHasBeenValidated
            }
        case ActionTypes.UPDATE_MOBILEPAY_ADDRESS_FORM:
            return {
                ...state,
                mobilePayAddressForm: {
                    ...state.mobilePayAddressForm,
                    ...action.payload
                },
                addressHasBeenValidated: state.mobilePayAddressForm.addressHasBeenValidated
            }
        case ActionTypes.DELIVERYINSTRUCTIONS:
            return {
                ...state,
                shippingMethodContainer: {
                    ...state.shippingMethodContainer,
                    ...action.payload
                }
            }
        case ActionTypes.ENABLE_PAYMENTTECH_OVERLAY:
            return {
                ...state,
                paymentMethodContainer: {
                    ...state.paymentMethodContainer,
                    isPaymentTechOverlayDisplayed: true
                }
            }
        case ActionTypes.RECEIVE_VALIDATED_SMARTY_STREET_ADDRESS:
        case ActionTypes.UPDATE_ADDITIONAL_ADDRESS_FORM_DATA:
            return {
                ...state,
                addressForm: {
                    ...action.payload
                }
            }
        case ActionTypes.RECEIVE_ADDRESS_VALIDATION_ERRORS:
            return {
                ...state,
                addressHasBeenValidated: true,
                addressValidationErrors: action.payload
            }
        case ActionTypes.RECEIVE_EMAIL_VALIDATION_ERROR:
            return {
                ...state,
            }
        case ActionTypes.HANDLE_PROMO_OR_GIFTCARD_CHANGE:
            return {
                ...state,
                paymentMethodContainer: {
                    ...state.paymentMethodContainer,
                    currentPromoOrGiftCard: action.payload
                }
            }
        case ActionTypes.HANDLE_LOYALTY_CODE_CHANGE:
            return {
                ...state,
                paymentMethodContainer: {
                    ...state.paymentMethodContainer,
                    appliedLoyaltyCode: action.payload.Applied,
                    selectedLoyaltyCode: action.payload.Selected
                }
            }
        case ActionTypes.CLEAR_PAYMENT_ERRORS:
            return {
                ...state,
                errors: {
                    ...state.errors,
                    SUBMIT_FOR_PAYMENT: {}
                }
            }
        case ActionTypes.REMOVE_WARNING_VALIDATION:
            return {
                ...state,
                warnings: []
            }
        case ActionTypes.UPDATE_WARNING_VALIDATION:
            return updateWarningValidation(state, action.payload)
        default:
            return state
    }
};



export default transactionReducer


function updateState(state, action) {
    const updatedTransaction = action.payload.checkoutTransactionRm
    return {
        ...state,
        transactionId: updatedTransaction.transactionId,
        isUserLoggedIn: updatedTransaction.isUserLoggedIn,
        thirdPartyPaymentContainer: updatedTransaction.thirdPartyPaymentContainer,
        addressLookup: updatedTransaction.addressLookup,
        addressForm: updatedTransaction.addressForm,
        addresses: updatedTransaction.addresses,
        shippingMethodContainer: {
            ...updatedTransaction.shippingMethodContainer,
            deliveryInstructions: state.shippingMethodContainer.deliveryInstructions != null ? state.shippingMethodContainer.deliveryInstructions : updatedTransaction.shippingMethodContainer.deliveryInstructions
        },
        paymentMethodContainer: updatePaymentState(state, action),
        carb: updatedTransaction.carb,
        payPalOrder: updatedTransaction.payPalOrder,
        paypalTransactionId: updatedTransaction.paypalTransactionId,
        confirmation: updatedTransaction.confirmation,
        emailSignUp: updatedTransaction.emailSignUp,
        loyaltySignUp: updatedTransaction.loyaltySignUp,
        hasBackorderedItems: updatedTransaction.hasBackorderedItems,
        isPayPalAllowedForAddress: updatedTransaction.isPayPalAllowedForAddress,
        enablePaypalForBackorders: updatedTransaction.enablePaypalForBackorders,
        paymentProcessorClientAuthToken: updatedTransaction.paymentProcessorClientAuthToken ?
            updatedTransaction.paymentProcessorClientAuthToken :
            state.paymentProcessorClientAuthToken,
        addressHasBeenValidated: updatedTransaction.addressHasBeenValidated,
        isBraintreePaypalExpressEnabled: updatedTransaction.isBraintreePaypalExpressEnabled,
        isGooglePayExpressEnabled: updatedTransaction.isGooglePayExpressEnabled,
        addressValidationErrors: updatedTransaction.addressValidationErrors,
        googlePayMerchantId: updatedTransaction.googlePayMerchantId,
        userProfile: updatedTransaction.userProfile,
        dropshipItems: updatedTransaction.dropshipItems,
        truckFreightItems: updatedTransaction.truckFreightItems,
        isTruckFreightOrder: updatedTransaction.isTruckFreightOrder,
        hasPOBoxOverweightPackage: updatedTransaction.hasPOBoxOverweightPackage,
        shippingProtectionOptionsContainer: updatedTransaction.shippingProtectionOptionsContainer,
        unshippableItems: updatedTransaction.unshippableItems,
        promoCode: updatedTransaction.promoCode,
    };
}

function updatePaymentState(state, action) {
    const defaultBilling = typeof state.paymentMethodContainer.isDefaultBillingAddressSelected !== "undefined" ? state.paymentMethodContainer.isDefaultBillingAddressSelected : state.paymentMethodContainer.hasDefaultBillingAddress
    const shippingAsBilling = typeof state.paymentMethodContainer.isShippingAddressBillingAddressSelected !== "undefined" ? state.paymentMethodContainer.isShippingAddressBillingAddressSelected : !state.paymentMethodContainer.hasDefaultBillingAddress && state.paymentMethodContainer.hasShippingAddress
    const customBilling = typeof state.paymentMethodContainer.isCustomBillingAddressSelected !== "undefined" ? state.paymentMethodContainer.isCustomBillingAddressSelected : !state.paymentMethodContainer.hasDefaultBillingAddress && !state.paymentMethodContainer.hasShippingAddress

    return {
        ...action.payload.checkoutTransactionRm.paymentMethodContainer,
        isDefaultBillingAddressSelected: defaultBilling,
        isShippingAddressBillingAddressSelected: shippingAsBilling,
        isCustomBillingAddressSelected: customBilling
    }
}

function updateStateCustomerInfo(state, action) {
    return {
        ...state,
        thirdPartyPaymentContainer: action.payload.checkoutTransactionRm.thirdPartyPaymentContainer,
        addressForm: action.payload.checkoutTransactionRm.addressForm,
        paymentProcessorClientAuthToken: action.payload.checkoutTransactionRm.paymentProcessorClientAuthToken,
        storeHydrated: true
    };
}
function updateStateUnhydrated(state, action) {
    return {
        ...state,
        isUserLoggedIn: action.payload.checkoutTransactionRm.isUserLoggedIn,
        transactionId: action.payload.checkoutTransactionRm.transactionId,
        thirdPartyPaymentContainer: action.payload.checkoutTransactionRm.thirdPartyPaymentContainer,
        addressLookup: action.payload.checkoutTransactionRm.addressLookup,
        addressForm: action.payload.checkoutTransactionRm.addressForm,
        addressBookAddressForm: action.payload.checkoutTransactionRm.paypalAddressForm ?? action.payload.checkoutTransactionRm.mobilePayAddressForm,
        addresses: action.payload.checkoutTransactionRm.addresses,
        shippingMethodContainer: action.payload.checkoutTransactionRm.shippingMethodContainer,
        paymentMethodContainer: action.payload.checkoutTransactionRm.paymentMethodContainer,
        carb: action.payload.checkoutTransactionRm.carb,
        payPalOrder: action.payload.checkoutTransactionRm.payPalOrder,
        paypalTransactionId: action.payload.checkoutTransactionRm.paypalTransactionId,
        confirmation: action.payload.checkoutTransactionRm.confirmation,
        storeHydrated: false,
        paypalAddressForm: action.payload.checkoutTransactionRm.paypalAddressForm,
        mobilePayAddressForm: action.payload.checkoutTransactionRm.mobilePayAddressForm,
        isCleanPaypalAddress: action.payload.checkoutTransactionRm.isCleanPaypalAddress,
        isBraintreePaypalExpressEnabled: action.payload.checkoutTransactionRm.isBraintreePaypalExpressEnabled,
        isGooglePayExpressEnabled: action.payload.checkoutTransactionRm.isGooglePayExpressEnabled,
        googlePayMerchantId: action.payload.checkoutTransactionRm.googlePayMerchantId,
        dropshipItems: action.payload.checkoutTransactionRm.dropshipItems,
        truckFreightItems: action.payload.checkoutTransactionRm.truckFreightItems,
        isTruckFreightOrder: action.payload.checkoutTransactionRm.isTruckFreightOrder,
        hasPOBoxOverweightPackage: action.payload.checkoutTransactionRm.hasPOBoxOverweightPackage,
        shippingProtectionOptionsContainer: action.payload.checkoutTransactionRm.shippingProtectionOptionsContainer,
        unshippableItems: action.payload.checkoutTransactionRm.unshippableItems,
    };
}

function updateShippingAddressSuggestion(state, action) {
    
    var newState = updateState(state, action);
    
    newState['shippingAddressSuggestion'] = action.payload.checkoutTransactionRm.shippingAddressSuggestion;
    newState['carb'] = action.payload.checkoutTransactionRm.carb;
    
    return newState;
}

function updateEmailSignUpState(state, action) {
    return {
        ...state,
        emailSignUp: {
            ...state.emailSignUp,
            wasEmailInvalid: action.payload.wasEmailInvalid,
            emailSignUpStatusType: action.payload.emailSignUpStatusType
        },
    }
}

function updateLoyaltySignUpState(state, action) {
    return {
        ...state,
        loyaltySignUp: {
            ...state.loyaltySignUp,
            loyaltySignUpStatusType: action.payload
        },
    }
}

function enableAddressBook(state, action) {
    return {
        ...state,
        isAddressBookDisplayed: true,
        showAddressFields: action.payload.showAddressFields
    }
}

function disableAddressBook(state, action) {
    return {
        ...state,
        isAddressBookDisplayed: false,
        showAddressFields: false
    }
}

function updateAddressBookAddress(state, action) {
    if (action.payload) {
        return {
            ...state,
            addressBookAddressForm: {
                ...action.payload
            },
        }
    } else {
        return {
            ...state,
            addressBookAddressForm: {
                ...initialAddressFormState
            }
        }
    }
}

function addAddressBookAddress(state, action) {
    
    return {
        ...state,
        transactionId: action.payload.checkoutTransactionRm.transactionId,
        addressLookup: action.payload.checkoutTransactionRm.addressLookup,
        addressForm: action.payload.checkoutTransactionRm.addressForm,
        addresses: action.payload.checkoutTransactionRm.addresses,
        shippingMethodContainer: action.payload.checkoutTransactionRm.shippingMethodContainer,
        paymentMethodContainer: action.payload.checkoutTransactionRm.paymentMethodContainer,
        shippingAddressSuggestion: action.payload.checkoutTransactionRm.shippingAddressSuggestion,
        isAddressBookDisplayed: state.errors.length > 0 || 
            action.payload.checkoutTransactionRm.addressValidationErrors !== null && action.payload.checkoutTransactionRm.addressValidationErrors.hasSmartStreetErrors === true,
        addressValidationErrors: action.payload.checkoutTransactionRm.addressValidationErrors,
        addressHasBeenValidated: action.payload.checkoutTransactionRm.addressHasBeenValidated,
        carb: action.payload.checkoutTransactionRm.carb
    }
}

function isAddressValid(address) {
    if (!address.fullName || !address.fullName.length) {
        return false;
    }
    if (!address.addressLine1 || !address.addressLine1.length) {
        return false;
    }
    if (!address.city || !address.city.length) {
        return false;
    }
    if (!address.countryId) {
        return false;
    }
    if ((address.countryId === USA_ID || address.countryId === CANADA_ID)
        && !address.provinceId) {
        return false;
    }
    if (address.countryId !== USA_ID
        && address.countryId !== CANADA_ID
        && (!address.province || !address.province.length)) {
        return false;
    }
    if (!address.postalCode || !address.postalCode.length) {
        return false;
    }
    return true;
}

export function updateWarningValidation(state, action) {
    if (action.formUpdated.addressLine1) {
        return {
            ...state,
            addressValidationErrors: {
                ...state.addressValidationErrors,
                addressDoesNotReceiveMail: false,
                isInvalidAddress: false,
                partialAddress: false,
                invalidAddressNumber:false,
                isInvalidSopAddress: false,
            }
        }
    }
    if (action.formUpdated.addressLine2) {
        return {
            ...state,
            addressValidationErrors: {
                ...state.addressValidationErrors,
                apartmentSuiteNumberRequired: false,
                boxNumberIsMissingOrInvalid: false
            }
        }
    }
    if (action.formUpdated.company) {
        return {
            ...state,
            addressValidationErrors: {
                ...state.addressValidationErrors,
                isCommercial: false
            }
        }
    }

    return {
        ...state
    }
}