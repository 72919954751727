import React from 'react'
import {withRouter} from "react-router-dom"
import {connect} from 'react-redux';
import Modal from "Components/Modal/Modal"
import {CardPrimary, CardSecondary} from "Components/Card/Card";
import Typography from "Components/Typography/Typography";
import Divider from "Components/Divider/Divider";
import {
    requestRemoveDropshipItems,
    requestRemoveTruckFreightItems,
    requestRemoveItems,
    showAddressBook
} from "Actions/checkoutTransactionAction";
import Button from "../../../Components/Button/Button";
import Media from 'Components/Media/Media';
import './addressShippingConstraints.css';
import {receiveAddressShippingConstraintModal} from "../../../Actions/checkoutTransactionAction";

const AddressShippingConstraints = (
    {
        isPayPalExpress,
        isTruckFreightOrder,
        hasPOBoxOverweightPackage,
        paymentMethodContainer,
        showAddressBook,
        addresses,
        addressLookup,
        requestRemoveDropshipItems,
        requestRemoveTruckFreightItems,
        dropshipItems,
        truckFreightItems,
        dispatchAddressShippingConstraintModalRendered,
        requestRemoveItems,
        unshippableItems
    }
) => {
    
    let orderContainsDropshipItems = dropshipItems.length > 0;
    let orderContainsTruckFreightItems = truckFreightItems.length > 0;

    const hasUnshippableItems = unshippableItems?.length > 0;

    // If the order doesn't contain a dropship item, freight item and isn't a PO box then don't show the modal
    if(!orderContainsDropshipItems 
        && !orderContainsTruckFreightItems 
        && !isTruckFreightOrder 
        && !hasPOBoxOverweightPackage
        && !hasUnshippableItems) {
        return false;
    }
    
    let shippingAddress;

    // The address will come from PayPal if using PayPal Express
    if(isPayPalExpress) {
        let shippingAddressId = paymentMethodContainer.shipToAddressId;
        shippingAddress = addresses[shippingAddressId];
    } else {
        shippingAddress = addresses[addressLookup.shippingAddressId];
    }
    
    let shippingAddressIsPOBox = shippingAddress.isPOBox;
    let shippingAddressIsOutsideContiguousUS = shippingAddress.isOutsideContiguousUS;
    let shippingAddressIsInternationalAddress = shippingAddress.isInternationalAddressToggleActive;

    // If the address isn't a PO box and isn't an international address, don't show the modal
    if(!shippingAddressIsPOBox && !shippingAddressIsInternationalAddress && !shippingAddressIsOutsideContiguousUS) {
        return false;
    }

    if (hasUnshippableItems) {
        dispatchAddressShippingConstraintModalRendered();
        return showRemoveUnshippableItemsModal(unshippableItems, requestRemoveItems, showAddressBook, isPayPalExpress)
    } else if (shippingAddressIsPOBox && orderContainsDropshipItems) {
        // WE WANT TO GET RID OF THIS IN FAVOR OF THE GENERALIZED "INVALID ITEMS PATH"
        dispatchAddressShippingConstraintModalRendered();
        return showPOBoxDropshipItemsModal(dropshipItems, showAddressBook);
    } else if (shippingAddressIsPOBox && (hasPOBoxOverweightPackage || isTruckFreightOrder || orderContainsTruckFreightItems)) {
        // WE WANT TO GET RID OF THIS IN FAVOR OF THE GENERALIZED "INVALID ITEMS PATH"
        dispatchAddressShippingConstraintModalRendered();
        return showPOBoxOverweightItemsModal(showAddressBook);
    } else if (shippingAddressIsInternationalAddress && orderContainsDropshipItems) {
        // WE WANT TO GET RID OF THIS IN FAVOR OF THE GENERALIZED "INVALID ITEMS PATH"
        dispatchAddressShippingConstraintModalRendered();
        return showInternationalDropshipItemsModal(dropshipItems, requestRemoveDropshipItems, isPayPalExpress, showAddressBook);
    } else if (shippingAddressIsInternationalAddress && (orderContainsTruckFreightItems || isTruckFreightOrder)) {
        // WE WANT TO GET RID OF THIS IN FAVOR OF THE GENERALIZED "INVALID ITEMS PATH"
        dispatchAddressShippingConstraintModalRendered();
        return showInternationalFreightItemsModal(truckFreightItems, orderContainsTruckFreightItems, requestRemoveTruckFreightItems, isPayPalExpress, showAddressBook);
    } else if (shippingAddressIsOutsideContiguousUS && (orderContainsTruckFreightItems || isTruckFreightOrder)) {
        // WE WANT TO GET RID OF THIS IN FAVOR OF THE GENERALIZED "INVALID ITEMS PATH"
        dispatchAddressShippingConstraintModalRendered();
        return showNonContiguousUSFreightItemsModal(truckFreightItems, orderContainsTruckFreightItems, requestRemoveTruckFreightItems, isPayPalExpress, showAddressBook)
    }
    
    return false;
}

const dispatchAddressShippingConstraintModalRendered = () => dispatch => {
    dispatch(receiveAddressShippingConstraintModal())
}

function showRemoveUnshippableItemsModal(unshippableItems, removeItems, showAddressBook, isPayPalExpress) {
    if (unshippableItems?.lengh) {
        return false
    }
    const firstItem = unshippableItems[0]
    if (!firstItem.unshippableReason) {
        return false
    }
    const { unshippableItemType, title, shortDescription, longDescription } = firstItem.unshippableReason

    const itemsWithMatchingReason = unshippableItems.filter(item => item.unshippableReason?.unshippableItemType == unshippableItemType)

    const items = itemsWithMatchingReason.map((x) => {
        return <CardSecondary className="itemContainer" key={x.ordinality}>
            <div className="item">
                <div className="itemImageContainer">
                    <Media.Image className="itemImage" url={x.imageUrl} title={x.title} hasWebp={false} />
                </div>
                <div className="itemTitle">
                    {x.title}
                </div>
            </div>
        </CardSecondary>
    })

    const removeItemsHeading = (
        <>
            <a href="javascript:void(0)" className="cardAction" />
            <div className="contentContainer">
                <Typography as={'h2'}>{title}</Typography>
                <Typography as={'h3'}>{shortDescription}</Typography>
                <div className="redText">{longDescription}</div>
                {items}
            </div>
        </>
    );

    let removeUnshippableItemsModalBody = (
        <CardPrimary className="addressShippingConstraints noMarginTopDesktop">
            {removeItemsHeading}
            <Divider variant="horizontal" />
            <div className="buttonArea">
                <Button className="removeDropshipItems" variant={'confirmation'} inline title="Remove Item(s) from Cart"
                    onClick={() => removeItems(itemsWithMatchingReason, isPayPalExpress)}>
                    Remove Items from Cart
                </Button>
                <Button className="changeShipping" variant={'secondary'} inline title="Change Shipping Address"
                    onClick={showAddressBook} >
                    Change Shipping Address
                </Button>
                <div className="buttonArea">
                    <Button className="returnToCart" variant={'link'} inline title="Return to Cart"
                        onClick={() => { cartRedirect() }}>
                        Return to Cart
                    </Button>
                </div>
            </div>
        </CardPrimary>
    )

    return (
        <Modal content={removeUnshippableItemsModalBody} />
    )
}

function showPOBoxOverweightItemsModal(showAddressBook) {
    let poBoxOverweightPackageModalContent = (
        <>
            <a href="javascript:void(0)" className="cardAction"/>
            <div className="contentContainer">
                <Typography as={'h2'}>Overweight Package(s) Notice</Typography>
                <Typography as={'h3'}>One or more of the package(s) on order cannot be shipped to a PO Box</Typography>
                <div className="redText">Due to package(s) in your order that exceed the 10lb weight limit,
                    we are unable to ship to a PO Box address. Please update to a physical shipping address.
                </div>
            </div>
        </>
    );

    let poBoxOverweightPackageModal = (
        <CardPrimary className="addressShippingConstraints noMarginTopDesktop">
            {poBoxOverweightPackageModalContent}
            <Divider variant="horizontal"/>
            <div className="buttonArea">
                <Button className="changeShipping" variant={'confirmation'} inline title="Change Shipping Address"
                        onClick={showAddressBook} >
                    Change Shipping Address
                </Button>
                <Button className="returnToCart" variant={'link'} inline title="Return to Cart"
                        onClick={ () => { cartRedirect() } }>
                    Return to Cart
                </Button>
            </div>
        </CardPrimary>
    )
    
    return (
        <Modal content={poBoxOverweightPackageModal}/>
    )
}

function showPOBoxDropshipItemsModal(dropshipItems, showAddressBook) {
    
    let items = dropshipItems.map((x) => {
        return <CardSecondary className="itemContainer" key={x.ordinality}>
            <div className="item">
                <div className="itemImageContainer">
                    <Media.Image className="itemImage" url={x.imageUrl} title={x.title} hasWebp={false} />
                </div>
                <div className="itemTitle">
                    {x.title}
                </div>
            </div>
        </CardSecondary>
    })

    let poBoxDropshipItemsModalContent = (
        <>
            <a href="javascript:void(0)" className="cardAction"/>
            <div className="contentContainer">
                <Typography as={'h2'}>Factory Shipping Notice</Typography>
                <Typography as={'h3'}>Items on order cannot be shipped to a PO Box</Typography>
                <div className="redText">Due to items in your order that ship direct from the factory, we are unable
                    to ship to a PO Box address. Please update to a physical shipping address.
                </div>
                {items}
            </div>
        </>
    );

    let poBoxDropshipItemsModal = (
        <CardPrimary className="addressShippingConstraints noMarginTopDesktop">
            {poBoxDropshipItemsModalContent}
            <Divider variant="horizontal"/>
            <div className="buttonArea">
                <Button className="changeShipping" variant={'confirmation'} inline title="Change Shipping Address"
                        onClick={showAddressBook} >
                    Change Shipping Address
                </Button>
                <Button className="returnToCart" variant={'link'} onClick={ () => { cartRedirect() } }>
                    Return to Cart
                </Button>
            </div>
        </CardPrimary>
    )
    
    return (
        <Modal content={poBoxDropshipItemsModal}/>
    )
}

function showInternationalDropshipItemsModal(dropshipItems, requestRemoveDropshipItems, isPayPalExpress, showAddressBook) {

    let items = dropshipItems.map((x) => {
        return <CardSecondary className="itemContainer" key={x.ordinality}>
            <div className="item">
                <div className="itemImageContainer">
                    <Media.Image className="itemImage" url={x.imageUrl} title={x.title} hasWebp={false} />
                </div>
                <div className="itemTitle">
                    {x.title}
                </div>
            </div>
        </CardSecondary>
    })
    
    let internationalDropshipItemsModalContent = (
        <>
            <a href="javascript:void(0)" className="cardAction"/>
            <div className="contentContainer">
                <Typography as={'h2'}>Factory Shipping Notice</Typography>
                <Typography as={'h3'}>Items on order cannot be shipped to an international address</Typography>
                <div className="redText">Due to items in your order that ship direct from the factory, we are unable to 
                    ship to an international address. Please remove the factory shipped items from your order or 
                    consider replacing this item with an equivalent in-stock item.
                </div>
                {items}
            </div>
        </>
    );

    let internationalDropshipItemsModal = (
        <CardPrimary className="addressShippingConstraints noMarginTopDesktop">
            {internationalDropshipItemsModalContent}
            <Divider variant="horizontal"/>
            <div className="buttonArea">
                <Button className="removeDropshipItems" variant={'confirmation'} inline title="Remove Item(s) from Cart" 
                        onClick={() => requestRemoveDropshipItems(dropshipItems, isPayPalExpress)}>
                    Remove Items from Cart
                </Button>
                <Button className="changeShipping" variant={'secondary'} inline title="Change Shipping Address"
                        onClick={showAddressBook} >
                    Change Shipping Address
                </Button>
                <div className="buttonArea">
                    <Button className="returnToCart" variant={'link'} inline title="Return to Cart"
                            onClick={ () => { cartRedirect() } }>
                        Return to Cart
                    </Button>
                </div>
            </div>
        </CardPrimary>
    )

    return (
        <Modal content={internationalDropshipItemsModal}/>
    )
}

function showNonContiguousUSFreightItemsModal(truckFreightItems, orderContainsTruckFreightItems, requestRemoveTruckFreightItems, isPayPalExpress, showAddressBook) {
    let items = truckFreightItems.map((x) => {
        return <CardSecondary className="itemContainer" key={x.ordinality}>
            <div className="item">
                <div className="itemImageContainer">
                    <Media.Image className="itemImage" url={x.imageUrl} title={x.title} hasWebp={false} />
                </div>
                <div className="itemTitle">
                    {x.title}
                </div>
            </div>
        </CardSecondary>
    })

    let nonContiguousFreightItemsModalContent = (
        <>
            <a href="javascript:void(0)" className="cardAction"/>
            <div className="contentContainer">
                <Typography as={'h2'}>Non-Contiguous Freight Shipping Notice</Typography>
                <Typography as={'h3'}>Items on order cannot be shipped to a non-contiguous US address</Typography>
                <div className="redText">Due to items in your order that ship via freight, we are unable to
                    ship to a non-contiguous US address. Please remove the freight shipped items from your order or change
                    your shipping address.
                </div>
                {items}
            </div>
        </>
    );

    let nonContiguousFreightItemsModal = (
        <CardPrimary className="addressShippingConstraints noMarginTopDesktop">
            {nonContiguousFreightItemsModalContent}
            <Divider variant="horizontal"/>
            <div className="buttonArea">
                <Button className="removeDropshipItems" variant={'confirmation'} inline title="Remove Item(s) from Cart"
                        onClick={() => requestRemoveTruckFreightItems(truckFreightItems, isPayPalExpress)}>
                    Remove Items from Cart
                </Button>
                <Button className="changeShipping" variant={'secondary'} inline title="Change Shipping Address"
                        onClick={showAddressBook} >
                    Change Shipping Address
                </Button>
                <Button className="returnToCart" variant={'link'} inline title="Return to Cart"
                        onClick={ () => { cartRedirect() } }>
                    Return to Cart
                </Button>
            </div>
        </CardPrimary>
    )

    return (
        <Modal content={nonContiguousFreightItemsModal}/>
    )
}

function showInternationalFreightItemsModal(truckFreightItems, orderContainsTruckFreightItems, requestRemoveTruckFreightItems, isPayPalExpress, showAddressBook) {
    let items = truckFreightItems.map((x) => {
        return <CardSecondary className="itemContainer" key={x.ordinality}>
            <div className="item">
                <div className="itemImageContainer">
                    <Media.Image className="itemImage" url={x.imageUrl} title={x.title} hasWebp={false} />
                </div>
                <div className="itemTitle">
                    {x.title}
                </div>
            </div>
        </CardSecondary>
    })

    let internationalFreightItemsModalContent = (
        <>
            <a href="javascript:void(0)" className="cardAction"/>
            <div className="contentContainer">
                <Typography as={'h2'}>International Freight Shipping Notice</Typography>
                <Typography as={'h3'}>Items on order cannot be shipped to an international address</Typography>
                <div className="redText">Due to items in your order that ship via freight, we are unable to
                    ship to your international address. Please remove the freight shipped items from your order or change
                    your shipping address.
                </div>
                {items}
            </div>
        </>
    );

    let internationalFreightItemsModal = (
        <CardPrimary className="addressShippingConstraints noMarginTopDesktop">
            {internationalFreightItemsModalContent}
            <Divider variant="horizontal"/>
            <div className="buttonArea">
                <Button className="removeDropshipItems" variant={'confirmation'} inline title="Remove Item(s) from Cart"
                        onClick={() => requestRemoveTruckFreightItems(truckFreightItems, isPayPalExpress)}>
                    Remove Items from Cart
                </Button>
                <Button className="changeShipping" variant={'secondary'} inline title="Change Shipping Address"
                        onClick={showAddressBook} >
                    Change Shipping Address
                </Button>
                <Button className="returnToCart" variant={'link'} inline title="Return to Cart"
                        onClick={ () => { cartRedirect() } }>
                    Return to Cart
                </Button>
            </div>
        </CardPrimary>
    )

    return (
        <Modal content={internationalFreightItemsModal}/>
    )
}

function cartRedirect() {
    window.location.href = "/shoppingcart"
}

const mapDispatchToProps = {
    showAddressBook,
    requestRemoveDropshipItems,
    requestRemoveTruckFreightItems,
    requestRemoveItems,
    dispatchAddressShippingConstraintModalRendered
}

const mapStateToProps = (state) => {
    return {
        addresses: state.transaction.addresses,
        addressLookup: state.transaction.addressLookup,
        paymentMethodContainer: state.transaction.paymentMethodContainer,
        dropshipItems: state.transaction.dropshipItems,
        truckFreightItems: state.transaction.truckFreightItems,
        isTruckFreightOrder: state.transaction.isTruckFreightOrder,
        hasPOBoxOverweightPackage: state.transaction.hasPOBoxOverweightPackage,
        unshippableItems: state.transaction.unshippableItems,
    }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(AddressShippingConstraints)) 