import { combineReducers } from 'redux'
import { routerReducer } from 'react-router-redux'

import * as ShoppingCartActionTypes from 'Types/ShoppingCartActionTypes'
import * as ProductActionTypes from 'Types/ProductActionTypes'

import { DeduplicateArray } from 'Utilities/ArrayUtilities'

const initialState = {
    skuBases: {
        "": []
    },
    skuVariants: {
        "": []
    }
}

const productReducer = (state, action) => {
    state = state || initialState

    switch (action.type) {
        case ShoppingCartActionTypes.RECEIVE_SHOPPINGCARTPAGE:
            return {
                ...state,
                skuVariants: action.payload.skuVariants           
            }
        case ShoppingCartActionTypes.RECEIVE_UPDATE_SHOPPINGCART_PRODUCTS:
            return {
                ...state,
                skuVariants: action.payload.skuVariants
            }
        case ProductActionTypes.ADD_SKUBASES:
            return {
                ...state,
                skuBases: {
                    ...state.skuBases,
                    ...action.payload
                }
            }
        case ProductActionTypes.ADD_SKUVARIANTS:
            return {
                ...state,
                skuVariants: {
                    ...state.skuVariants,
                    ...action.payload
                }
            }
        default:
            return state
    }
}
export default productReducer