const initialState = {
    isFooterRedesignEnabled: true // default to not showing the old footer
}

const featureFlagReducer = (state, action) => {
    state = state || initialState

    if (action.payload && action.payload.featureFlagContainer) {
        return action.payload.featureFlagContainer
    } else {
        return state
    }
}
export default featureFlagReducer
