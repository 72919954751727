import * as ActionTypes from 'Types/CheckoutActionTypes';
import {LoadScript} from "Utilities/Utilities"

const GeminiMiddleware = ({ dispatch, getState }) => next => action => {
    if(!navigator.globalPrivacyControl && !document.cookie.includes('EnableGpc=')) {
        try {
            switch (action.type) {
                case ActionTypes.HYDRATED_CONFIRMATION_STEP: {
                    const script = "//s.yimg.com/wi/ytc.js"
                    const scriptId = "GeminiPixel"
                    const callback = function () {
                        window.dotq = window.dotq || [];
                        window.dotq.push({
                            'projectId': '10000',
                            'properties': {
                                'pixelId': '10019976',
                                'qstrings': {
                                    'et': 'custom',
                                    'gv': action.payload.checkoutTransactionRm.confirmation.orderSubTotal
                                }
                            }
                        })
                    }
                    LoadScript(script, scriptId, callback)
                }

                    break
                default:
                    break
            }

        } catch (e) {
            console.error(e)
        }
    }
    
    next(action)
}

export default GeminiMiddleware;